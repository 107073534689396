define('walden-service/routes/pilots/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    /**
     * Query all pilot programs and return that as the model for the template.
     *
     * @override
     * @method lifecycle:model
     * @returns {Promise} Resolves when the pilots have been retrieved, otherwise rejects.
     * @memberof Routes/Pilots/Index
     */
    model: function model() {
      return this.store.query('pilot', { count: 9999 });
    }
  });
});