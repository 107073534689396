define('walden-service/serializers/order', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    isNewSerializerAPI: true,
    attrs: {
      service_date: { serialize: 'records' }
    },
    normalizeQueryResponse: function normalizeQueryResponse(store, primaryModelClass, payload, id, requestType) {
      payload = {
        orders: payload.orders
      };

      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });
});