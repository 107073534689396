define('walden-service/routes/routes/partition', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var RSVP = Ember.RSVP;
  exports.default = Ember.Route.extend({
    model: function model(params) {
      return RSVP.hash({
        partition: this.get('store').find('service-partition', params.id),
        service_days: this.get('store').query('service-day', { partition: params.id, count: 99 })
      });
    }
  });
});