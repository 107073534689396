define('walden-service/models/route', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    region: _emberData.default.attr('string'),
    driver: _emberData.default.attr('string'),
    partition: _emberData.default.attr('number'),
    service_day: _emberData.default.attr('number'),
    depart_at: _emberData.default.attr('string'),
    return_at: _emberData.default.attr('string'),
    route_stops: _emberData.default.attr('raw')
  });
});