define('walden-service/components/eligible-cuts', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    cuts: null,
    didInsertElement: function didInsertElement() {
      this.set('cuts', []);
    }
  });
});