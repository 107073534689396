define('walden-service/components/sp/tab-manager/pane', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    /**
     * The ID of the tab that this pane belongs to.
     *
     * @argument
     * @type {string}
     * @default null
     * @memberof Components/Sp/Tab-Manager/Pane
     */
    tabId: null,
    classNames: ['sp-tab-manager-pane']
  });
});