define('walden-service/components/member-search', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    session: Ember.inject.service('session'),
    ajax: Ember.inject.service('ajax'),
    init: function init() {
      this._super();
      var self = this;

      $(function () {
        $(document).on('click', '.service-member', function () {
          var memberID = $(this).parent().parent().find('.member-id').html();
          console.log(memberID);
          window.location.href = '/member/' + memberID;
          //self.get('serviceMember')(memberID);
        });
      });

      $(document).on('click', '.service-order', function () {
        var orderId = $(this).parent().parent().find('.order-id').html();
        console.log(orderId);
        self.get('serviceOrder')(orderId);
      });
    },
    actions: {
      searchMembers: function searchMembers(e) {
        var self = this;
        var srcVal = encodeURIComponent($(e.target).val());
        var nameVal = null;

        if ($(e.target).val().indexOf(' ') !== -1) {
          nameVal = $(e.target).val().split(' ');
        }

        if (srcVal.length > 1) {
          self.get('ajax').request('members?email=' + srcVal + ':LIKE:OR&first_name=' + (nameVal ? nameVal[0] : srcVal) + ':LIKE:OR&last_name=' + (nameVal ? nameVal[1] : srcVal) + ':LIKE:OR&phone=' + srcVal + ':LIKE:OR&id=' + srcVal + ':LIKE:OR', {
            headers: {
              token: self.get('session').session.content.authenticated.token
            }
          }).then(function (response) {
            $('.remove').hide();
            var members = response.members;
            var resultContainer = $('.member-result-container');

            resultContainer.html('');

            members.every(function (member) {
              var result = $('#template .member-result').clone();

              result.find('.member-id').html(member.id);
              result.find('.member-name').html(member.first_name + ' ' + member.last_name);
              result.find('.member-email').html(member.email);
              result.find('.member-phone').html(member.phone);

              resultContainer.append(result);
              return true;
            });

            return true;
          });
        }
      },
      searchMemberAddress: function searchMemberAddress(e) {
        console.log($(e.targe).val());
        $('.remove').hide();
        var resultContainer = $('.member-result-container');
        var self = this;
        var srcVal = $(e.target).val();
        if (srcVal.length > 1) {
          self.get('ajax').request('addresses?formatted_address=' + srcVal + ':LIKE:OR&address1=' + srcVal + ':LIKE:OR&address2=' + srcVal + ':LIKE:OR', {
            headers: {
              token: self.get('session').session.content.authenticated.token
            }
          }).then(function (response) {
            console.log(response);

            resultContainer.html('');
            if (response.addresses.length > 0) {
              resultContainer.html('');
              var _iteratorNormalCompletion = true;
              var _didIteratorError = false;
              var _iteratorError = undefined;

              try {
                for (var _iterator = response.addresses[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                  var address = _step.value;

                  self.get('ajax').request('members/' + address.member, {
                    headers: {
                      token: self.get('session').session.content.authenticated.token
                    }
                  }).then(function (response) {
                    var member = response.member;

                    var result = $('#template .member-result').clone();

                    result.find('.member-id').html(member.id);
                    result.find('.member-name').html(member.first_name + ' ' + member.last_name);
                    result.find('.member-email').html(member.email);
                    result.find('.member-phone').html(member.phone);

                    resultContainer.append(result);
                  });
                }
              } catch (err) {
                _didIteratorError = true;
                _iteratorError = err;
              } finally {
                try {
                  if (!_iteratorNormalCompletion && _iterator.return) {
                    _iterator.return();
                  }
                } finally {
                  if (_didIteratorError) {
                    throw _iteratorError;
                  }
                }
              }
            }
          });
        } else {
          console.log('none');
          resultContainer.html('');
          $('.remove').show();
        }
      },
      serviceUser: function serviceUser(e) {
        console.log(e);
      },
      searchOrders: function searchOrders(e) {
        var self = this;
        var srcVal = $(e.target).val();
        if (srcVal.length > 0) {
          self.get('ajax').request('orders?member=' + srcVal + ':LIKE:OR&id=' + srcVal + ':LIKE:OR', {
            headers: {
              token: self.get('session').session.content.authenticated.token
            }
          }).then(function (response) {
            console.log(response);
            $('.remove').hide();
            var orders = response.orders;
            var resultContainer = $('.order-result-container');

            resultContainer.html('');

            orders.every(function (order) {
              var result = $('#template .order-result').clone();

              result.find('.order-id').html(order.id);
              result.find('.order-status').html('Share #' + order.id);
              result.find('.order-created').html(order.created_at);
              result.find('.order-updated').html(order.updated_at);
              result.find('.member-name').html(order.member_details.first_name + ' ' + order.member_details.last_name);
              result.find('.member-email').html(order.member_details.email);

              resultContainer.append(result);
              return true;
            });

            return true;
          });
        }
      },
      serviceOrder: function serviceOrder(e) {
        console.log(e);
      }
    }
  });
});