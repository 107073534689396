define('walden-service/helpers/ctod', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ctod = function ctod(params) {
    return '$' + parseInt(Math.floor(params[0] / 100));
  };
  exports.default = Ember.Helper.helper(ctod);
});