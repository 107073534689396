define('walden-service/models/zip', ['exports', 'ember-data', 'ember-data/attr'], function (exports, _emberData, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    code: (0, _attr.default)('string'),
    active: (0, _attr.default)('boolean'),
    soon: (0, _attr.default)('boolean'),
    centroid: _emberData.default.attr('raw'),
    hull: _emberData.default.attr('raw'),
    grpId: _emberData.default.attr('number', { defaultValue: null }),
    color: _emberData.default.attr('string', { defaultValue: null }),
    dayIndex: _emberData.default.attr('number', { defaultValue: null }),
    orders: _emberData.default.attr('raw', { defaultValue: null }),
    region: _emberData.default.attr('number')
  });
});