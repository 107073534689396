define('walden-service/adapters/share', ['exports', 'walden-service/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    ajaxService: Ember.inject.service('ajax'),
    session: Ember.inject.service('session'),
    pathForType: function pathForType(type) {
      return 'orders';
    }
  });
});