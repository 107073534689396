define('walden-service/models/share-type', ['exports', 'ember-data', 'ember-data/model', 'ember-data/attr'], function (exports, _emberData, _model, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    display_order: _emberData.default.attr('number'),
    image_uri: _emberData.default.attr('string'),
    shareSizes: _emberData.default.hasMany('shareSize', { embedded: 'always' }),
    selected: _emberData.default.attr('boolean', { defaultValue: false })
  });
});