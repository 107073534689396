define('walden-service/components/walden-utils', ['exports', 'walden-service/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    session: Ember.inject.service('session'),
    ajax: Ember.inject.service('ajax'),
    didInsertElement: function didInsertElement() {
      var self = this;

      $(document).on('click', '#picksheet-upload-submit', function () {
        self.uploadPickData();
      });
    },
    actions: {},
    uploadPickData: function uploadPickData() {
      var self = this;
      var formData = new FormData();
      formData.append('file', $('#picksheet-upload')[0].files[0]);

      $.ajax({
        url: _environment.default.walden_api + '/pick-upload',
        headers: {
          token: self.get('session').session.content.authenticated.token
        },
        type: 'POST',
        data: formData,
        processData: false, // tell jQuery not to process the data
        contentType: false, // tell jQuery not to set contentType
        success: function success(data) {
          alert('Successfully uploaded pick data!');
          location.reload(true);
        },
        error: function error(err) {
          alert('Something went wrong: ' + err.toString);
          console.log(err);
        }
      });
    }
  });
});