define('walden-service/serializers/zip-group', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    isNewSerializerAPI: true,
    attrs: {
      zips: { serialize: 'ids', deserialize: 'records' },
      service_day: { serialize: 'ids', deserialize: 'records' },
      service_partition: { serialize: 'ids', deserialize: 'records' },
      pack_day: { serialize: false }
    },
    serializeIntoHash: function serializeIntoHash(hash, type, record, options) {
      hash['zip_group'] = this.serialize(record, options);
    },
    normalizeUpdateRecordResponse: function normalizeUpdateRecordResponse(store, primaryModelClass, payload, id, requestType) {
      payload = {
        'zip-group': payload.zip_group
      };

      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });
});