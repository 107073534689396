define('walden-service/router', ['exports', 'walden-service/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('login');
    // this.route('map');
    this.route('members');
    this.route('member', { path: 'member/:memberid' });
    this.route('scheduling');
    this.route('configure');
    this.route('order', { path: 'order/:orderid' });
    this.route('tools');
    this.route('orders');
    this.route('utilities');
    this.route('snapshot', { path: 'snapshot/:scid' });
    this.route('schistory', { path: 'schistory/:memberid' });
    this.route('problems');
    this.route('routing');
    this.route('specials');
    this.route('calendar', { path: 'calendar/:index' });
    this.route('service-day', { path: 'service-day/:id' });
    this.route('holiday-order', { path: 'holiday-order/:orderid' });
    this.route('holiday-scheduling');

    // Routes & driver sheets.
    this.route('routes', function () {
      this.route('index', { path: '/' }); // Lists months.
      this.route('partition', { path: '/partition/:id' }); // Lists days in a month.
      this.route('day', { path: '/day/:id' }); // Lists routes for a day.
      this.route('route', { path: '/route/:id' }); // Individual route details.
    });

    // For debug.
    this.route('loading');
    // Catch-all route.
    this.route('404', { path: '/*' });
    this.route('pilots', function () {
      this.route('pilot', { path: '/:id' });
    });

    // Scheduled events (packing jobs, emails, etc.)
    this.route('events', function () {
      this.route('index', { path: '/' });
    });
  });

  exports.default = Router;
});