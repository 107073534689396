define('walden-service/components/loader-ball-scale-ripple-multiple', ['exports', 'ember-cli-loaders/components/loader-ball-scale-ripple-multiple'], function (exports, _loaderBallScaleRippleMultiple) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _loaderBallScaleRippleMultiple.default;
    }
  });
});