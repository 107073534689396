define('walden-service/serializers/cutgroup', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    isNewSerializerAPI: true,
    attrs: {
      cuts: { serialize: 'records', deserialize: 'records' }
    },
    normalizeFindAllResponse: function normalizeFindAllResponse(store, primaryModelClass, payload, id, requestType) {
      console.log(payload);
      payload = {
        'cut-groups': payload.cut_groups
      };

      return this._super(store, primaryModelClass, payload, id, requestType);
    },
    normalizeQueryResponse: function normalizeQueryResponse(store, primaryModelClass, payload, id, requestType) {
      // for (let listing of payload.categories) {
      //   listing.cutgroups = listing.cut_groups;
      //   listing.cut_type = listing.type;
      //   delete listing.type;
      //   delete listing.cut_groups;

      //   for (let group of listing.cutgroups) {
      //     delete group.type;
      //   }
      // }
      payload = {
        cutgroups: payload.cut_groups
        // 'cut-groups': payload.cut_groups
      };

      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });
});