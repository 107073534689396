define('walden-service/models/service-holiday', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    event_start: _emberData.default.attr('string'),
    event_end: _emberData.default.attr('string'),
    active: _emberData.default.attr('boolean'),
    created_at: _emberData.default.attr('string'),
    updated_at: _emberData.default.attr('string'),
    service_days: _emberData.default.hasMany('holiday-service-day')
  });
});