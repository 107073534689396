define('walden-service/helpers/moment-month', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.momentMonth = momentMonth;
  function momentMonth(params /*, hash*/) {
    return _moment.default.months(params[0]);
  }

  exports.default = Ember.Helper.helper(momentMonth);
});