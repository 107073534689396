define('walden-service/adapters/cutgroup', ['exports', 'walden-service/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    ajax: Ember.inject.service('ajax'),
    findAll: function findAll(modelName, query) {
      return this.get('ajax').request('/shareconfig/cut-groups', {
        method: 'GET'
      }).then(function (response) {
        return response;
      });
    },
    query: function query(store, type, query) {
      var cut_type = query.filter.cut_type;
      var url = 'shareconfig/cut-groups?cut_type=' + cut_type + '&active=1';
      return this.get('ajax').request(url, {
        method: 'GET'
      }).then(function (response) {
        console.log('eeeeee', response);
        return response;
      });
    }
  });
});