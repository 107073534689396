define('walden-service/models/share-config', ['exports', 'ember-data', 'ember-data/model', 'ember-data/attr'], function (exports, _emberData, _model, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    member: _emberData.default.attr('number', { allowNull: true }),
    meat_exclusions: _emberData.default.hasMany('meat-type'),
    cut_group_exclusions: _emberData.default.hasMany('cutgroup'),
    cut_group_inclusions: _emberData.default.hasMany('cutgroup'),
    dietary_restrictions: _emberData.default.hasMany('dietary-restriction', { allowNull: true }),
    share_people: _emberData.default.belongsTo('share-people'),
    share_type: _emberData.default.belongsTo('share-type', { async: false, embedded: 'always' }),
    share_size: _emberData.default.belongsTo('share-size', { async: false, embedded: 'always' }),
    share_addons: _emberData.default.hasMany('addon'),
    share_frequency: _emberData.default.belongsTo('share-frequency'),
    custom: _emberData.default.attr('boolean'),
    inProgress: _emberData.default.attr('boolean')
  });
});