define('walden-service/components/loader-line-scale', ['exports', 'ember-cli-loaders/components/loader-line-scale'], function (exports, _loaderLineScale) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _loaderLineScale.default;
    }
  });
});