define('walden-service/models/pilot-member', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    pilot: _emberData.default.belongsTo('pilot'),
    member: _emberData.default.attr(),
    waitlist: _emberData.default.attr('boolean'),
    opt_out: _emberData.default.attr('boolean')
  });
});