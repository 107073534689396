define('walden-service/components/configure-cut-groups', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        session: Ember.inject.service('session'),
        ajax: Ember.inject.service('ajax'),
        cutGroups: {},
        cutTypes: {},
        activeGroup: {},
        didInsertElement: function didInsertElement() {
            console.log('loading cut groups');
            var self = this;
            self.getCutTypes();
            self.loadCutGroups();
            $(document).on('click', '.cg-list tr', function () {
                var cutGroup = $(this).attr('cgid');
                console.log(cutGroup);

                self.populateModalFields(cutGroup);

                $('#cutGroupModal').modal('show');
            });

            $(document).on('click', '.cg-move', function () {
                if ($(this).parent().hasClass('cut-select')) {
                    var current = $(this).detach().appendTo('.cuts-in-group');
                    // $('.cuts-in-group').append(current);
                } else {
                    var current = $(this).detach().appendTo('.cut-select');
                    // $('.cut-select').append(current);
                }
            });

            $(document).on('click', '.create-save-cut-group', function () {
                self.saveCutGroup();
            });
        },
        populateModalFields: function populateModalFields(cgid) {
            var self = this;
            var cg = self.cutGroups[cgid];
            self.activeGroup = cg;
            $('.cg-name').val(cg.name);
            if (cg.active) {
                $('.cg-active').attr('checked', 'checked');
            } else {
                $('.cg-active').removeAttr('checked');
            }
            $('.cg-type').val(cg.cut_type);
            $('.cg-display').val(cg.display_section);
        },
        loadCutGroups: function loadCutGroups() {
            var self = this;
            var token = self.get('session').session.content.authenticated.token;
            self.get('ajax').request('shareconfig/cut-groups?count=1000', {
                method: 'GET',
                headers: {
                    'token': token,
                    'content-type': 'application/json'
                }
            }).then(function (response) {
                var _iteratorNormalCompletion = true;
                var _didIteratorError = false;
                var _iteratorError = undefined;

                try {

                    for (var _iterator = response.cut_groups[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                        var cg = _step.value;

                        self.cutGroups[cg.id] = cg;
                        var newTr = $('<tr>');
                        var newTd = $('<td>');

                        var nameTd = newTd.clone().html(cg.name);
                        var activeTd = newTd.clone().html(cg.active ? 'Active' : 'Hidden');

                        newTr.attr('cgid', cg.id).append(nameTd).append(activeTd);

                        $('.cg-list').append(newTr);

                        $('.cut-select').append('<div class="cg-move" cg_sel=' + cg.id + '>' + cg.name + ' (' + (cg.active ? 'Active' : 'Hidden') + ') <span class="icon icon-check"></span></div>');
                    }
                } catch (err) {
                    _didIteratorError = true;
                    _iteratorError = err;
                } finally {
                    try {
                        if (!_iteratorNormalCompletion && _iterator.return) {
                            _iterator.return();
                        }
                    } finally {
                        if (_didIteratorError) {
                            throw _iteratorError;
                        }
                    }
                }

                console.log(response);
            });
        },
        getCutTypes: function getCutTypes() {
            var self = this;
            var token = self.get('session').session.content.authenticated.token;
            self.get('ajax').request('shareconfig/cut-types?count=1000', {
                method: 'GET',
                headers: {
                    'token': token,
                    'content-type': 'application/json'
                }
            }).then(function (response) {
                console.log(response);
                var _iteratorNormalCompletion2 = true;
                var _didIteratorError2 = false;
                var _iteratorError2 = undefined;

                try {
                    for (var _iterator2 = response.cut_types[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
                        var ct = _step2.value;

                        self.cutTypes[ct.id] = ct;
                        $('.cg-type').append('<option value="' + ct.id + '">' + ct.name + '</option>');
                    }
                } catch (err) {
                    _didIteratorError2 = true;
                    _iteratorError2 = err;
                } finally {
                    try {
                        if (!_iteratorNormalCompletion2 && _iterator2.return) {
                            _iterator2.return();
                        }
                    } finally {
                        if (_didIteratorError2) {
                            throw _iteratorError2;
                        }
                    }
                }
            });
        },
        saveCutGroup: function saveCutGroup() {
            var self = this;
            var token = self.get('session').session.content.authenticated.token;

            self.cutGroups[self.activeGroup.id].name = $('.cg-name').val();
            self.cutGroups[self.activeGroup.id].active = $('.cg-active').is(':checked');
            self.cutGroups[self.activeGroup.id].cut_type = $('.cg-type').val();
            self.cutGroups[self.activeGroup.id].display_section = $('.cg-display').val();

            var newCuts = [];
            $('.cuts-in-group .cg-move').each(function () {
                newCuts.push($(this).attr('cg_sel'));
            });

            self.get('ajax').request('shareconfig/cut-groups/' + self.activeGroup.id, {
                method: 'PUT',
                headers: {
                    'token': token,
                    'content-type': 'application/json'
                },
                data: {
                    'cut_group': {
                        name: $('.cg-name').val(),
                        active: $('.cg-active').is(':checked'),
                        cut_type: $('.cg-type').val(),
                        display_section: $('.cg-display').val()
                    }
                }
            }).then(function (response) {
                console.log(response);
            });
        },
        deleteCutGroupAssoc: function deleteCutGroupAssoc(cut) {}
    });
});