define('walden-service/controllers/order', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    ajax: Ember.inject.service('ajax'),
    actions: {
      setItemWeight: function setItemWeight(item, weight) {
        this.get('ajax').request('/packed-items/' + item.id, {
          method: 'PUT',
          data: {
            packed_item: {
              packed_weight: weight
            }
          }
        }).then(function (resp) {
          item.set('packed_weight', weight);
          /*
           *item.set('needs_weight', false);
           */
        }).catch(function (err) {
          console.error(err);
          alert('An error has occurred. Please try entering the weight again.');
        });
      },
      shareQuickLookup: function shareQuickLookup(id) {
        this.transitionToRoute('order', id);
      }
    }
  });
});