define('walden-service/helpers/ctod-exact', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  /**
   * Tempalte helper that creates an extact dollar figure from a provided amount of pennies.
   */
  var ctodExact = function ctodExact(params) {
    var value = (parseInt(params[0]) / 100).toFixed(2);
    if (params[1]) {
      value = (value - 5).toFixed(2);
    }
    if (value % 1 === 0) {
      value = Number(value).toFixed(0);
    }
    return '$' + value;
  };
  exports.default = Ember.Helper.helper(ctodExact);
});