define('walden-service/components/sp/tab-manager/tab', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = Ember.Component.extend({
    classNames: ['sp-tab-manager-tab'],
    classNameBindings: ['active'],
    /**
     * Whether or not this is the active tab within the tab manager.
     *
     * @computed
     * @type {boolean}
     * @returns {boolean} Whether or not this is the active tab.
     * @memberof Components/Sp/Tab-Manager/Tab
     */
    active: computed('selectedTabId', function () {
      return this.get('tabId') === this.get('selectedTabId');
    }),
    /**
     * Invoke the closure on selecting this tab.
     *
     * @override
     * @method click
     * @returns {null}
     * @memberof Components/Sp/Tab-Manager/Tab
     */
    click: function click(e) {
      e.preventDefault();
      if (this.get('onClick')) {
        this.get('onClick')(this.get('tabId'));
      }
    }
  });
});