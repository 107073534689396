define('walden-service/controllers/application', ['exports', 'walden-service/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    actions: {
      invalidateSession: function invalidateSession() {
        this.get('session').invalidate();
        // window.location.href = "/";
        this.transitionToRoute('/login');
      }
    },
    appEnv: _environment.default.environment
  });
});