define('walden-service/controllers/routes/partition', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = Ember.Controller.extend({
    breadcrumbs: computed('month_string', function () {
      return [{ active: false, link: '/', name: 'Routes' }, { active: true, link: '', name: this.get('month_string') }];
    }),
    month_string: computed('model.partition.active_starting', function () {
      return (0, _moment.default)(this.get('model.partition.active_starting')).format('MMMM YYYY');
    })
  });
});