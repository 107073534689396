define('walden-service/serializers/billing', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTSerializer.extend({
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      payload.billing = payload.details;
      payload.billing.id = 0;

      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });
});