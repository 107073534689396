define('walden-service/helpers/moment-generic', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.momentGeneric = momentGeneric;
  function momentGeneric(params /*, hash*/) {
    var s = null;
    try {
      var format = params[0];
      var date = params[1];
      if (date && format) {
        s = _moment.default.utc(date).format(format);
      }
    } catch (e) {}
    return s;
  }

  exports.default = Ember.Helper.helper(momentGeneric);
});