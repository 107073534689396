define('walden-service/controllers/problems', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['category', 'filter'],
    category: null,
    filter: null,
    actions: {
      /**
       * Routes to the either the member or order page using the id
       * of the clicked table row in the view (i.e. members/{id} or orders/{id}).
       * @param {string} category - Problem category (order or member)
       * @param {number} id - Resource id (for a member or an order)
       */
      viewProblem: function viewProblem(category, id) {
        if (category === 'member') {
          this.transitionToRoute('member', id);
        } else if (category === 'order') {
          this.transitionToRoute('order', id);
        }
      }
    }
  });
});