define('walden-service/models/share', ['exports', 'ember-data', 'moment'], function (exports, _emberData, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    member: _emberData.default.attr('number'),
    notes: _emberData.default.attr('string'),
    share_config: _emberData.default.belongsTo('share-config'),
    status: _emberData.default.attr('string'),
    service_date: _emberData.default.belongsTo('service-date'),
    transactions: _emberData.default.hasMany('transaction', { async: false }),
    addons: _emberData.default.hasMany('addons', { async: false }),
    packed_items: _emberData.default.attr('raw'), // TODO: Make packed_items a model,
    service_partition: _emberData.default.attr('raw'),
    address_details: _emberData.default.belongsTo('address', { async: false }),
    legacy: _emberData.default.attr('raw'),
    delivered_at: _emberData.default.attr('raw'),
    pickup: _emberData.default.attr('boolean'),
    packed_weight: _emberData.default.attr('raw'),
    complete: _emberData.default.attr('raw'),
    human_month: Ember.computed('service_date', function () {
      var format = 'MMMM, YYYY';
      try {
        if (this.get('legacy')) {
          // Handle dates for legacy orders.
          var legacy_fields = JSON.parse(this.get('legacy'));
          if (Array.isArray(legacy_fields.deliveryWindow) && legacy_fields.deliveryWindow.length) {
            return (0, _moment.default)(legacy_fields.deliveryWindow[0]).format(format);
          } else if (this.get('delivered_at') !== null) {
            return (0, _moment.default)(this.get('delivered_at')).format(format);
          } else {
            return 'Legacy - Unknown';
          }
        } else {
          return (0, _moment.default)().month(this.get('service_partition').month).year(this.get('service_partition').year).format(format);
        }
      } catch (err) {
        return err.toString();
      }
    })
  });
});