define('walden-service/models/sticky-preference', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    member: _emberData.default.attr('number'),
    cut_details: _emberData.default.belongsTo('cut'),
    cut: _emberData.default.attr('number'),
    inclusion: _emberData.default.attr('boolean'),
    created_at: _emberData.default.attr('string')
  });
});