define('walden-service/components/loader-semi-circle-spin', ['exports', 'ember-cli-loaders/components/loader-semi-circle-spin'], function (exports, _loaderSemiCircleSpin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _loaderSemiCircleSpin.default;
    }
  });
});