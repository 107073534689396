define('walden-service/adapters/share-size', ['exports', 'walden-service/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    pathForType: function pathForType(type) {
      //return Ember.String.pluralize(type);
      return 'share-sizes';
    }
  });
});