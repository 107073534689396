define('walden-service/adapters/share-frequency', ['exports', 'walden-service/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    ajax: Ember.inject.service('ajax'),
    namespace: 'shareconfig',
    pathForType: function pathForType(type) {
      //return Ember.String.pluralize(type);
      return 'share-frequencies';
    },
    findAll: function findAll(modelName, query) {
      return this.get('ajax').request('/shareconfig/share-frequencies?count=9999', {
        method: 'GET'
      }).then(function (response) {
        return response;
      });
    }
  });
});