define('walden-service/serializers/sticky-preference', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    isNewSerializerAPI: true,
    attrs: {
      cut_details: { deserialize: 'records', serialize: false },
      created_at: { serialize: false },
      updated_at: { serialize: false }
    },
    serializeIntoHash: function serializeIntoHash(hash, type, record, options) {
      hash.sticky_preference = this.serialize(record, options);

      console.log('hash going out: ' + JSON.stringify(hash)); // hash is { full of data } going out

      //return hash; // after this, for some reason the request goes out as an empty hash {}
    }
  });
});