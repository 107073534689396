define('walden-service/components/routes/pack-delay', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = Ember.Component.extend({
    // Prevent the wrapper div from appearing.
    tagName: '',

    // These options are passed through to the token button.
    start_disabled: false,
    reload: undefined,

    // If a default value is given, the corresponding option will not be shown.
    default_field: false,
    default_value: false,
    default_status: false,
    default_region: false,

    // Configuration for the form.
    valid_fields: {
      zip_group: 'Zip Group',
      projected_date: 'Service Day',
      route: 'Route',
      id: 'Individual Share',
      pickup: 'Pickup Status'
    },
    valid_statuses: {
      packed: 'Packed',
      dispatched: 'Dispatched'
    },
    valid_regions: {
      all: 'Everywhere (MA & CT)',
      ma: 'MA (Including NH, ME, etc)',
      ct: 'CT (Including NY, NJ, etc)'
    },
    valid_notify: {
      true: 'Send Emails!',
      false: "Don't Send Any Emails"
    },

    // These are the arguments to the API call.
    status_field: '',
    status_value: 0,
    status_status: 'packed',
    status_region: 'all',
    status_date: '2019-01-01',
    status_time: '00:00 PM',
    status_notify: true,
    status_comment: '',

    // Need to update time-based validations on an interval.
    current_time: (0, _moment.default)(),
    validation_interval: null,

    didInsertElement: function didInsertElement() {
      // Set the form fields' date and time to the current date and time.
      this.set('status_date', (0, _moment.default)().format('YYYY-MM-DD'));
      this.set('status_time', (0, _moment.default)().add(1, 'hour').format('HH:mm'));

      // If defaults were set, update our internal values.
      if (this.get('default_value')) this.set('status_value', this.get('default_value'));

      // If a default field is passed, use it.
      if (this.get('default_field')) {
        this.set('status_field', this.get('default_field'));
      } else {
        // Otherwise use the first valid field.
        for (var key in this.get('valid_fields')) {
          this.set('status_field', key);
          break;
        }
      }

      // If a default status is passed, use it.
      if (this.get('default_status')) {
        this.set('status_status', this.get('default_status'));
      } else {
        // Otherwise use the first valid status.
        for (var _key in this.get('valid_statuses')) {
          this.set('status_status', _key);
          break;
        }
      }

      // If a default status is passed, use it.
      if (this.get('default_region')) {
        this.set('status_region', this.get('default_region'));
      } else {
        // Otherwise use the first valid status.
        for (var _key2 in this.get('valid_regions')) {
          this.set('status_region', _key2);
          break;
        }
      }

      // Set the default notification state.
      for (var _key3 in this.get('valid_notify')) {
        this.set('status_notify', _key3);
        break;
      }

      // Update time validations based on this interval.
      var self = this;
      this.set('validation_interval', setInterval(function () {
        self.set('current_time', (0, _moment.default)());
      }, 1000));
    },
    willDestroyElement: function willDestroyElement() {
      clearInterval(this.get('validation_interval'));
    },


    request_body: computed('status_field', 'status_value', 'status_status', 'status_region', 'status_date', 'status_time', 'status_notify', 'status_comment', function () {
      // Construct the event and format each field.
      var event = {
        filter_field: this.get('status_field'),
        filter_value: parseInt(this.get('status_value')),
        region: this.get('status_region'),
        status: this.get('status_status'),
        notify: this.get('status_notify'),
        trigger_at: (0, _moment.default)(this.get('status_date') + ' ' + this.get('status_time'), 'YYYY-MM-DD HH:mm A').toISOString(),
        comment: this.get('status_comment')
      };

      return JSON.stringify(event);
    }),

    valid_time: computed('request_body', 'current_time', function () {
      return (0, _moment.default)(JSON.parse(this.get('request_body')).trigger_at).isAfter((0, _moment.default)().add(5, 'minutes'));
    })
  });
});