define('walden-service/serializers/share-config', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    isNewSerializerAPI: true,
    attrs: {
      share_addons: { serialize: 'records', deserialize: 'records' },
      meat_exclusions: { serialize: 'records', deserialize: 'records' },
      cut_group_exclusions: { serialize: 'records', deserialize: 'records' },
      cut_group_inclusions: { serialize: 'records', deserialize: 'records' },
      share_people: { serialize: 'records', deserialize: 'records' },
      share_type: { serialize: 'records', deserialize: 'records' },
      share_size: { serialize: 'records', deserialize: 'records' },
      dietary_restrictions: { serialize: 'records', deserialize: 'records' },
      share_frequency: { serialize: 'records', deserialize: 'records' }
    },
    // normalizeResponse(store, primaryModelClass, payload, id, requestType) {
    //   if (payload.success) {
    //     delete payload.success;
    //     payload['share-config'] = {};
    //     payload['share-config'].id = new Date().getTime();
    //   }

    //   return this._super(store, primaryModelClass, payload, id, requestType);
    // },
    normalizeQueryResponse: function normalizeQueryResponse(store, primaryModelClass, payload, id, requestType) {
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = payload.share_configs[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var sc = _step.value;

          sc.dietary_restrictions = sc.dietary_exclusions;
          sc.share_addons = sc.recurring_addons;
          sc.share_type = sc.type_details;
          sc.share_size = sc.size_details;
          sc.share_people = sc.people_details;
          var _iteratorNormalCompletion2 = true;
          var _didIteratorError2 = false;
          var _iteratorError2 = undefined;

          try {
            for (var _iterator2 = sc.share_addons[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
              var addon = _step2.value;

              addon.share_addon = addon.share_addons;
            }
          } catch (err) {
            _didIteratorError2 = true;
            _iteratorError2 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion2 && _iterator2.return) {
                _iterator2.return();
              }
            } finally {
              if (_didIteratorError2) {
                throw _iteratorError2;
              }
            }
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      payload = { 'share-configs': payload.share_configs };

      return this._super(store, primaryModelClass, payload, id, requestType);
    },
    normalizeFindRecordResponse: function normalizeFindRecordResponse(store, primaryModelClass, payload, id, requestType) {
      payload.share_config.dietary_restrictions = payload.share_config.dietary_exclusions;
      payload.share_config.share_addons = payload.share_config.recurring_addons;
      //payload.share_config.id = payload.share_config.member;
      var _iteratorNormalCompletion3 = true;
      var _didIteratorError3 = false;
      var _iteratorError3 = undefined;

      try {
        for (var _iterator3 = payload.share_config.share_addons[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
          var addon = _step3.value;

          addon.share_addon = addon.share_addons;
        }
      } catch (err) {
        _didIteratorError3 = true;
        _iteratorError3 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion3 && _iterator3.return) {
            _iterator3.return();
          }
        } finally {
          if (_didIteratorError3) {
            throw _iteratorError3;
          }
        }
      }

      payload = { 'share-config': payload.share_config };
      console.log(payload);
      return this._super(store, primaryModelClass, payload, id, requestType);
    },


    serializeIntoHash: function serializeIntoHash(hash, type, record, options) {
      hash['share-config'] = this.serialize(record, options);
    }
  });
});