define('walden-service/serializers/service-day', ['exports', 'walden-service/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    normalizeFindAllResponse: function normalizeFindAllResponse(store, primaryModelClass, payload, id, requestType) {
      payload = {
        'service-days': payload.service_days
      };
      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });
});