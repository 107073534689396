define('walden-service/models/addon', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data'], function (exports, _model, _attr, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    display: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    image_uri: _emberData.default.attr('string'),
    unit_plural: _emberData.default.attr('string'),
    unit_singular: _emberData.default.attr('string'),
    price: _emberData.default.attr('number'),
    quantity_max: _emberData.default.attr('number'),
    recurring: _emberData.default.attr('boolean'),
    quantity: _emberData.default.attr('number'),
    perMonth: _emberData.default.attr('number', { defaultValue: 0 }),
    freezable: _emberData.default.attr('boolean'),
    global_limit: _emberData.default.attr('boolean'),
    weighed: _emberData.default.attr('boolean'),
    available: _emberData.default.attr('number', { defaultValue: 0 }),
    display_section: _emberData.default.attr('number', { defaultValue: 0 }),
    display_type: _emberData.default.attr('number', { defaultValue: 0 }),
    unit_weight: _emberData.default.attr('number', { defaultValue: 0 }),
    display_order: _emberData.default.attr('number', { defaultValue: 100 }),
    groups: _emberData.default.hasMany('addon-groups'),
    discount_map: _emberData.default.attr('raw'),
    share_addon: _emberData.default.belongsTo('share-addon', { async: false }),
    order_addon: _emberData.default.belongsTo('share-addon', { async: false }),
    availability_ranges: _emberData.default.hasMany('availability-range', { async: false }),
    sublabel: _emberData.default.attr('string')
  });
});