define('walden-service/components/sp/button-base', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'button',
    classNames: ['btn', 'px-4'],
    attributeBindings: ['type', 'disabled'],
    /**
     * The type of button.
     *
     * @argument
     * @type {string}
     * @default 'button'
     * @memberof Components/Sp/Button-Base
     */
    type: 'button',
    /**
     * Whether or not the button is disabled.
     *
     * @argument
     * @type {boolean}
     * @default false
     * @memberof Components/Sp/Button-Base
     */
    disabled: false,
    /**
     * Whether or not the button is loading.
     *
     * @argument
     * @type {boolean}
     * @default false
     * @memberof Components/Sp/Button-Base
     */
    loading: false
  });
});