define('walden-service/serializers/credit', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      payload.credit = {
        balance: payload.balance,
        id: new Date().getTime()
      };

      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });
});