define('walden-service/components/sc-history', ['exports', 'moment'], function (exports, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        session: Ember.inject.service('session'),
        ajax: Ember.inject.service('ajax'),
        positionalParams: ['memberid'],
        memberid: null,
        didInsertElement: function didInsertElement() {
            console.log(this.memberid);
            var self = this;
            this.getShareConfigs();

            $(document).on('click', '.hist-item', function () {
                self.loadConfig($(this).attr('id'));
            });
        },
        getShareConfigs: function getShareConfigs() {
            var self = this;
            self.get('ajax').request('/share-configs?member=' + self.memberid, {
                method: 'GET',
                headers: {
                    token: self.get('session').session.content.authenticated.token
                }
            }).then(function (response) {
                console.log(response);
                var i = 0;
                var _iteratorNormalCompletion = true;
                var _didIteratorError = false;
                var _iteratorError = undefined;

                try {
                    for (var _iterator = response.share_configs[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                        var sc = _step.value;

                        i++;
                        $('.schist-list').prepend('<div class="hist-item" id="' + sc.id + '">' + (0, _moment.default)(sc.created_at).calendar() + '</div>');
                        if (i == response.share_configs.length) {
                            self.loadConfig(sc.id);
                        }
                    }
                } catch (err) {
                    _didIteratorError = true;
                    _iteratorError = err;
                } finally {
                    try {
                        if (!_iteratorNormalCompletion && _iterator.return) {
                            _iterator.return();
                        }
                    } finally {
                        if (_didIteratorError) {
                            throw _iteratorError;
                        }
                    }
                }
            });
        },
        loadConfig: function loadConfig(scid) {
            $('#sc-frame').attr('src', '/snapshot/' + scid);
        }
    });
});