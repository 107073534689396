define('walden-service/helpers/day-color', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.dayColor = dayColor;
  function dayColor(params /*, hash*/) {
    return 'day-' + params[0].toString();
  }

  exports.default = Ember.Helper.helper(dayColor);
});