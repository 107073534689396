define('walden-service/controllers/routes/day', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = Ember.Controller.extend({
    breadcrumbs: computed('day_string', function () {
      return [{ active: false, link: '/', name: 'Routes' }, { active: true, link: '', name: this.get('day_string') }];
    }),
    day_string: computed('model.service_day.calendar_date', function () {
      return (0, _moment.default)(this.get('model.service_day.calendar_date')).format('LL');
    })
  });
});