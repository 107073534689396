define('walden-service/components/routing/routing-control-widget/routing-control-widget', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      removeZipFromGroup: function removeZipFromGroup(zip) {
        this.sendAction('removeZipFromGroup', zip);
      },
      selectPastedZipCodes: function selectPastedZipCodes(codes) {
        this.sendAction('selectPastedZipCodes', codes);
      },
      removeAllSelectedZipcodes: function removeAllSelectedZipcodes() {
        this.sendAction('removeAllSelectedZipcodes');
      },
      moveToZipGroup: function moveToZipGroup(group) {
        this.sendAction('moveToZipGroup', group);
      },
      createZipGroup: function createZipGroup(day) {
        this.sendAction('createZipGroup', day);
      },
      viewZipCodeOrders: function viewZipCodeOrders(zip) {
        this.sendAction('viewZipCodeOrders', zip);
      },
      showSelectedZipsOrderCounts: function showSelectedZipsOrderCounts() {
        this.sendAction('showSelectedZipsOrderCounts');
      }
      // --------- END ACTIONS --------------

    }
  });
});