define('walden-service/serializers/holiday-service-day', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var underscore = Ember.String.underscore;
  exports.default = _emberData.default.RESTSerializer.extend({
    serializeIntoHash: function serializeIntoHash(data, type, record, options) {
      var root = underscore(type.modelName); // API wants holiday_service_day in POST payload instead of holidayServiceDay.
      data[root] = this.serialize(record, options);
    }
  });
});