define('walden-service/helpers/numeq', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var eq = function eq(params) {
    return parseInt(params[0]) === parseInt(params[1]);
  };
  exports.default = Ember.Helper.helper(eq);
});