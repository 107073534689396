define('walden-service/serializers/member', ['exports', 'walden-service/serializers/application', 'ember-data'], function (exports, _application, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    isNewSerializerAPI: true,
    attrs: {
      addresses: { serialize: false, deserialize: 'records' },
      generation: { serialize: false },
      created_at: { serialize: false },
      active: { serialize: false },
      email: { serialize: true },
      legacy_id: { serialize: false }
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (payload.member) {
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = payload.member.addresses[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var addr = _step.value;

            addr.address_zip = addr.zip_code;
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }
      }
      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });
});