define('walden-service/routes/routes/day', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var RSVP = Ember.RSVP;
  exports.default = Ember.Route.extend({
    model: function model(params) {
      return RSVP.hash({
        service_day: this.get('store').find('service-day', params.id),
        zip_groups: this.get('store').query('zip-group', { day: params.id, count: 9999 }),
        routes: this.get('store').query('route', { service_day: params.id, count: 9999 })
      });
    }
  });
});