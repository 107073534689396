define('walden-service/components/configure-promo', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    session: Ember.inject.service('session'),
    ajax: Ember.inject.service('ajax'),
    didInsertElement: function didInsertElement() {
      var self = this;
      self.send('getPromoContent');

      $(document).on('click', '#save-promo-content', function () {
        self.send('savePromoContent');
      });
    },
    actions: {
      getPromoContent: function getPromoContent() {
        var self = this;
        self.get('ajax').request('promos', {
          headers: {
            'token': self.get('session').session.content.authenticated.token
          }
        }).then(function (response) {
          console.log(response);
          var activePromo = response.promos[response.promos.length - 1];
          $('#promo-title').val(activePromo.title);
          $('#promo-content').val(activePromo.content);
          if (activePromo.button) $('#promo-show-button').attr('checked', 'checked');
          $('#promo-button-text').val(activePromo.button_text);
          $('promo-button-uri').val(activePromo.button_uri);
          $('#promo-image-uri').val(activePromo.image_uri);
          $('#promo-image-desc').val(activePromo.image_meta);
        });
      },
      savePromoContent: function savePromoContent() {
        var self = this;
        self.get('ajax').request('promos', {
          method: 'POST',
          headers: {
            'token': self.get('session').session.content.authenticated.token,
            'content-type': 'application/json'
          },
          data: {
            promo: {
              title: $('#promo-title').val(),
              content: $('#promo-content').val(),
              button: $('#promo-show-button').is(':checked'),
              button_text: $('#promo-button-text').val(),
              button_uri: $('promo-button-uri').val(),
              image_uri: $('#promo-image-uri').val(),
              image_meta: $('#promo-image-desc').val(),
              promo_section: 0,
              active: true
            }
          }
        }).then(function (response) {
          console.log(response);
          alert('Success!');
        });
      }
    }
  });
});