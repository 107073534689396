define('walden-service/components/routing/routing-map', ['exports', 'walden-service/util/routing/walden-map'], function (exports, _waldenMap) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    zips: null,
    groups: null,
    colors: null,
    selectedZips: [],
    init: function init() {
      this._super.apply(this, arguments);
      var map = new _waldenMap.default({
        element: 'routing-map',
        origin: { lat: 42.561805, lng: -71.2689 }
      });
      this.set('map', map);
    },
    didInsertElement: function didInsertElement() {
      this.get('map').render();
      this.sendAction('mapRendered', this.get('map'));
      this.showZipCodes();
    },
    showZipCodes: function showZipCodes() {
      var _this = this;

      var zips = this.get('zips').toArray();
      if (zips) {
        var _loop = function _loop(zip) {
          var code = zip.get('code');
          var hull = zip.get('hull');
          var cent = zip.get('centroid');
          if (hull) {
            var coordinates = zip.get('hull.coordinates.firstObject');
            var paths = coordinates.map(function (coord) {
              return { lat: coord[1], lng: coord[0] };
            });
            var self = _this;
            var map = _this.get('map');
            map.createPolygon(code, paths);
            map.addListener('polygon', code, 'click', function () {
              self.sendAction('zipcodeSelected', zip);
            });
          }
        };

        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = zips[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var zip = _step.value;

            _loop(zip);
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }
      }
    },
    didUpdateAttrs: function didUpdateAttrs() {
      var groups = this.get('groups');
      if (groups) {
        this.showZipGroups();
      }
    },
    showZipGroups: function showZipGroups() {
      var map = this.get('map');
      var groups = this.get('groups').toArray();
      var _iteratorNormalCompletion2 = true;
      var _didIteratorError2 = false;
      var _iteratorError2 = undefined;

      try {
        for (var _iterator2 = groups[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
          var grp = _step2.value;

          var zips = grp.get('zips').toArray();
          if (zips.length) {
            var day_index = grp.get('service_day.day_index');
            var grp_color = this.get('colors').objectAt(day_index);
            var _iteratorNormalCompletion3 = true;
            var _didIteratorError3 = false;
            var _iteratorError3 = undefined;

            try {
              for (var _iterator3 = zips[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
                var zip = _step3.value;

                var _code = zip.get('code');
                var poly = map.polygons[_code];
                if (poly) {
                  zip.set('grpId', grp.get('id'));
                  zip.set('dayIndex', day_index);
                  map.updatePolygonColor(_code, grp_color, true);
                }
              }
            } catch (err) {
              _didIteratorError3 = true;
              _iteratorError3 = err;
            } finally {
              try {
                if (!_iteratorNormalCompletion3 && _iterator3.return) {
                  _iterator3.return();
                }
              } finally {
                if (_didIteratorError3) {
                  throw _iteratorError3;
                }
              }
            }

            var hull = grp.get('hull');
            var coords = hull.coordinates[0].map(function (coord) {
              return { lat: coord[1], lng: coord[0] };
            });
            map.createPolyLine(grp.get('id'), coords, grp_color);
            var cent = grp.get('centroid');
            if (cent) {
              var _coords = { lat: cent.coordinates[1], lng: cent.coordinates[0] };
              map.createMarker(grp.get('id'), _coords, grp_color, null, 'group', 'Day ' + grp.get('dayIndex') + ' - ' + grp.get('id'));
            }
          }
        }
      } catch (err) {
        _didIteratorError2 = true;
        _iteratorError2 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion2 && _iterator2.return) {
            _iterator2.return();
          }
        } finally {
          if (_didIteratorError2) {
            throw _iteratorError2;
          }
        }
      }
    }
  });
});