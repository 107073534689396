define('walden-service/helpers/moment-short-date', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.momentFullDate = momentFullDate;
  function momentFullDate(params /*, hash*/) {
    return _moment.default.utc(params[0]).format('(MM/DD/YYYY)');
  }

  exports.default = Ember.Helper.helper(momentFullDate);
});