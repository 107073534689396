define('walden-service/adapters/order', ['exports', 'walden-service/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    query: function query(store, type, _query) {
      var zip = _query.zip;
      var partition = _query.partition;
      var id = _query.id;
      if (zip && partition) {
        var url = '/service/zips/' + zip + '/orders-in-partition/' + partition;
        return this.ajax(url, 'GET');
      } else if (partition) {
        // order search
        var _url = 'orders?partition=' + partition;
        if (id) {
          _url += '&id=' + id + ':LIKE:OR';
        }
        return this.ajax(_url, 'GET');
      }
    }
  });
});