define('walden-service/components/service-day/delivery-groups', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    deliveryMemberTotal: 0,
    deliveryShareTotal: 0,
    stopsTotal: 0,
    didInsertElement: function didInsertElement() {
      this.getTotalMemberCount();
      this.getTotalShareCount();
      this.getTotalStopsCount();
    },
    getTotalMemberCount: function getTotalMemberCount() {
      var memberCountsByZip = this.get('memberCounts');
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = this.get('zipGroups').toArray()[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var group = _step.value;

          //      for (let zip of group.get('zips').toArray()) {
          try {
            this.incrementProperty('deliveryMemberTotal', parseInt(group.get('est_shares')));
          } catch (err) {
            console.log('Failed to find zip while calculating member total');
          }
          //    }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }
    },
    getTotalShareCount: function getTotalShareCount() {
      var shareCountsByGroup = this.get('shareCounts');
      var _iteratorNormalCompletion2 = true;
      var _didIteratorError2 = false;
      var _iteratorError2 = undefined;

      try {
        for (var _iterator2 = this.get('zipGroups').toArray()[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
          var group = _step2.value;

          try {
            var count = shareCountsByGroup.counts.by_group[group.get('id')];
            if (count) {
              this.incrementProperty('deliveryShareTotal', count);
            }
          } catch (err) {
            console.log('Failed to find group while calculating share total');
          }
        }
      } catch (err) {
        _didIteratorError2 = true;
        _iteratorError2 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion2 && _iterator2.return) {
            _iterator2.return();
          }
        } finally {
          if (_didIteratorError2) {
            throw _iteratorError2;
          }
        }
      }
    },
    getTotalStopsCount: function getTotalStopsCount() {
      var _iteratorNormalCompletion3 = true;
      var _didIteratorError3 = false;
      var _iteratorError3 = undefined;

      try {
        for (var _iterator3 = this.get('zipGroups').toArray()[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
          var group = _step3.value;

          this.incrementProperty('stopsTotal', group.get('route_density'));
        }
      } catch (err) {
        _didIteratorError3 = true;
        _iteratorError3 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion3 && _iterator3.return) {
            _iterator3.return();
          }
        } finally {
          if (_didIteratorError3) {
            throw _iteratorError3;
          }
        }
      }
    }
  });
});