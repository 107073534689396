define('walden-service/components/dashboard/dashboard-metrics', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    session: Ember.inject.service('session'),
    ajax: Ember.inject.service('ajax'),
    didInsertElement: function didInsertElement() {
      var self = this;
      var now = new Date().toUTCString();
      var ctxShareRecs = document.getElementById('share-recurrences-chart').getContext('2d');
      var ctxShareTypes = document.getElementById('share-types-chart').getContext('2d');
      this.get('ajax').request('analytics/share-stats', {
        method: 'GET',
        headers: {
          token: self.get('session').session.content.authenticated.token
        }
      }).then(function (resp) {
        var shares = resp.shares;

        var shareRecLabels = [];
        var shareRecData = [];
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = shares.freqs[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var freq = _step.value;

            shareRecLabels.push(freq.freq);
            shareRecData.push(freq.count);
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }

        var shareTypeLabels = [];
        var shareTypeData = [];
        var _iteratorNormalCompletion2 = true;
        var _didIteratorError2 = false;
        var _iteratorError2 = undefined;

        try {
          for (var _iterator2 = shares.types[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
            var type = _step2.value;

            shareTypeLabels.push(type['type']);
            shareTypeData.push(type.count);
          }
        } catch (err) {
          _didIteratorError2 = true;
          _iteratorError2 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion2 && _iterator2.return) {
              _iterator2.return();
            }
          } finally {
            if (_didIteratorError2) {
              throw _iteratorError2;
            }
          }
        }

        var shareRecChart = new Chart(ctxShareRecs, {
          type: 'doughnut',
          data: {
            labels: shareRecLabels,
            datasets: [{
              data: shareRecData,
              backgroundColor: ['rgb(255, 99, 132)', 'rgb(54, 162, 235)', 'rgb(255, 206, 86)']
            }]
          }
        });

        var shareTypeChart = new Chart(ctxShareTypes, {
          type: 'doughnut',
          data: {
            labels: shareTypeLabels,
            datasets: [{
              data: shareTypeData,
              backgroundColor: ['rgb(255, 99, 132)', 'rgb(54, 162, 235)', 'rgb(255, 206, 86)']
            }]
          }
        });
      });

      this.get('ajax').request('analytics/enrollment-stats', {
        method: 'GET',
        headers: {
          token: self.get('session').session.content.authenticated.token
        },
        data: {
          date: now
        }
      }).then(function (resp) {
        var members = resp.enrollments;

        var newThisMonth = members.months.curr.new;
        var cancelledThisMonth = members.months.curr.cancelled;

        var newLastMonth = members.months.pre.new;
        var cancelledLastMonth = members.months.pre.cancelled;

        var pctDiffNewMembers = getPercentDifference(newThisMonth, newLastMonth).toFixed(2);

        var newMembersHtml = '<h3 class="statcard-number">' + newThisMonth + '</h3>\n             <span class="statcard-desc">New Members</span>\n             <span class="font-weight-bold delta-indicator">' + pctDiffNewMembers + '%</span>';
        $('#stats-new-members').append(newMembersHtml);

        if (pctDiffNewMembers < 0) {
          $('#stats-new-members').find('.delta-indicator').addClass('delta-negative');
          $('#stats-new-members').find('.statcard-number').addClass('text-success');
        } else if (pctDiffNewMembers > 0) {
          $('#stats-new-members').find('.delta-indicator').addClass('delta-positive');
          $('#stats-new-members').find('.statcard-number').addClass('text-success');
        }

        var pctDiffCancelledMembers = getPercentDifference(cancelledThisMonth, cancelledLastMonth).toFixed(2);
        var cancelledMembersHtml = '<h3 class="statcard-number">' + cancelledThisMonth + '</h3>\n             <span class="statcard-desc">Cancelled Members</span>\n             <span class="font-weight-bold delta-indicator">' + pctDiffCancelledMembers + '%</span>';
        $('#stats-cancelled-members').append(cancelledMembersHtml);

        if (pctDiffCancelledMembers < 0) {
          $('#stats-cancelled-members').find('.delta-indicator').addClass('delta-negative').css('color', '#1BC98E');
          $('#stats-cancelled-members').find('.statcard-number').addClass('text-danger');
        } else if (pctDiffCancelledMembers > 0) {
          $('#stats-cancelled-members').find('.delta-indicator').addClass('delta-positive').css('color', '#E64759');
          $('#stats-cancelled-members').find('.statcard-number').addClass('text-danger');
        }
      });

      this.get('ajax').request('analytics/referral-stats', {
        method: 'GET',
        headers: {
          token: self.get('session').session.content.authenticated.token
        },
        data: {
          date: now,
          sorted: true,
          descending: true
        }
      }).then(function (resp) {
        var referrals = resp.referrals;
        var totalReferrals = 0;
        var _iteratorNormalCompletion3 = true;
        var _didIteratorError3 = false;
        var _iteratorError3 = undefined;

        try {
          for (var _iterator3 = referrals[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
            var ref = _step3.value;

            totalReferrals += ref.count;
          }
        } catch (err) {
          _didIteratorError3 = true;
          _iteratorError3 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion3 && _iterator3.return) {
              _iterator3.return();
            }
          } finally {
            if (_didIteratorError3) {
              throw _iteratorError3;
            }
          }
        }

        var _iteratorNormalCompletion4 = true;
        var _didIteratorError4 = false;
        var _iteratorError4 = undefined;

        try {
          for (var _iterator4 = referrals[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
            var _ref = _step4.value;

            var refWidth = getWidthPercentage(_ref.count, totalReferrals);
            var refHtml = '<a class="list-group-item list-group-item-action justify-content-between d-flex">\n                <span class="list-group-progress" style="width: ' + refWidth + ';" ></span>\n                <span>' + _ref.name + '</span>\n                <span class="text-muted">' + _ref.count + '</span>\n              </a>';
            $('#stats-referrals').append(refHtml);
          }
        } catch (err) {
          _didIteratorError4 = true;
          _iteratorError4 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion4 && _iterator4.return) {
              _iterator4.return();
            }
          } finally {
            if (_didIteratorError4) {
              throw _iteratorError4;
            }
          }
        }
      });

      this.get('ajax').request('analytics/addon-counts', {
        method: 'GET',
        headers: {
          token: self.get('session').session.content.authenticated.token
        },
        data: {
          date: now,
          sorted: true,
          descending: true
        }
      }).then(function (resp) {
        var addons = resp.addons;
        var totalAddons = 0;
        for (var i = 0; i < 10; i++) {
          if (i < addons.length) {
            var addon = addons[i];
            totalAddons += addon.count;
          }
        }
        for (var _i = 0; _i < 10; _i++) {
          // only want the top 10 addons
          if (_i < addons.length) {
            // extra check incase there aren't 10 addons
            var _addon = addons[_i];
            var addonWidth = getWidthPercentage(_addon.count, totalAddons);
            var addonHtml = '<a class="list-group-item list-group-item-action justify-content-between d-flex">\n                  <span class="list-group-progress" style="width: ' + addonWidth + ';" ></span>\n                  <span>' + _addon.name + '</span>\n                  <span class="ftext-muted">' + _addon.count + '</span>\n                </a>';
            $('#stats-addon-ranks').append(addonHtml);
          }
        }
      });

      this.get('ajax').request('quotes', {
        method: 'GET'
      }).then(function (resp) {
        var quote = resp.quote;
        var quoteHtml = '<blockquote class="blockquote text-center">\n            <p class="mb-0">' + quote.text + '</p>\n            <footer class="blockquote-footer">' + quote.author + '</footer>\n           </blockquote>';
        $('#random-quote').html(quoteHtml);
      });
    }
  });


  function getWidthPercentage(numerator, denom) {
    return (numerator / denom).toFixed(2) * 100 + '%';
  }

  function getPercentDifference(v1, v2) {
    if (v1 === 0 && v2 === 0) return 0;
    return (v1 - v2) / ((v1 + v2) / 2) * 100;
  }
});