define('walden-service/helpers/moment-human-date', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.momentHumanDate = momentHumanDate;
  function momentHumanDate(params /*, hash*/) {
    return moment.utc(params[0]).format('dddd MMMM DD, YYYY');
  }

  exports.default = Ember.Helper.helper(momentHumanDate);
});