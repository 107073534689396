define('walden-service/models/share-size', ['exports', 'ember-data', 'ember-data/model', 'ember-data/attr'], function (exports, _emberData, _model, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _DS$Model$extend;

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  exports.default = _emberData.default.Model.extend((_DS$Model$extend = {
    display: _emberData.default.attr('string'),
    display_order: _emberData.default.attr('number')
  }, _defineProperty(_DS$Model$extend, 'display_order', _emberData.default.attr('number')), _defineProperty(_DS$Model$extend, 'price', _emberData.default.attr('number')), _defineProperty(_DS$Model$extend, 'weight_lower', _emberData.default.attr('number')), _defineProperty(_DS$Model$extend, 'weight_upper', _emberData.default.attr('number')), _defineProperty(_DS$Model$extend, 'share_type', _emberData.default.belongsTo('shareType', { embedded: 'always' })), _defineProperty(_DS$Model$extend, 'selected', _emberData.default.attr('boolean', { defaultValue: false })), _defineProperty(_DS$Model$extend, 'visible', _emberData.default.attr('boolean', { defaultValue: false })), _DS$Model$extend));
});