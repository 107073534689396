define('walden-service/adapters/application', ['exports', 'ember-data', 'ember-simple-auth/mixins/data-adapter-mixin', 'ember-ajax/mixins/ajax-support', 'walden-service/config/environment', 'ember-inflector'], function (exports, _emberData, _dataAdapterMixin, _ajaxSupport, _environment, _emberInflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTAdapter.extend(_dataAdapterMixin.default, _ajaxSupport.default, {
    authorizer: 'authorizer:token',
    host: _environment.default.walden_api,
    pathForType: function pathForType(type) {
      return _emberInflector.default.inflector.pluralize(Ember.String.dasherize(type));
    },

    headers: {
      'Content-Type': 'application/json'
    }
  });
});