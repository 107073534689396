define('walden-service/routes/configure', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    actions: {
      didTransition: function didTransition() {
        $('body').css('border-top', '5px solid #e67e22');
      }
    }
  });
});