define('walden-service/components/dashboard/dashboard-problem-orders', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    session: Ember.inject.service('session'),
    ajax: Ember.inject.service('ajax'),
    // ---------------------------------
    // Actions

    actions: {
      /**
       * Invokes the 'viewProblemsPage' action in the 'index' controller
       * after user clicks a problem type block on the dashboard page.
       * See 'index' controller 'viewProblemsPage' action documentation.
       * @param {string} category - Problem category to view (order or member)
       * @param {string} filter - Problem filter to apply for the selected
       * category (i.e. 'unpaid-addons')
       */
      viewProblemsPage: function viewProblemsPage(category, filter) {
        this.sendAction('viewProblemsPage', category, filter);
      }
    },
    /**
     * TODO: Utilize a model to prevent all of this unnecessary jQuery usage.
     */
    didInsertElement: function didInsertElement() {
      var problemPromises = [];

      var orderProblems = this.get('ajax').request('analytics/problems/orders', {
        method: 'GET',
        data: {
          count: true
        }
      });
      var memberProblems = this.get('ajax').request('analytics/problems/members', {
        method: 'GET',
        data: {
          count: true
        }
      });

      problemPromises.push(orderProblems);
      problemPromises.push(memberProblems);

      Promise.all(problemPromises).then(function (problems) {
        var orderProblems = problems[0].problems;
        var memberProblems = problems[1].problems;

        var orderProblemCount = 0;
        var orderProblemCountMap = {
          'non-cancelled-failed-transactions': 0,
          'unpaid-addons': 0,
          'lacking-weight': 0,
          'undelivered-after-scheduled': 0
        };
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = orderProblems[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var problem = _step.value;

            orderProblemCountMap[problem.type] = problem.count;
            orderProblemCount += problem.count;
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }

        var memberProblemCount = 0;
        var memberProblemCountMap = {
          'bad-address': 0
        };
        var _iteratorNormalCompletion2 = true;
        var _didIteratorError2 = false;
        var _iteratorError2 = undefined;

        try {
          for (var _iterator2 = memberProblems[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
            var _problem = _step2.value;

            memberProblemCountMap[_problem.type] = _problem.count;
            memberProblemCount += _problem.count;
          }
        } catch (err) {
          _didIteratorError2 = true;
          _iteratorError2 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion2 && _iterator2.return) {
              _iterator2.return();
            }
          } finally {
            if (_didIteratorError2) {
              throw _iteratorError2;
            }
          }
        }

        var probsFailedTrans = orderProblemCountMap['non-cancelled-failed-transactions'];
        var statClass = probsFailedTrans >= 5 ? 'statcard-danger' : probsFailedTrans >= 3 ? 'statcard-warning' : 'statcard-light border rounded';
        $('#problem-failed-trans').addClass(statClass).find('.statcard-number').text(probsFailedTrans);

        var probsLackingWeights = orderProblemCountMap['lacking-weight'];
        statClass = probsLackingWeights >= 5 ? 'statcard-danger' : probsLackingWeights >= 3 ? 'statcard-warning' : 'statcard-light border rounded';
        $('#problem-lacking-weight').addClass(statClass).find('.statcard-number').text(probsLackingWeights);

        var probsUnpaidAddons = orderProblemCountMap['unpaid-addons'];
        statClass = probsUnpaidAddons >= 5 ? 'statcard-danger' : probsUnpaidAddons >= 3 ? 'statcard-warning' : 'statcard-light border rounded';
        $('#problem-unpaid-addons').addClass(statClass).find('.statcard-number').text(probsUnpaidAddons);

        var probsUndelivered = orderProblemCountMap['undelivered-after-scheduled'];
        statClass = probsUndelivered >= 5 ? 'statcard-danger' : probsUndelivered >= 3 ? 'statcard-warning' : 'statcard-light border rounded';
        $('#problem-undelivered').addClass(statClass).find('.statcard-number').text(probsUndelivered);

        // End Order Problems

        // Member Problems
        var probsBadAddress = memberProblemCountMap['bad-address'];
        statClass = probsBadAddress >= 5 ? 'statcard-danger' : probsBadAddress >= 3 ? 'statcard-warning' : 'statcard-light border rounded';
        $('#problem-bad-address').addClass(statClass).find('.statcard-number').text(probsBadAddress);

        // End Member Problems

        $('#problems-total-count').html('<h1 class="display-1">' + (orderProblemCount + memberProblemCount) + '</h1>');
      }).catch(function (err) {
        console.log(err);
      });
    }
  });
});