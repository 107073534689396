define('walden-service/components/orders/packed-items', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  // const $ = Ember.$;
  var weight_modal = '#enterWeightModal';
  exports.default = Ember.Component.extend({
    activeItem: null,
    activeItemWeight: null,
    didInsertElement: function didInsertElement() {
      $(weight_modal).modal({ show: false });
    },
    willDestroyElement: function willDestroyElement() {
      $(weight_modal).modal('dispose');
    },

    actions: {
      showWeightModal: function showWeightModal(item) {
        this.set('activeItemWeight', null);
        this.set('activeItem', item);
        $(weight_modal).modal('show');
      },
      setItemWeight: function setItemWeight() {
        var item = this.get('activeItem');
        var item_weight = this.get('activeItemWeight');

        var shouldSet = true;
        if (parseInt(item_weight) > 20) {
          shouldSet = confirm('Are you sure that this item is over 20lbs? (' + item_weight + ')');
        }
        if (shouldSet) {
          if (item && item_weight) {
            this.sendAction('setItemWeight', item, item_weight);
            $(weight_modal).modal('hide');
          }
        }
      }
    }
  });
});