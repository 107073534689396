define('walden-service/components/shareconfig/monthly-addons', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = Ember.Component.extend({
    /**
     * In shareconfig we only want to show recurring addons
     *
     * @returns {Object} Filtered recurring addons
     */
    recurringAddons: computed(function () {
      return this.get('addons').filterBy('recurring', true);
    }),
    /**
     * If we have a shareconfig, set addon model instances accordingly
     *
     */
    didInsertElement: function didInsertElement() {
      if (this.get('shareConf')) {
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = this.get('shareConf').get('share_addons').toArray()[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var share_addon = _step.value;

            var addon = this.get('addons').findBy('id', share_addon.get('id'));
            if (addon) {
              var quantity = addon.get('share_addon').get('quantity');
              var price = share_addon.get('price') * quantity / 100;
              addon.set('quantity', quantity);
              addon.set('perMonth', price);
            }
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }
      }
    },
    actions: {
      /**
       * Update the display value and reference model for an addon
       *
       * @param {event} event change event
       */
      setAddonValue: function setAddonValue(event) {
        var addon = this.get('addons').findBy('id', event.target.id);
        addon.set('perMonth', Math.floor(event.target.value * addon.get('price') / 100));
        addon.set('quantity', event.target.value);
      }
    }
  });
});