define('walden-service/routes/events/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var RSVP = Ember.RSVP;
  exports.default = Ember.Route.extend({
    model: function model(params) {
      return RSVP.hash({
        scheduled_dispatch: this.get('store').query('scheduled-event', { type: 'scheduled-status' })
      });
    }
  });
});