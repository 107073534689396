define('walden-service/util/walden-cut', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  var WaldenCut = function () {
    function WaldenCut(id, type, name, active, position, restrictions, contents, parent) {
      _classCallCheck(this, WaldenCut);

      this.id = id;
      this.type = type;
      this.name = name;
      this.active = active;
      this.parent = parent;
      this.position = position;
      this.restrictions = restrictions;
      this.contents = contents;

      this.types = ['base', 'middle', 'end'];

      this._render();
    }

    _createClass(WaldenCut, [{
      key: 'save',
      value: function save() {}
    }, {
      key: '_render',
      value: function _render() {
        var cutItem = $('<tr>');
        var cutCell = $('<td>');

        cutItem.addClass('configure-cut-item');
        if (!this.active) cutItem.addClass('cut-inactive');
        cutItem.attr('id', this.id);

        var nameCol = cutCell.clone();
        nameCol.html(this.name);
        cutItem.append(nameCol);

        var typeCol = cutCell.clone();
        typeCol.html(this.types[this.type - 1]);
        cutItem.append(typeCol);

        var contentCol = cutCell.clone();
        contentCol.html(this.contents.map(function (elem) {
          return elem.name;
        }).join(","));
        cutItem.append(contentCol);

        var editCol = cutCell.clone();
        editCol.addClass('text-right');
        editCol.html('<div class="btn btn-info edit-configure-cuts"><span class="icon icon-edit"></span></div>');
        cutItem.append(editCol);

        $(this.parent).append(cutItem);
      }
    }]);

    return WaldenCut;
  }();

  exports.default = WaldenCut;
});