define('walden-service/routes/problems', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    /**
     * Problems model. Gets orders and member accounts
     * that are in a problem state.
     */
    model: function model() {
      return Ember.RSVP.hash({
        orders: this.store.findAll('problem-order'),
        members: this.store.findAll('problem-member')
      });
    },

    /**
     * Hook that wipes the current query params from the
     * problems/ route after leaving the page.
     * @param {object} controller - Problems controller
     */
    resetController: function resetController(controller) {
      var queryParams = controller.get('queryParams');
      queryParams.forEach(function (param) {
        controller.set(param, null);
      });
    }
  });
});