define('walden-service/adapters/zip', ['exports', 'walden-service/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    namespace: 'service',
    aj: Ember.inject.service('ajax'),
    findAll: function findAll() {
      return this.get('aj').request('/service/zips?count=9999', {
        method: 'GET'
      }).then(function (response) {
        return response;
      });
    },
    query: function query(store, type, _query) {
      var url = '/service/zips?count=9999';
      if (_query.active) {
        url += '&active=' + _query.active;
      }
      return this.get('aj').request(url, {
        method: 'GET'
      }).then(function (response) {
        return response;
      });
    }
  });
});