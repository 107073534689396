define('walden-service/controllers/pilots/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var alias = Ember.computed.alias;
  exports.default = Ember.Controller.extend({
    pilots: alias('model'),
    /**
     * View a pilot upon clicking it's pilot-card in the template.
     *
     * @method action:viewPilot
     * @returns {null}
     * @memberof Controllers/Pilots/Index
     */
    actions: {
      viewPilot: function viewPilot(pilot) {
        this.transitionToRoute('pilots.pilot', pilot.get('id'));
      }
    }
  });
});