define('walden-service/models/member', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    active: _emberData.default.attr('boolean'),
    first_name: _emberData.default.attr('string'),
    last_name: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    generation: _emberData.default.attr('number'),
    phone: _emberData.default.attr('string'),
    created_at: _emberData.default.attr('string'),
    addresses: _emberData.default.hasMany('address'),
    notes: _emberData.default.attr('string'),
    legacy_id: _emberData.default.attr('string'),
    delivery_window_starting: _emberData.default.attr('number'),
    delivery_window_ending: _emberData.default.attr('number'),
    service_type: _emberData.default.attr('number'),
    sms: _emberData.default.attr('boolean', { defaultValue: false })
  });
});