define('walden-service/components/shareconfig/cut-selection', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = Ember.Component.extend({
    showSubs: false,
    showRoasts: true,
    cutgroupError: false,
    cutGroupErrorMessage: '',
    shareWeight: null,
    /**
     * Filter out base cuts to be shown by default
     *
     * @returns {object} The filtered list of cuts
     */
    baseCutsPrimary: computed(function () {
      var cuts = this._getCutGroupBySection(2);
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = cuts[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var cut = _step.value;

          if (!this.get('shareConf')) {
            cut.set('selected', true);
          }

          if (this.edit) cut.set('selected', false);
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      return cuts;
    }),
    /**
     * Filter out the rest of the base cuts
     *
     * @returns {object} The filtered list of cuts
     */
    baseCutsOther: computed(function () {
      var cuts = this._getCutGroupBySection(0);
      var _iteratorNormalCompletion2 = true;
      var _didIteratorError2 = false;
      var _iteratorError2 = undefined;

      try {
        for (var _iterator2 = cuts[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
          var cut = _step2.value;

          cut.set('selected', false);
        }
      } catch (err) {
        _didIteratorError2 = true;
        _iteratorError2 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion2 && _iterator2.return) {
            _iterator2.return();
          }
        } finally {
          if (_didIteratorError2) {
            throw _iteratorError2;
          }
        }
      }

      return cuts;
    }),
    /**
     * Filter out middle cuts
     *
     * @returns {object} The filtered list of cuts
     */
    middleCategories: computed(function () {
      return this._getCutListingsByType(3);
    }),
    /**
     * Filter out end cuts
     *
     * @returns {object} The filtered list of cuts
     */
    endCategories: computed(function () {
      return this._getCutListingsByType(2);
    }),
    /**
     * Set roast and chicken visibility based on share size
     *
     */
    showRoastsCheck: function () {
      //if (this.get('shareWeight') >= 10) {
      this.set('showRoasts', true);
      //} else {
      //  this.set('showRoasts', false);
      //  const roast = this.get('baseCuts').findBy('id', '122');
      //  roast.set('selected', false);
      //  const chicken = this.get('baseCuts').findBy('id', '123');
      //  chicken.set('selecter', false);
      //  this._checkGroupValidity();
      //}
    }.observes('shareWeight'),
    /**
     * If we have a shareconfig, set model instances appropriately
     *
     */
    didInsertElement: function didInsertElement() {
      if (this.edit) {
        // Setup Base Cuts
        var _iteratorNormalCompletion3 = true;
        var _didIteratorError3 = false;
        var _iteratorError3 = undefined;

        try {
          for (var _iterator3 = this.get('baseCuts').toArray()[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
            var baseCut = _step3.value;

            // All existing base cuts are inclusions
            var match = this.get('shareConf').get('cut_group_inclusions').findBy('id', baseCut.get('id'));

            if (match) {
              match.set('selected', true);
            }
          }

          // Setup Middle and End Cuts
        } catch (err) {
          _didIteratorError3 = true;
          _iteratorError3 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion3 && _iterator3.return) {
              _iterator3.return();
            }
          } finally {
            if (_didIteratorError3) {
              throw _iteratorError3;
            }
          }
        }

        var _iteratorNormalCompletion4 = true;
        var _didIteratorError4 = false;
        var _iteratorError4 = undefined;

        try {
          for (var _iterator4 = this.get('cutListings').toArray()[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
            var listing = _step4.value;
            var _iteratorNormalCompletion5 = true;
            var _didIteratorError5 = false;
            var _iteratorError5 = undefined;

            try {
              for (var _iterator5 = listing.get('cutgroups').toArray()[Symbol.iterator](), _step5; !(_iteratorNormalCompletion5 = (_step5 = _iterator5.next()).done); _iteratorNormalCompletion5 = true) {
                var group = _step5.value;

                var exclusion = this.get('shareConf').get('cut_group_exclusions').findBy('id', group.get('id'));

                if (exclusion) {
                  group.set('selected', false);
                }

                var inclusion = this.get('shareConf').get('cut_group_inclusions').findBy('id', group.get('id'));

                if (inclusion) {
                  group.set('selected', true);
                }
              }
            } catch (err) {
              _didIteratorError5 = true;
              _iteratorError5 = err;
            } finally {
              try {
                if (!_iteratorNormalCompletion5 && _iterator5.return) {
                  _iterator5.return();
                }
              } finally {
                if (_didIteratorError5) {
                  throw _iteratorError5;
                }
              }
            }
          }
        } catch (err) {
          _didIteratorError4 = true;
          _iteratorError4 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion4 && _iterator4.return) {
              _iterator4.return();
            }
          } finally {
            if (_didIteratorError4) {
              throw _iteratorError4;
            }
          }
        }
      }
    },
    actions: {
      /**
       * Show or hide cuts groups in a given category
       *
       * @param {number} cat Category id
       */
      toggleCategory: function toggleCategory(cat) {
        var category = this.get('cutListings').findBy('id', cat);
        category.set('selected', !category.get('selected'));
      },
      /**
       * Select or deselect a cut group
       *
       * @param {number} cat Category id of the cut group
       * @param {number} grp Cut group id
       */
      toggleGroup: function toggleGroup(cat, grp) {
        var category = this.get('cutListings').findBy('id', cat);
        var group = category.get('cutgroups').findBy('id', grp);
        group.set('selected', !group.get('selected'));
        this._checkGroupValidity();
      },
      /**
       * Show or hide base substitutions
       *
       */
      toggleSubs: function toggleSubs() {
        this.toggleProperty('showSubs');
      },
      /**
       * Select or deselect a base cut
       *
       * @param {number} id Base cut group id
       */
      toggleBase: function toggleBase(id) {
        var group = this.get('baseCuts').findBy('id', id);
        group.set('selected', !group.get('selected'));
        this._checkGroupValidity();
      }
    },
    /**
     * Check the validity of the current cut group configuration
     *
     */
    _checkGroupValidity: function _checkGroupValidity() {
      var baseCuts = this.get('baseCuts');
      var middleCategories = this.get('middleCategories');
      var endCategories = this.get('endCategories');

      var selectedBaseCuts = 0;
      var selectedMiddleCuts = 0;
      var selectedEndCuts = 0;

      var _iteratorNormalCompletion6 = true;
      var _didIteratorError6 = false;
      var _iteratorError6 = undefined;

      try {
        for (var _iterator6 = baseCuts.toArray()[Symbol.iterator](), _step6; !(_iteratorNormalCompletion6 = (_step6 = _iterator6.next()).done); _iteratorNormalCompletion6 = true) {
          var bc = _step6.value;

          if (bc.get('selected') === true) {
            selectedBaseCuts++;
          }
        }
      } catch (err) {
        _didIteratorError6 = true;
        _iteratorError6 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion6 && _iterator6.return) {
            _iterator6.return();
          }
        } finally {
          if (_didIteratorError6) {
            throw _iteratorError6;
          }
        }
      }

      var _iteratorNormalCompletion7 = true;
      var _didIteratorError7 = false;
      var _iteratorError7 = undefined;

      try {
        for (var _iterator7 = middleCategories[Symbol.iterator](), _step7; !(_iteratorNormalCompletion7 = (_step7 = _iterator7.next()).done); _iteratorNormalCompletion7 = true) {
          var middleCat = _step7.value;
          var _iteratorNormalCompletion9 = true;
          var _didIteratorError9 = false;
          var _iteratorError9 = undefined;

          try {
            for (var _iterator9 = middleCat.get('cutgroups').toArray()[Symbol.iterator](), _step9; !(_iteratorNormalCompletion9 = (_step9 = _iterator9.next()).done); _iteratorNormalCompletion9 = true) {
              var cutGroup = _step9.value;

              if (cutGroup.get('selected')) {
                selectedMiddleCuts++;
              }
            }
          } catch (err) {
            _didIteratorError9 = true;
            _iteratorError9 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion9 && _iterator9.return) {
                _iterator9.return();
              }
            } finally {
              if (_didIteratorError9) {
                throw _iteratorError9;
              }
            }
          }
        }
      } catch (err) {
        _didIteratorError7 = true;
        _iteratorError7 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion7 && _iterator7.return) {
            _iterator7.return();
          }
        } finally {
          if (_didIteratorError7) {
            throw _iteratorError7;
          }
        }
      }

      var _iteratorNormalCompletion8 = true;
      var _didIteratorError8 = false;
      var _iteratorError8 = undefined;

      try {
        for (var _iterator8 = endCategories[Symbol.iterator](), _step8; !(_iteratorNormalCompletion8 = (_step8 = _iterator8.next()).done); _iteratorNormalCompletion8 = true) {
          var endCat = _step8.value;
          var _iteratorNormalCompletion10 = true;
          var _didIteratorError10 = false;
          var _iteratorError10 = undefined;

          try {
            for (var _iterator10 = endCat.get('cutgroups').toArray()[Symbol.iterator](), _step10; !(_iteratorNormalCompletion10 = (_step10 = _iterator10.next()).done); _iteratorNormalCompletion10 = true) {
              var _cutGroup = _step10.value;

              if (_cutGroup.get('selected')) {
                selectedEndCuts++;
              }
            }
          } catch (err) {
            _didIteratorError10 = true;
            _iteratorError10 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion10 && _iterator10.return) {
                _iterator10.return();
              }
            } finally {
              if (_didIteratorError10) {
                throw _iteratorError10;
              }
            }
          }
        }
      } catch (err) {
        _didIteratorError8 = true;
        _iteratorError8 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion8 && _iterator8.return) {
            _iterator8.return();
          }
        } finally {
          if (_didIteratorError8) {
            throw _iteratorError8;
          }
        }
      }

      var message = '';
      var hasContent = false;

      if (selectedBaseCuts === 0 || selectedBaseCuts > 2) {
        if (hasContent) message += ', ';else hasContent = true;
        message += 'Please select one or two base cuts';
      }

      if (selectedMiddleCuts < 5) {
        if (hasContent) message += ', ';else hasContent = true;
        message += 'Please select at least five middle cuts';
      }

      if (selectedEndCuts < 5) {
        if (hasContent) message += ', ';else hasContent = true;
        message += 'Please select at least 5 end cuts';
      }

      if (hasContent) {
        this.set('cutGroupError', true);
        this.set('cutGroupErrorMessage', message);
      } else {
        this.set('cutGroupError', false);
      }
    },
    /**
     * Filter cut categories by type
     *
     * @param {number} type Type id
     * @returns {object} The filtered group of categories
     */
    _getCutListingsByType: function _getCutListingsByType(type) {
      return this.get('cutListings').toArray().filter(function (cl) {
        return cl.get('cut_type') === type;
      });
    },
    /**
     * Filter cut groups based on display section
     *
     * @param {number} section Section id
     * @returns {object} The filtered list of cut groups
     */
    _getCutGroupBySection: function _getCutGroupBySection(section) {
      return this.get('baseCuts').toArray().filter(function (cl) {
        return cl.get('display_section') === section;
      });
    }
  });
});