define('walden-service/components/order-search', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    session: Ember.inject.service('session'),
    ajax: Ember.inject.service('ajax'),
    init: function init() {
      this._super();
      var self = this;

      $(function () {
        $(document).on('click', '.service-order', function () {
          var orderId = $(this).parent().parent().find('.order-id').html();
          console.log(orderId);
          self.get('serviceOrder')(orderId);
        });
      });
    },
    actions: {
      searchOrders: function searchOrders(e) {
        var self = this;
        var srcVal = $(e.target).val();
        if (srcVal.length > 0) {
          self.get('ajax').request('orders?member=' + srcVal + ':LIKE:OR&id=' + srcVal + ':LIKE:OR', {
            headers: {
              'token': self.get('session').session.content.authenticated.token
            }
          }).then(function (response) {
            console.log(response);
            $('.remove').hide();
            var orders = response.orders;
            var resultContainer = $('.order-result-container');

            resultContainer.html('');

            orders.every(function (order) {
              var result = $('#template .order-result').clone();

              result.find('.order-id').html(order.id);
              result.find('.order-status').html('Order #' + order.id);
              result.find('.order-created').html(order.created_at);
              result.find('.order-updated').html(order.updated_at);
              result.find('.member-name').html(order.member_details.first_name + ' ' + order.member_details.last_name);
              result.find('.member-email').html(order.member_details.email);

              resultContainer.append(result);
              return true;
            });

            return true;
          });
        }
      },
      serviceOrder: function serviceOrder(e) {
        console.log(e);
      }
    }
  });
});