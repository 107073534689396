define('walden-service/models/service-partition', ['exports', 'ember-data', 'moment'], function (exports, _emberData, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    month: _emberData.default.attr('number'),
    year: _emberData.default.attr('number'),
    description: _emberData.default.attr('string'),
    human_date: Ember.computed('month', 'year', function () {
      return (0, _moment.default)().month(this.get('month')).format('MMMM') + ' ' + this.get('year');
    }),
    active_starting: _emberData.default.attr('string'),
    active_ending: _emberData.default.attr('string')
  });
});