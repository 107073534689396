define('walden-service/components/search-result', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    currentAddress: null,
    fullAddress: null,
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      var curr = this.get('member.addresses').toArray().sort(function (a, b) {
        return parseInt(b.id, 10) - parseInt(a.id, 10);
      }).objectAt(0);
      this.set('currentAddress', curr);
      var full = void 0;
      if (curr) {
        full = curr.address1 + (curr.address2 ? ', ' + curr.address2 : '') + ', ' + curr.city + ', ' + curr.zip_code.service_state.code + ' ' + curr.zip_code.code;
      }
      this.set('fullAddress', full);
    }
  });
});