define('walden-service/routes/specials', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return Ember.RSVP.hash({
        addons: this.store.findAll('addon'),
        addonGroups: this.store.findAll('addon-group'),
        partitions: this.store.findAll('service-partition')
      });
    },

    setupController: function setupController(controller, model) {
      var monthCounter = 0;
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = model.partitions.toArray()[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var part = _step.value;

          if (monthCounter === 0) {
            controller.set('thisMonth', part.get('id'));
            console.log('this', part.get('id'));
          } else if (monthCounter === 1) {
            controller.set('nextMonth', part.get('id'));
            console.log('next', part.get('id'));
          }
          monthCounter++;
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      controller.set('model', model);
    }
  });
});