define('walden-service/helpers/spinners', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.spinners = spinners;
  function spinners() {
    return {
      clock: ['🕐', '🕑', '🕒', '🕓', '🕔', '🕕', '🕖', '🕗', '🕘', '🕙', '🕚', '🕛']
    };
  }
  exports.default = Ember.Helper.helper(spinners);
});