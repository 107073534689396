define('walden-service/serializers/pilot', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var underscore = Ember.String.underscore;
  exports.default = _emberData.default.RESTSerializer.extend({
    /**
     * Underscore all object keys in the outgoing payload.
     *
     * @override
     * @method keyForAttribute
     * @memberof Serializers/Pilot
     */
    keyForAttribute: function keyForAttribute(key) {
      return underscore(key);
    },

    /**
     * Remove the 'wufoo_config' property on the serialized payload
     * before sending the pilot to server. This is done to prevent inadvertently
     * updating the pilot's wufoo configuration when updating a different property,
     * as there is currently no interface to allow users to change a pilot's wufoo config.
     */
    serialize: function serialize() {
      var payload = this._super.apply(this, arguments);
      delete payload.wufoo_config;
      return payload;
    }
  });
});