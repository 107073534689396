define('walden-service/models/service-day', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _emberData.default.Model.extend({
    partition: _emberData.default.attr('number'),
    calendar_date: _emberData.default.attr('string'),
    makeup: _emberData.default.attr('boolean'),
    packed: _emberData.default.attr('boolean'),
    dispatched: _emberData.default.attr('boolean'),
    locked: _emberData.default.attr('boolean'),
    active: _emberData.default.attr('boolean'),
    day_index: _emberData.default.attr('number'),
    region_lock: _emberData.default.attr('number'),
    color: _emberData.default.attr('string', { defaultValue: null }),
    open_regions: computed('region_lock', function () {
      return {
        'MA North': (2 & this.get('region_lock')) > 0,
        'MA South': (1 & this.get('region_lock')) > 0,
        'CT East': (4 & this.get('region_lock')) > 0,
        'CT West': (8 & this.get('region_lock')) > 0
      };
    })
  });
});