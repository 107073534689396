define('walden-service/components/utility/error-display', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    error: 0,
    errorType: null,
    errorMessage: null,
    parseError: function () {
      var _this = this;
      this.set('errorMessage', null);
      try {
        var error = this.get('error');
        var message = null;
        if (!error.message && !error.payload) throw 'unknown error';
        if (this.get('error').payload) {
          if (this.get('error').payload.errors && this.get('error').payload.errors.length) {
            this.set('errorMessage', this.get('error').payload.errors.join(', '));
          }
        }
        var code = this.get('error.code');
        if (this.get('errorMessage') === null && this.get('error.message')) {
          message = this.get('error.message');
        }
        var statusCode = this.get('error.statusCode');

        this.set('errorType', code);
        if (message) {
          this.set('errorMessage', message);
        }
      } catch (err) {
        console.log('Caught: ', err);
        _this.set('errorType', 'Unknown Error');
        _this.set('errorMessage', 'Unable to reach the server');
      }
    }.observes('error')
  });
});