define('walden-service/serializers/application', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTSerializer.extend({
    keyForAttribute: function keyForAttribute(key) {
      return Ember.String.decamelize(key);
    }
  });
});