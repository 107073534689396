define('walden-service/components/main-navigation', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    session: Ember.inject.service('session'),
    actions: {
      invalidateSession: function invalidateSession() {
        this.sendAction('invalidateSession');
        // this.get('session').invalidate();
        // window.location.href = "/";
      }
    }
  });
});