define('walden-service/helpers/loose-equals', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.looseEquals = looseEquals;
  function looseEquals(params /*, hash*/) {
    return params[0] == params[1];
  }

  exports.default = Ember.Helper.helper(looseEquals);
});