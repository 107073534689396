define('walden-service/components/pilots/member-id-list', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    /**
     * The list of member IDs to display.
     *
     * @argument
     * @type {Array.<String>}
     * @default []
     * @memberof Components/Pilots/Member-Id-List
     */
    ids: [],
    /**
     * Whether or not to display a header with buttons to clear or
     * act on the displayed list of member Ids.
     *
     * @argument
     * @type {boolean}
     * @default false
     * @memberof Components/Pilots/Member-Id-List
     */
    header: false,
    /**
     * The button text for the submit button, The submit button invokes a closure
     * action using the current list of member IDs as the function's argument.
     *
     * @argument
     * @type {string}
     * @default null
     * @memberof Components/Pilots/Member-Id-List
     */
    submitLabel: null,
    /**
     * Whether or not to disable the submit button.
     *
     * @arugment
     * @type {boolean}
     * @default false
     * @memberof Components/Pilots/Member-Id-List
     */
    disabled: false,
    /**
     * Whether or not to show a loading spinner on the submit button.
     *
     * @argument
     * @type {boolean}
     * @default false
     * @memberof Components/Pilots/Member-Id-List
     */
    loading: false,
    actions: {
      /**
       * Clear out the current list of member Ids.
       *
       * @method action:clearMemberIds
       * @returns {null}
       * @memberof Components/Pilots/Member-Id-List
       */
      clearMemberIds: function clearMemberIds() {
        this.set('ids', []);
      }
    }
  });
});