define('walden-service/routes/members', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    session: Ember.inject.service('session'),
    actions: {
      searchMembers: function searchMembers(e) {
        console.log($(e.target).val());
      },
      didTransition: function didTransition() {
        $('body').css('border-top', '5px solid #2980b9');
      }
    },
    didInsertElement: function didInsertElement() {}
  });
});