define('walden-service/routes/routing', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var RSVP = Ember.RSVP;
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    activePartitionId: null,
    model: function model() {
      return RSVP.hash({
        partitions: this.get('store').findAll('service-partition'),
        // Changed from findAll to query, otherwise transitioning from the pilots/pilot route to
        // the /routing route would cause only the zips pulled in the pilot page to show on the map.
        zips: this.get('store').query('zip', { count: 9999 })
      });
    },
    setupController: function setupController(controller, model) {
      var filteredZips = [];
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = model.zips.toArray()[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var zip = _step.value;

          if (zip.get('soon') || zip.get('active')) {
            filteredZips.pushObject(zip);
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      model.zips = filteredZips;
      controller.set('model', model);
      var firstPartition = controller.get('model.partitions.firstObject.id');
      controller.set('activePartition', firstPartition);
      this._super.apply(this, arguments);
    }
  });
});