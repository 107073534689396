define('walden-service/adapters/addon', ['exports', 'walden-service/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var makeArray = Ember.makeArray;
  exports.default = _application.default.extend({
    url: '/cut-listings',
    namespace: 'shareconfig',
    // pathForType: '/cut-listings',
    eajax: Ember.inject.service('ajax'),
    findAll: function findAll(modelName, query) {
      return this.get('eajax').request('/shareconfig/addons?count=9999', {
        method: 'GET'
      }).then(function (response) {
        return response;
      });
    }
    // query: function(modelName, query) {
    //   console.log('query-----------------', query;
    //   return this.get('ajax')
    //     .request('/cut-listings?count=9999', {
    //       method: 'GET'
    //     })
    //     .then((response) => {
    //       return response;
    //     });
    // }
  });
});