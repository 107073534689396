define('walden-service/adapters/credit', ['exports', 'walden-service/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    ajaxService: Ember.inject.service('ajax'),
    findRecord: function findRecord(store, type, id, snapshot) {
      return this.get('ajaxService').request('/members/' + id + '/credit', {
        method: 'GET'
      }).then(function (response) {
        return response;
      });
    }
  });
});