define('walden-service/serializers/availability-range', ['exports', 'walden-service/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    serializeIntoHash: function serializeIntoHash(hash, type, record, options) {
      console.log(' hash going in: ' + JSON.stringify(hash));

      hash['addon_availability'] = this.serialize(record, options); // <---- THIS LINE

      console.log('hash going out: ' + JSON.stringify(hash));

      return hash;
    }
  });
});