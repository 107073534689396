define('walden-service/models/transaction', ['exports', 'ember-data', 'moment'], function (exports, _emberData, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    amount: _emberData.default.attr('number'),
    created_at: _emberData.default.attr('string'),
    updated_at: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    stripe_id: _emberData.default.attr('string'),
    type: _emberData.default.attr('string'),
    hidden: _emberData.default.attr('boolean'),
    human_date: Ember.computed('created_at', function () {
      return (0, _moment.default)(this.get('created_at')).calendar();
    }),
    human_updated: Ember.computed('updated_at', function () {
      return (0, _moment.default)(this.get('updated_at')).calendar();
    }),

    human_amount: Ember.computed('amount', function () {
      return '$' + this.get('amount') / 100;
    }),
    pretty_description: Ember.computed('description', function () {
      var desc = this.get('description').split('\n');
      var pretty = '';
      var i = 0;
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = desc[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var curdesc = _step.value;

          pretty += '<div class="mt-2">' + (i === 0 ? '<strong>' : '') + desc[i] + (i === 0 ? '</strong>' : '') + '</div>';
          i++;
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      return pretty;
    })
  });
});