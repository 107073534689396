define('walden-service/components/walden-routing2', ['exports', 'moment'], function (exports, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        session: Ember.inject.service('session'),
        ajax: Ember.inject.service('ajax'),
        map: {
            map: null,
            activeOrders: {},
            centroids: {},
            routeCentroids: {},
            mock: {},
            colorMap: {}
        },
        colors: [{
            "hex": "#EFDECD",
            "name": "Almond",
            "rgb": "(239, 222, 205)"
        }, {
            "hex": "#CD9575",
            "name": "Antique Brass",
            "rgb": "(205, 149, 117)"
        }, {
            "hex": "#FDD9B5",
            "name": "Apricot",
            "rgb": "(253, 217, 181)"
        }, {
            "hex": "#78DBE2",
            "name": "Aquamarine",
            "rgb": "(120, 219, 226)"
        }, {
            "hex": "#87A96B",
            "name": "Asparagus",
            "rgb": "(135, 169, 107)"
        }, {
            "hex": "#FFA474",
            "name": "Atomic Tangerine",
            "rgb": "(255, 164, 116)"
        }, {
            "hex": "#FAE7B5",
            "name": "Banana Mania",
            "rgb": "(250, 231, 181)"
        }, {
            "hex": "#9F8170",
            "name": "Beaver",
            "rgb": "(159, 129, 112)"
        }, {
            "hex": "#FD7C6E",
            "name": "Bittersweet",
            "rgb": "(253, 124, 110)"
        }, {
            "hex": "#000000",
            "name": "Black",
            "rgb": "(0,0,0)"
        }, {
            "hex": "#ACE5EE",
            "name": "Blizzard Blue",
            "rgb": "(172, 229, 238)"
        }, {
            "hex": "#1F75FE",
            "name": "Blue",
            "rgb": "(31, 117, 254)"
        }, {
            "hex": "#A2A2D0",
            "name": "Blue Bell",
            "rgb": "(162, 162, 208)"
        }, {
            "hex": "#6699CC",
            "name": "Blue Gray",
            "rgb": "(102, 153, 204)"
        }, {
            "hex": "#0D98BA",
            "name": "Blue Green",
            "rgb": "(13, 152, 186)"
        }, {
            "hex": "#7366BD",
            "name": "Blue Violet",
            "rgb": "(115, 102, 189)"
        }, {
            "hex": "#DE5D83",
            "name": "Blush",
            "rgb": "(222, 93, 131)"
        }, {
            "hex": "#CB4154",
            "name": "Brick Red",
            "rgb": "(203, 65, 84)"
        }, {
            "hex": "#B4674D",
            "name": "Brown",
            "rgb": "(180, 103, 77)"
        }, {
            "hex": "#FF7F49",
            "name": "Burnt Orange",
            "rgb": "(255, 127, 73)"
        }, {
            "hex": "#EA7E5D",
            "name": "Burnt Sienna",
            "rgb": "(234, 126, 93)"
        }, {
            "hex": "#B0B7C6",
            "name": "Cadet Blue",
            "rgb": "(176, 183, 198)"
        }, {
            "hex": "#FFFF99",
            "name": "Canary",
            "rgb": "(255, 255, 153)"
        }, {
            "hex": "#1CD3A2",
            "name": "Caribbean Green",
            "rgb": "(28, 211, 162)"
        }, {
            "hex": "#FFAACC",
            "name": "Carnation Pink",
            "rgb": "(255, 170, 204)"
        }, {
            "hex": "#DD4492",
            "name": "Cerise",
            "rgb": "(221, 68, 146)"
        }, {
            "hex": "#1DACD6",
            "name": "Cerulean",
            "rgb": "(29, 172, 214)"
        }, {
            "hex": "#BC5D58",
            "name": "Chestnut",
            "rgb": "(188, 93, 88)"
        }, {
            "hex": "#DD9475",
            "name": "Copper",
            "rgb": "(221, 148, 117)"
        }, {
            "hex": "#9ACEEB",
            "name": "Cornflower",
            "rgb": "(154, 206, 235)"
        }, {
            "hex": "#FFBCD9",
            "name": "Cotton Candy",
            "rgb": "(255, 188, 217)"
        }, {
            "hex": "#FDDB6D",
            "name": "Dandelion",
            "rgb": "(253, 219, 109)"
        }, {
            "hex": "#2B6CC4",
            "name": "Denim",
            "rgb": "(43, 108, 196)"
        }, {
            "hex": "#EFCDB8",
            "name": "Desert Sand",
            "rgb": "(239, 205, 184)"
        }, {
            "hex": "#6E5160",
            "name": "Eggplant",
            "rgb": "(110, 81, 96)"
        }, {
            "hex": "#CEFF1D",
            "name": "Electric Lime",
            "rgb": "(206, 255, 29)"
        }, {
            "hex": "#71BC78",
            "name": "Fern",
            "rgb": "(113, 188, 120)"
        }, {
            "hex": "#6DAE81",
            "name": "Forest Green",
            "rgb": "(109, 174, 129)"
        }, {
            "hex": "#C364C5",
            "name": "Fuchsia",
            "rgb": "(195, 100, 197)"
        }, {
            "hex": "#CC6666",
            "name": "Fuzzy Wuzzy",
            "rgb": "(204, 102, 102)"
        }, {
            "hex": "#E7C697",
            "name": "Gold",
            "rgb": "(231, 198, 151)"
        }, {
            "hex": "#FCD975",
            "name": "Goldenrod",
            "rgb": "(252, 217, 117)"
        }, {
            "hex": "#A8E4A0",
            "name": "Granny Smith Apple",
            "rgb": "(168, 228, 160)"
        }, {
            "hex": "#95918C",
            "name": "Gray",
            "rgb": "(149, 145, 140)"
        }, {
            "hex": "#1CAC78",
            "name": "Green",
            "rgb": "(28, 172, 120)"
        }, {
            "hex": "#1164B4",
            "name": "Green Blue",
            "rgb": "(17, 100, 180)"
        }, {
            "hex": "#F0E891",
            "name": "Green Yellow",
            "rgb": "(240, 232, 145)"
        }, {
            "hex": "#FF1DCE",
            "name": "Hot Magenta",
            "rgb": "(255, 29, 206)"
        }, {
            "hex": "#B2EC5D",
            "name": "Inchworm",
            "rgb": "(178, 236, 93)"
        }, {
            "hex": "#5D76CB",
            "name": "Indigo",
            "rgb": "(93, 118, 203)"
        }, {
            "hex": "#CA3767",
            "name": "Jazzberry Jam",
            "rgb": "(202, 55, 103)"
        }, {
            "hex": "#3BB08F",
            "name": "Jungle Green",
            "rgb": "(59, 176, 143)"
        }, {
            "hex": "#FEFE22",
            "name": "Laser Lemon",
            "rgb": "(254, 254, 34)"
        }, {
            "hex": "#FCB4D5",
            "name": "Lavender",
            "rgb": "(252, 180, 213)"
        }, {
            "hex": "#FFF44F",
            "name": "Lemon Yellow",
            "rgb": "(255, 244, 79)"
        }, {
            "hex": "#FFBD88",
            "name": "Macaroni and Cheese",
            "rgb": "(255, 189, 136)"
        }, {
            "hex": "#F664AF",
            "name": "Magenta",
            "rgb": "(246, 100, 175)"
        }, {
            "hex": "#AAF0D1",
            "name": "Magic Mint",
            "rgb": "(170, 240, 209)"
        }, {
            "hex": "#CD4A4C",
            "name": "Mahogany",
            "rgb": "(205, 74, 76)"
        }, {
            "hex": "#EDD19C",
            "name": "Maize",
            "rgb": "(237, 209, 156)"
        }, {
            "hex": "#979AAA",
            "name": "Manatee",
            "rgb": "(151, 154, 170)"
        }, {
            "hex": "#FF8243",
            "name": "Mango Tango",
            "rgb": "(255, 130, 67)"
        }, {
            "hex": "#C8385A",
            "name": "Maroon",
            "rgb": "(200, 56, 90)"
        }, {
            "hex": "#EF98AA",
            "name": "Mauvelous",
            "rgb": "(239, 152, 170)"
        }, {
            "hex": "#FDBCB4",
            "name": "Melon",
            "rgb": "(253, 188, 180)"
        }, {
            "hex": "#1A4876",
            "name": "Midnight Blue",
            "rgb": "(26, 72, 118)"
        }, {
            "hex": "#30BA8F",
            "name": "Mountain Meadow",
            "rgb": "(48, 186, 143)"
        }, {
            "hex": "#C54B8C",
            "name": "Mulberry",
            "rgb": "(197, 75, 140)"
        }, {
            "hex": "#1974D2",
            "name": "Navy Blue",
            "rgb": "(25, 116, 210)"
        }, {
            "hex": "#FFA343",
            "name": "Neon Carrot",
            "rgb": "(255, 163, 67)"
        }, {
            "hex": "#BAB86C",
            "name": "Olive Green",
            "rgb": "(186, 184, 108)"
        }, {
            "hex": "#FF7538",
            "name": "Orange",
            "rgb": "(255, 117, 56)"
        }, {
            "hex": "#FF2B2B",
            "name": "Orange Red",
            "rgb": "(255, 43, 43)"
        }, {
            "hex": "#F8D568",
            "name": "Orange Yellow",
            "rgb": "(248, 213, 104)"
        }, {
            "hex": "#E6A8D7",
            "name": "Orchid",
            "rgb": "(230, 168, 215)"
        }, {
            "hex": "#414A4C",
            "name": "Outer Space",
            "rgb": "(65, 74, 76)"
        }, {
            "hex": "#FF6E4A",
            "name": "Outrageous Orange",
            "rgb": "(255, 110, 74)"
        }, {
            "hex": "#1CA9C9",
            "name": "Pacific Blue",
            "rgb": "(28, 169, 201)"
        }],
        didInsertElement: function didInsertElement() {

            $.getScript('http://cdn.jsdelivr.net/bootstrap.daterangepicker/2/daterangepicker.js', function () {});
            $.getScript('http://ajax.googleapis.com/ajax/libs/jqueryui/1.11.0/jquery-ui.min.js', function () {
                $.getScript('/jquery.rangecalendar.js', function () {

                    $('#rangecal').rangeCalendar();
                });
            });

            var self = this;
            google.maps.event.addDomListener(window, 'load', initMap);

            function initMap() {
                var mapElement = document.getElementById('map');
                var mapOptions = {
                    zoom: 8,
                    center: new google.maps.LatLng(42.561805, -71.2689) // Billerica
                };

                self.map.map = new google.maps.Map(mapElement, mapOptions);
            }

            // Click Listeners

            $(document).on('click', '#view-orders', function () {
                if ($(this).hasClass('closed')) {
                    $(this).find('i').removeClass('fa-angle-double-down').addClass('fa-angle-double-up');
                    $(this).removeClass('closed');
                    $('#active-orders').slideToggle(200);
                } else {
                    $(this).find('i').removeClass('fa-angle-double-up').addClass('fa-angle-double-down');
                    $(this).addClass('closed');
                    $('#active-orders').slideToggle(200);
                }
            });

            // setTimeout(function() {
            //   location.reload(true)
            // },'30000');

            // Load Initial Data

            self.send('getServicePartitions');

            setTimeout(function () {
                $('.load-body').fadeOut('1500');
            }, '3000');

            $(document).on('click', '#route-commit', function (e) {
                if (e) e.preventDefault();
                self.send('cluster', $('#partition-select').val());
            });

            $(document).on('change', '#partition-select', function () {
                self.clearMap(true, true, true);
                self.getServicePartitionOrders($('#partition-select').val());
                self.getServiceCentroids($('#partition-select').val());
                self.getRouteCentroids($('#partition-select').val());
            });
        },
        init: function init() {
            this._super();
        },
        getServicePartitionOrders: function getServicePartitionOrders(partition, cb) {
            var self = this;
            // $('.load-body').fadeIn('1500');
            self.get('ajax').request('/service-partitions/' + partition + '/orders', {
                method: 'GET',
                headers: {
                    'token': self.get('session').session.content.authenticated.token
                }
            }).then(function (response) {
                console.log('RESPO');
                console.log(response);
                // self.cluster(partition, false, true)
                var _iteratorNormalCompletion = true;
                var _didIteratorError = false;
                var _iteratorError = undefined;

                try {
                    for (var _iterator = response.orders[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                        var order = _step.value;

                        // console.log('plotting')
                        var coords = order.member_details.addresses[0].location.coordinates;
                        var marker = new google.maps.Marker({
                            position: { lat: coords[0], lng: coords[1] },
                            map: self.map.map,
                            title: order.member_details.email,
                            icon: {
                                path: google.maps.SymbolPath.CIRCLE,
                                fillColor: 'gray',
                                fillOpacity: 1,
                                strokeColor: '#000',
                                scale: 8,
                                strokeWeight: 3
                            }
                        });

                        marker.setValues({ type: "point", id: order.id });

                        marker.addListener('click', function () {
                            console.log(this.get('id'));
                            self.send('displayOrderInfo', this.get('id'));
                        });

                        var activeOrder = order;
                        activeOrder.marker = marker;
                        self.map.activeOrders[order.id] = activeOrder;
                        // console.log(self.map.activeOrders);
                    }
                } catch (err) {
                    _didIteratorError = true;
                    _iteratorError = err;
                } finally {
                    try {
                        if (!_iteratorNormalCompletion && _iterator.return) {
                            _iterator.return();
                        }
                    } finally {
                        if (_didIteratorError) {
                            throw _iteratorError;
                        }
                    }
                }

                if (cb) self.send(cb);
                // self.send('getServiceCentroids', partition)
            });
        },
        getServiceCentroids: function getServiceCentroids(partition, cb) {
            var self = this;
            self.get('ajax').request('/service-partitions/' + partition + '/service-centroids', {
                method: 'GET',
                headers: {
                    'token': self.get('session').session.content.authenticated.token
                }
            }).then(function (response) {
                console.log(response);
                var _iteratorNormalCompletion2 = true;
                var _didIteratorError2 = false;
                var _iteratorError2 = undefined;

                try {
                    for (var _iterator2 = response.service_centroids[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
                        var sc = _step2.value;

                        var coords = sc.centroid.coordinates;
                        console.log(coords);
                        var marker = new google.maps.Marker({
                            position: { lat: coords[0], lng: coords[1] },
                            map: self.map.map,
                            title: sc.id,
                            icon: {
                                path: google.maps.SymbolPath.BACKWARD_CLOSED_ARROW,
                                strokeColor: 'black',
                                strokeWeight: 3,
                                fillColor: 'white',
                                scale: 10
                            },
                            draggable: true
                        });

                        marker.setValues({ type: "centroid", id: sc.id });

                        marker.addListener('dragend', function (evt) {
                            // console.log(this.get('id'));
                            // console.log(evt.latLng.lat()+', '+evt.latLng.lng())
                            // $('.load-body').show();
                            self.clearMap(false, false, true);
                            self.placeServiceCentroid(this.get('id'), evt.latLng.lat(), evt.latLng.lng());
                            // self.cluster(partition, true);
                            // self.clusterRoutes(partition);
                            // self.getRouteCentroids(partition);
                            // self.send('getRouteCentroids', partition);
                        });

                        var centroid = sc;
                        sc.marker = marker;

                        self.map.centroids[sc.id] = sc;
                    }
                    // self.send('getRouteCentroids', partition)
                } catch (err) {
                    _didIteratorError2 = true;
                    _iteratorError2 = err;
                } finally {
                    try {
                        if (!_iteratorNormalCompletion2 && _iterator2.return) {
                            _iterator2.return();
                        }
                    } finally {
                        if (_didIteratorError2) {
                            throw _iteratorError2;
                        }
                    }
                }

                if (cb) self.send(cb);
            });
        },
        getRouteCentroids: function getRouteCentroids(partition, cb) {
            // console.log('getrtcentroids')
            var self = this;
            self.map.routeCentroids = {};
            self.get('ajax').request('/service-partitions/' + partition + '/route-centroids', {
                method: 'GET',
                headers: {
                    'token': self.get('session').session.content.authenticated.token
                }
            }).then(function (response) {
                // console.log('routecen')
                // console.log(response)
                // $('.load-body').fadeOut('1500');
                var _iteratorNormalCompletion3 = true;
                var _didIteratorError3 = false;
                var _iteratorError3 = undefined;

                try {
                    for (var _iterator3 = response.route_centroids[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
                        var rc = _step3.value;

                        var coords = rc.centroid.coordinates;
                        // console.log(coords)
                        var marker = new google.maps.Marker({
                            position: { lat: coords[0], lng: coords[1] },
                            map: self.map.map,
                            title: rc.id,
                            icon: 'http://www.googlemapsmarkers.com/v1/' + rc.id + '/f7a200/',
                            draggable: true
                        });

                        marker.setValues({ type: "route_centroid", id: rc.id });
                        marker.addListener('dragend', function (evt) {
                            console.log(this.get('id'));
                            console.log(evt.latLng.lat() + ', ' + evt.latLng.lng());
                            self.placeRouteCentroid(this.get('id'), evt.latLng.lat(), evt.latLng.lng());
                            // self.send('cluster', partition, false, 'redraw');
                        });

                        var routeCentroid = rc;
                        routeCentroid.marker = marker;

                        self.map.routeCentroids[rc.id] = routeCentroid;
                    }
                } catch (err) {
                    _didIteratorError3 = true;
                    _iteratorError3 = err;
                } finally {
                    try {
                        if (!_iteratorNormalCompletion3 && _iterator3.return) {
                            _iterator3.return();
                        }
                    } finally {
                        if (_didIteratorError3) {
                            throw _iteratorError3;
                        }
                    }
                }

                if (cb) self.send(cb);
            });
        },
        cluster: function cluster(partition, isservice, cont) {
            console.log('clustering');
            var self = this;
            self.get('ajax').request('/service-partitions/' + partition + '/cluster', {
                method: 'GET',
                headers: {
                    'token': self.get('session').session.content.authenticated.token
                }
            }).then(function (response) {
                if (cont) self.clusterRoutes(partition);
                console.log(response);
                var i = 0;
                var _iteratorNormalCompletion4 = true;
                var _didIteratorError4 = false;
                var _iteratorError4 = undefined;

                try {
                    for (var _iterator4 = response.centroids[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
                        var centroid = _step4.value;

                        console.log(Math.random() * (20 - 1) + 1);
                        console.log(self.colors);
                        var flightPath = new google.maps.Polyline({
                            path: centroid.path,
                            geodesic: true,
                            strokeColor: '#FF0000',
                            strokeOpacity: 1.0,
                            strokeWeight: 2
                        });

                        // flightPath.setMap(self.map.map);

                        var rand = Math.floor(Math.random() * (self.colors.length - 1) + 1);
                        var color = self.colors[i].hex;
                        var _iteratorNormalCompletion5 = true;
                        var _didIteratorError5 = false;
                        var _iteratorError5 = undefined;

                        try {
                            for (var _iterator5 = centroid.points[Symbol.iterator](), _step5; !(_iteratorNormalCompletion5 = (_step5 = _iterator5.next()).done); _iteratorNormalCompletion5 = true) {
                                var point = _step5.value;

                                self.map.activeOrders[point.entity.id].marker.setIcon({
                                    path: google.maps.SymbolPath.CIRCLE,
                                    fillColor: color,
                                    fillOpacity: 1,
                                    strokeColor: 'gray',
                                    scale: 8,
                                    strokeWeight: 3
                                });
                                console.log('POINT');
                                console.log(point);
                                console.log(point.service_centroid);
                                console.log(self.map.centroids);
                                self.map.centroids[point.entity.service_centroid].marker.setIcon({
                                    path: google.maps.SymbolPath.BACKWARD_CLOSED_ARROW,
                                    strokeColor: 'black',
                                    strokeWeight: 3,
                                    fillColor: color,
                                    fillOpacity: 1,
                                    scale: 10
                                });
                            }
                        } catch (err) {
                            _didIteratorError5 = true;
                            _iteratorError5 = err;
                        } finally {
                            try {
                                if (!_iteratorNormalCompletion5 && _iterator5.return) {
                                    _iterator5.return();
                                }
                            } finally {
                                if (_didIteratorError5) {
                                    throw _iteratorError5;
                                }
                            }
                        }

                        i = i + 5;
                    }
                } catch (err) {
                    _didIteratorError4 = true;
                    _iteratorError4 = err;
                } finally {
                    try {
                        if (!_iteratorNormalCompletion4 && _iterator4.return) {
                            _iterator4.return();
                        }
                    } finally {
                        if (_didIteratorError4) {
                            throw _iteratorError4;
                        }
                    }
                }
            });
            // self.send('clearMap', false, false, true);

            if (cb) self.send(cb, partition);
        },
        redraw: function redraw() {
            var self = this;
            self.send('clearMap');
            self.send('getServicePartitionOrders', $('#partition-select').val());
        },
        clusterRoutes: function clusterRoutes(partition) {
            console.log('clusterroutes');
            var self = this;
            self.get('ajax').request('/service-partitions/' + partition + '/populate-route-centroids', {
                method: 'GET',
                headers: {
                    'token': self.get('session').session.content.authenticated.token
                }
            }).then(function (response) {
                self.getRouteCentroids(partition);
            });
        },
        placeServiceCentroid: function placeServiceCentroid(centroid, lat, lng) {
            var self = this;
            self.get('ajax').request('/service-centroids/' + centroid, {
                method: 'PUT',
                headers: {
                    'token': self.get('session').session.content.authenticated.token
                },
                data: {
                    latitude: lat,
                    longitude: lng
                }
            }).then(function (response) {
                console.log(response);
                self.cluster($('#partition-select').val(), true, true);
            });
        },
        placeRouteCentroid: function placeRouteCentroid(centroid, lat, lng) {
            var self = this;
            self.get('ajax').request('/route-centroids/' + centroid, {
                method: 'PUT',
                headers: {
                    'token': self.get('session').session.content.authenticated.token
                },
                data: {
                    latitude: lat,
                    longitude: lng
                }
            }).then(function (response) {
                console.log(response);
            });
        },
        clearMap: function clearMap(orders, service, route) {
            var self = this;

            console.log(self.map);
            if (orders) {
                for (var order in self.map.activeOrders) {
                    self.map.activeOrders[order].marker.setMap(null);
                }
                self.map.activeOrders = {};
            }
            if (service) {
                for (var centroid in self.map.centroids) {
                    self.map.centroids[centroid].marker.setMap(null);
                }
                self.map.centroids = {};
            }
            if (route) {
                for (var _centroid in self.map.routeCentroids) {
                    self.map.routeCentroids[_centroid].marker.setMap(null);
                }
                self.map.routeCentroids = {};
            }
        },
        actions: {
            getServicePartitions: function getServicePartitions() {
                var self = this;
                self.get('ajax').request('service-partitions', {
                    method: 'GET',
                    headers: {
                        'token': self.get('session').session.content.authenticated.token
                    }
                }).then(function (response) {
                    console.log(response);
                    var _iteratorNormalCompletion6 = true;
                    var _didIteratorError6 = false;
                    var _iteratorError6 = undefined;

                    try {
                        for (var _iterator6 = response.partitions[Symbol.iterator](), _step6; !(_iteratorNormalCompletion6 = (_step6 = _iterator6.next()).done); _iteratorNormalCompletion6 = true) {
                            var partition = _step6.value;

                            // console.log(moment.months(partition.month));
                            var select = $('#partition-select');
                            var opt = $('<option>');
                            opt.attr('value', partition.id);
                            opt.html(_moment.default.months(partition.month) + ' - ' + partition.description + ' (' + (partition.sequence + 1) + (partition.sequence ? 'nd' : 'st') + ' half)');
                            select.append(opt);
                        }
                        // self.send('getServicePartitionOrders', $('#partition-select').val());
                        // self.send('getServiceCentroids', $('#partition-select').val());
                        // self.send('getRouteCentroids', $('#partition-select').val());
                    } catch (err) {
                        _didIteratorError6 = true;
                        _iteratorError6 = err;
                    } finally {
                        try {
                            if (!_iteratorNormalCompletion6 && _iterator6.return) {
                                _iterator6.return();
                            }
                        } finally {
                            if (_didIteratorError6) {
                                throw _iteratorError6;
                            }
                        }
                    }
                });
            },

            displayOrderInfo: function displayOrderInfo(order) {
                var self = this;
                var order = self.map.activeOrders[order];
                $('.rt-order-name').html(order.member_details.first_name + ' ' + order.member_details.last_name);
                $('#route-detail-pane .badge').html(order.status_details.status);
                $('.rt-address').html(order.member_details.addresses[0].formatted_address);
            }
        }

    });
});