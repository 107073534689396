define('walden-service/helpers/to-precision', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.toPrecision = toPrecision;
  function toPrecision(params) {
    return params[0].toFixed(params[1]);
  }

  exports.default = Ember.Helper.helper(toPrecision);
});