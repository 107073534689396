define('walden-service/models/address-zip', ['exports', 'ember-data', 'ember-data/attr', 'ember-cp-validations'], function (exports, _emberData, _attr, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    code: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 5,
      max: 5
    })]
  });

  exports.default = _emberData.default.Model.extend({
    member: (0, _attr.default)('number'),
    code: (0, _attr.default)('string'),
    active: (0, _attr.default)('boolean'),
    soon: (0, _attr.default)('boolean'),
    service_state: _emberData.default.belongsTo('state')
  });
});