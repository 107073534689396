define('walden-service/helpers/walden-varprice', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.waldenVarprice = waldenVarprice;

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  function waldenVarprice(params /*, hash*/) {
    var pennies = params[0];
    var quantity = params[1];
    var discount = 0;
    if (params[2]) {
      try {
        discount = JSON.parse(params[2].replace(/[\n\r]/g, '').replace('\t', '').replace(' ', '').replace(/\s/g, ''))[quantity];
      } catch (err) {
        console.log(err);
        console.log(params[2].replace(/[\n\r]/g, '').replace('\t', '').replace(' ', '').replace(/\s/g, ''));
        console.log(_typeof(params[2]));
      }
    }
    return ((pennies * quantity - discount) / 100).toFixed(2);
  }

  exports.default = Ember.Helper.helper(waldenVarprice);
});