define('walden-service/serializers/service-partition', ['exports', 'walden-service/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if ('partitions' in payload) {
        payload = {
          'service-partitions': payload.partitions
        };
      } else if ('service_partition' in payload) {
        payload = {
          'service-partition': payload.service_partition
        };
      }
      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });
});