define('walden-service/components/env-banner', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['env-banner'],
    classNameBindings: ['env'] // Adds bound value of 'env' as a class (i.e. 'pqa')
  });
});