define('walden-service/models/order', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    member: _emberData.default.attr('number'),
    address: _emberData.default.attr('number'),
    partition: _emberData.default.attr('number'),
    projected_date: _emberData.default.attr('number'),
    status: _emberData.default.attr('string'),
    locked: _emberData.default.attr('boolean'),
    address_details: _emberData.default.attr('raw')
  });
});