define('walden-service/components/problems/problems-members', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = Ember.Component.extend({
    // ---------------------------------
    // Actions

    actions: {
      /**
       * Invokes the 'viewProblem' action on the problems controller,
       * which redirects to the member route for the corresponding member.
       * @param {string} category - Problem category (member)
       * @param {number} id - Id of the member to view (will route to member/{id})
       */
      viewProblem: function viewProblem(category, id) {
        this.sendAction('viewProblem', 'member', id);
      },

      /**
       * Filters the current list of member problems after a filter
       * is selected from the 'Filter Problems' dropdown.
       * @param {string} filter - Member problem filter (i.e. 'bad-address')
       */
      changeFilter: function changeFilter(filter) {
        this.set('filter', filter);
        this.notifyPropertyChange('filteredMembers'); // Re-compute the computed property
      }
    },

    // ---------------------------------
    // Computed Properties

    /**
     * Filtered list of member problems as a result of either selecting
     * a filter from the 'Filter Problems' dropdown, or selecting a
     * problem type block on the dashboard page.
     */
    filteredMembers: computed(function () {
      var members = this.get('members');
      var filter = this.get('filter');
      if (filter) {
        return members.filterBy('type', filter);
      } else {
        return members;
      }
    }),
    /**
     * List of problem types for filtering (listed in 'Filter Problems' dropdown).
     */
    problemTypes: computed(function () {
      var members = this.get('members').toArray();
      var memberProblemTypes = [];
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = members[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var member = _step.value;

          var type = member.get('type');
          if (!memberProblemTypes.includes(type)) {
            memberProblemTypes.push(type);
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      return memberProblemTypes;
    })
  });
});