define('walden-service/components/loader-triangle-skew-spin', ['exports', 'ember-cli-loaders/components/loader-triangle-skew-spin'], function (exports, _loaderTriangleSkewSpin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _loaderTriangleSkewSpin.default;
    }
  });
});