define('walden-service/adapters/share-config', ['exports', 'walden-service/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var dasherize = Ember.String.dasherize;
  exports.default = _application.default.extend({
    session: Ember.inject.service('session'),
    ajaxService: Ember.inject.service('ajax'),
    pathForType: function pathForType(type) {
      return 'share-configs';
    },
    findRecord: function findRecord(store, type, id, snapshot) {
      // const token = this.get('session').session.content.authenticated.token;
      // const tokenData = JSON.parse(atob(token.split('.')[1]));
      // const member = tokenData.userId;
      var url = 'members/' + id + '/share-configs/current';

      return this.get('ajaxService').request(url, {
        method: 'GET'
      }).then(function (response) {
        return response;
      });
    },
    queryRecord: function queryRecord(store, type, id, snapshot) {
      // const token = this.get('session').session.content.authenticated.token;
      // const tokenData = JSON.parse(atob(token.split('.')[1]));
      // const member = tokenData.userId;
      var url = 'members/' + id + '/share-configs/current';

      return this.get('ajaxService').request(url, {
        method: 'GET'
      }).then(function (response) {
        return response;
      });
    }
  });
});