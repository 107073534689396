define('walden-service/models/scheduled-event', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _emberData.default.Model.extend({
    type: _emberData.default.attr('string'),
    json: _emberData.default.attr('raw'),
    comment: _emberData.default.attr('string'),
    attempts: _emberData.default.attr('number'),
    trigger_at: _emberData.default.attr('string'),
    created_at: _emberData.default.attr('string'),
    parsed_json: computed('json', function () {
      return JSON.parse(this.get('json'));
    }),
    filter_friendly: computed('json', function () {
      var valid_fields = {
        projected_date: 'Service Day',
        zip_group: 'Zip Group',
        route: 'Route',
        id: 'Share ID',
        pickup: 'Pickup Status'
      };

      var field = JSON.parse(this.get('json')).filter_field;

      if (!field) return 'Unknown field';
      if (field in valid_fields) return valid_fields[field];
      return field;
    })
  });
});