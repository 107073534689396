define('walden-service/util/routing/walden-map', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  var WaldenMap = function () {
    function WaldenMap(args) {
      _classCallCheck(this, WaldenMap);

      this.element = args.element;
      this.origin = args.origin;
      this.mapOptions = { zoom: 10, center: new google.maps.LatLng(args.origin.lat, args.origin.lng), noClear: true };
      this.polygons = {};
      this.polyColors = {
        selected: '#FFFFFF',
        default: '#FF0000'
      };
      this.polyLines = {};
      this.markers = {};
      this.pins = {
        share: 'M31.688,2C47.428,2,59,13.989,59,29.729v0.896C59,51.367,41.119,77,31.212,83h-0.237  C21.069,77,2,51.367,2,30.625v-0.896C2,13.989,14.76,2,30.5,2C30.659,2,31.529,2,31.688,2z',
        zip: 'M 400 300 L 350 300 L 300 300 L 300 350 L 350 400 L 400 350 L 400 300'
      };
    }

    _createClass(WaldenMap, [{
      key: 'render',
      value: function render() {
        this.map = new google.maps.Map(document.getElementById(this.element), this.mapOptions);
        this.setMapStyle();
      }
    }, {
      key: 'getRandomArrayElem',
      value: function getRandomArrayElem(arr) {
        return arr[Math.floor(Math.random() * arr.length)];
      }
    }, {
      key: 'createPolygon',
      value: function createPolygon(id, paths) {
        // const randColor = this.getRandomArrayElem(this.pinColors);
        var colors = this.polyColors;
        var poly = new google.maps.Polygon({
          paths: paths,
          strokeColor: colors.default,
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: colors.default,
          fillOpacity: 0.35
        });

        poly.setMap(this.map);
        this.polygons[id] = {
          poly: poly,
          label: null,
          color: colors.default
        };
      }
    }, {
      key: 'createPolyLine',
      value: function createPolyLine(id, path, color) {
        var lineSymbol = {
          path: 'M 0,-1 0,1',
          strokeColor: color,
          fillColor: color,
          strokeOpacity: 1,
          scale: 3
        };
        var polyLine = new google.maps.Polyline({
          path: path,
          strokeOpacity: 0,
          icons: [{
            icon: lineSymbol,
            offset: '0',
            repeat: '15px'
          }],
          zIndex: 9999,
          map: this.map
        });
        if (this.polyLines[id]) {
          this.polyLines[id].setMap(null);
        }
        this.polyLines[id] = polyLine;
      }
    }, {
      key: 'deletePolyLine',
      value: function deletePolyLine(id) {
        var polyLine = this.polyLines[id];
        if (polyLine) {
          polyLine.setMap(null);
          delete this.polyLines[id];
        }
      }
    }, {
      key: 'addPolygonLabel',
      value: function addPolygonLabel(id, position, label) {
        var poly = this.polygons[id].poly;
        if (poly) {
          var polyLabel = new google.maps.Marker({
            position: position,
            label: {
              text: label,
              fontSize: '16px',
              fontWeight: 'bold'
            },
            map: this.map,
            icon: null
          });
          polyLabel.setIcon({
            path: 'M 0,-1 0,1',
            fillColor: '#000000',
            strokeColor: '#000000',
            fillOpacity: 0.9,
            scale: 0,
            zIndex: 9999
          });
          this.polygons[id].label = polyLabel;
        }
      }
    }, {
      key: 'removePolygonLabel',
      value: function removePolygonLabel(id) {
        var poly = this.polygons[id];
        if (poly) {
          var label = poly.label;
          if (label) {
            label.setMap(null);
            poly.label = null;
          }
        }
      }
    }, {
      key: 'createMarker',
      value: function createMarker(id, position, color, title, type, label) {
        var marker = new google.maps.Marker({
          position: position,
          map: this.map,
          title: title,
          icon: null
        });
        if (type === 'share') {
          marker.setIcon({
            path: this.pins.share,
            fillColor: color,
            strokeColor: color,
            fillOpacity: 0.9,
            scale: 0.25,
            zIndex: 9999
          });
        } else if (type === 'group') {
          if (label) {
            marker.setLabel({
              text: label,
              fontSize: '24px',
              fontWeight: 'bold',
              color: 'white'
            });
          }
          marker.setIcon({
            path: 'M 0,-1 0,1',
            fillColor: color,
            strokeColor: color,
            fillOpacity: 0.9,
            scale: 0.25,
            zIndex: 9998
          });
        }

        if (this.markers[id]) {
          this.markers[id].setMap(null);
        }
        this.markers[id] = marker;
      }
    }, {
      key: 'updatePolygonColor',
      value: function updatePolygonColor(id, color, set) {
        var poly = this.polygons[id];
        if (poly) {
          var clr = color ? color : poly.color;
          poly.poly.fillColor = clr;
          poly.poly.strokeColor = clr;
          if (set) {
            poly.color = clr;
          }
          // New way of doing this as of v3.4
          poly.poly.setOptions({
            fillColor: clr,
            storeColor: clr
          });
          // Old way of doing this
          /*
           *poly.poly.setMap(null);
           *poly.poly.setMap(this.map);
           */
        }
      }
    }, {
      key: 'deleteMarkers',
      value: function deleteMarkers() {
        for (var markerId in this.markers) {
          var marker = this.markers[markerId];
          marker.setMap(null);
        }
        this.markers = {};
      }
    }, {
      key: 'deleteMarker',
      value: function deleteMarker(id) {
        var marker = this.markers[id];
        if (marker) {
          marker.setMap(null);
          delete this.markers[id];
        }
      }
    }, {
      key: 'deletePolygons',
      value: function deletePolygons() {
        for (var polyId in this.polygons) {
          var poly = this.polygons[polyId];
          if (poly.poly) {
            poly.poly.setMap(null);
          }
          if (poly.label) {
            poly.label.setMap(null);
          }
        }
        this.polygons = {};
      }
    }, {
      key: 'deletePolyLines',
      value: function deletePolyLines() {
        for (var polyLinelId in this.polyLines) {
          var polyLine = this.polyLines[polyLinelId];
          polyLine.setMap(null);
        }
        this.polyLines = {};
      }
    }, {
      key: 'clear',
      value: function clear() {
        this.deletePolygons();
        this.deleteMarkers();
        this.deletePolyLines();
      }
    }, {
      key: 'addListener',
      value: function addListener(type, id, event, fn) {
        if (type === 'polygon') {
          var poly = this.polygons[id].poly;
          if (poly) {
            poly.addListener(event, fn);
          }
        } else if (type === 'marker') {
          var marker = this.markers[id];
          if (marker) {
            marker.addListener(event, fn);
          }
        }
      }
    }, {
      key: 'setMapStyle',
      value: function setMapStyle() {
        this.map.setOptions({
          styles: [{
            elementType: 'geometry',
            stylers: [{
              color: '#212121'
            }]
          }, {
            elementType: 'labels.icon',
            stylers: [{
              visibility: 'off'
            }]
          }, {
            elementType: 'labels.text.fill',
            stylers: [{
              color: '#757575'
            }]
          }, {
            elementType: 'labels.text.stroke',
            stylers: [{
              color: '#212121'
            }]
          }, {
            featureType: 'administrative',
            elementType: 'geometry',
            stylers: [{
              color: '#757575'
            }]
          }, {
            featureType: 'administrative.country',
            elementType: 'labels.text.fill',
            stylers: [{
              color: '#9e9e9e'
            }]
          }, {
            featureType: 'administrative.land_parcel',
            stylers: [{
              visibility: 'off'
            }]
          }, {
            featureType: 'administrative.locality',
            elementType: 'labels.text.fill',
            stylers: [{
              color: '#bdbdbd'
            }]
          }, {
            featureType: 'poi',
            elementType: 'labels.text.fill',
            stylers: [{
              color: '#757575'
            }]
          }, {
            featureType: 'poi.park',
            elementType: 'geometry',
            stylers: [{
              color: '#181818'
            }]
          }, {
            featureType: 'poi.park',
            elementType: 'labels.text.fill',
            stylers: [{
              color: '#616161'
            }]
          }, {
            featureType: 'poi.park',
            elementType: 'labels.text.stroke',
            stylers: [{
              color: '#1b1b1b'
            }]
          }, {
            featureType: 'road',
            elementType: 'geometry.fill',
            stylers: [{
              color: '#2c2c2c'
            }]
          }, {
            featureType: 'road',
            elementType: 'labels.text.fill',
            stylers: [{
              color: '#8a8a8a'
            }]
          }, {
            featureType: 'road.arterial',
            elementType: 'geometry',
            stylers: [{
              color: '#373737'
            }]
          }, {
            featureType: 'road.highway',
            elementType: 'geometry',
            stylers: [{
              color: '#3c3c3c'
            }]
          }, {
            featureType: 'road.highway.controlled_access',
            elementType: 'geometry',
            stylers: [{
              color: '#4e4e4e'
            }]
          }, {
            featureType: 'road.local',
            elementType: 'labels.text.fill',
            stylers: [{
              color: '#616161'
            }]
          }, {
            featureType: 'transit',
            elementType: 'labels.text.fill',
            stylers: [{
              color: '#757575'
            }]
          }, {
            featureType: 'water',
            elementType: 'geometry',
            stylers: [{
              color: '#000000'
            }]
          }, {
            featureType: 'water',
            elementType: 'labels.text.fill',
            stylers: [{
              color: '#3d3d3d'
            }]
          }],
          disableDefaultUI: true
        });
      }
    }]);

    return WaldenMap;
  }();

  exports.default = WaldenMap;
});