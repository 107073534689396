define('walden-service/controllers/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      /**
       * Routes to the problems/ page after user clicks a problem type
       * block on the dashboard page. This will set the tab on the
       * problems page that corresponds to the category (order or member)
       * as active, and selects a filter in the 'Filter Problems' dropdown.
       * @param {string} category - Problem category to view (order or member)
       * @param {string} filter - Problem filter to apply for the selected
       * category (i.e. 'unpaid-addons')
       */
      viewProblemsPage: function viewProblemsPage(category, filter) {
        this.transitionToRoute('problems', {
          queryParams: {
            category: category,
            filter: filter
          }
        });
      }
    }
  });
});