define('walden-service/components/problems/problems-orders', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = Ember.Component.extend({
    // ---------------------------------
    // Actions

    actions: {
      /**
       * Invokes the 'viewProblem' action on the problems controller,
       * which redirects to the orders route for the corresponding order.
       * @param {string} category - Problem category (order)
       * @param {number} id - Id of the order to view (will route to order/{id})
       */
      viewOrderProblem: function viewOrderProblem(id) {
        this.sendAction('viewProblem', 'order', id);
      },

      /**
       * Filters the current list of order problems after a filter
       * is selected from the 'Filter Problems' dropdown.
       * @param {string} filter - Order problem filter (i.e. 'unpaid-addons')
       */
      changeFilter: function changeFilter(filter) {
        this.set('filter', filter);
        this.notifyPropertyChange('filteredOrders');
      }
    },

    // ---------------------------------
    // Computed Properties

    /**
     * Filtered list of order problems as a result of either selecting
     * a filter from the 'Filter Problems' dropdown, or selecting a
     * problem type block on the dashboard page.
     */
    filteredOrders: computed(function () {
      var orders = this.get('orders');
      var filter = this.get('filter');
      if (filter) {
        return orders.filterBy('type', filter);
      } else {
        return orders;
      }
    }),
    /**
     * List of problem types for filtering (listed in 'Filter Problems' dropdown).
     */
    problemTypes: computed(function () {
      var orders = this.get('orders').toArray();
      var orderProblemTypes = [];
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = orders[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var order = _step.value;

          var type = order.get('type');
          if (!orderProblemTypes.includes(type)) {
            orderProblemTypes.push(type);
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      return orderProblemTypes;
    })
  });
});