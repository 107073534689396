define('walden-service/components/pilots/member-id-loader', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    /**
     * Raw input of the textarea.
     *
     * @argument
     * @type {string}
     * @default null
     * @memberof Components/Pilots/Member-Id-Loader
     */
    rawInput: null,
    /**
     * Wipe the raw input value when this component is about to be destroyed.
     *
     * @override
     * @method lifecycle:willDestroyElement
     * @returns null
     * @memberof Components/Pilots/Member-Id-Loader
     */
    willDestroyElement: function willDestroyElement() {
      this.set('rawInput', null);
    },

    /**
     * Invokes the bound closure action with the extracted IDs.
     *
     * @method action:loadMemberIds
     * @returns null
     * @memberof Components/Pilots/Member-Id-Loader
     */
    actions: {
      loadMemberIds: function loadMemberIds() {
        if (this.get('onLoadIds')) this.get('onLoadIds')(this._extractMemberIds());
      }
    },
    /**
     * Extract the member IDs from the value of the textarea.
     *
     * @method _extractMemberIds
     * @returns {Array.<String>}
     * @memberof Components/Pilots/Member-Id-Loader
     */
    _extractMemberIds: function _extractMemberIds() {
      var memberIds = [];
      var raw = this.get('rawInput');
      if (raw && raw.length) {
        // Match against numbers separated by anything.
        var matches = raw.match(/\d+/g);
        if (matches && matches.length) memberIds = matches;
      }
      return memberIds;
    }
  });
});