define('walden-service/components/routing/routing-control-widget/routing-control-widget-selected', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var $ = Ember.$;
  exports.default = Ember.Component.extend({
    assignmentEnabled: false,
    isEditingGroup: false,
    selectedZips: null,
    selectedServiceDay: null,
    selectedZipGroup: null,
    actions: {
      removeZipFromGroup: function removeZipFromGroup(zip) {
        this.sendAction('removeZipFromGroup', zip);
        this.disableAssignment();
      },
      removeAllSelectedZipcodes: function removeAllSelectedZipcodes() {
        this.sendAction('removeAllSelectedZipcodes');
        this.disableAssignment();
      },
      selectServiceDayForGroup: function selectServiceDayForGroup(day) {
        this.set('selectedServiceDay', day);
        this.set('selectedZipGroup', null);
      },
      selectPastedZipCodes: function selectPastedZipCodes() {
        var pasted = $('#pasted-zipcodes').val();

        var codes = pasted // remove new lines, as well as any combos of newlines and commas.
        .split('\n') // gross but works (if only I knew regex)
        .join(',').split(',');

        var codesCleaned = [];
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = codes[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var code = _step.value;

            if (code.length >= 4) {
              var cleaned = code;
              if (code.length === 4) {
                cleaned = '0'.concat(cleaned);
              }
              cleaned = cleaned.replace(/\s/g, '');
              console.log('cleaned:', cleaned);
              codesCleaned.push(cleaned);
            }
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }

        console.log('cleaned: ', codesCleaned);
        this.sendAction('selectPastedZipCodes', codesCleaned);
      },
      selectZipGroupForGroup: function selectZipGroupForGroup(group) {
        this.set('selectedServiceDay', null);
        this.set('selectedZipGroup', group);
      },
      createZipGroup: function createZipGroup() {
        var day = this.get('selectedServiceDay');
        this.sendAction('createZipGroup', day);
        this.disableAssignment();
      },
      moveToZipGroup: function moveToZipGroup() {
        var group = this.get('selectedZipGroup');
        this.sendAction('moveToZipGroup', group);
        this.disableAssignment();
      },
      enableZipGroupEdit: function enableZipGroupEdit(editing) {
        this.set('assignmentEnabled', true);
        this.set('isEditingGroup', editing);
        this.clearEditSelections();
      },
      viewZipCodeOrders: function viewZipCodeOrders(zip) {
        this.sendAction('viewZipCodeOrders', zip);
      },
      showSelectedZipsOrderCounts: function showSelectedZipsOrderCounts() {
        this.sendAction('showSelectedZipsOrderCounts');
      },
      zipcodeSelected: function zipcodeSelected(zip) {
        this.sendAction('zipcodeSelected', zip, true, true);
      }
      // ------------- END ACTIONS -------------------

    },
    disableAssignment: function disableAssignment() {
      this.set('assignmentEnabled', false);
      this.set('isEditingGroup', false);
      this.clearEditSelections();
    },
    clearEditSelections: function clearEditSelections() {
      this.set('selectedServiceDay', null);
      this.set('selectedZipGroup', null);
    }
  });
});