define('walden-service/adapters/meat-type', ['exports', 'walden-service/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    ajax: Ember.inject.service('ajax'),
    findAll: function findAll(modelName, query) {
      return this.get('ajax').request('/shareconfig/meat-types?count=9999', {
        method: 'GET'
      }).then(function (response) {
        return response;
      });
    }
  });
});