define('walden-service/controllers/orders', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      serviceOrder: function serviceOrder(id) {
        this.transitionToRoute('order', id);
      }
    }
  });
});