define('walden-service/adapters/availability-range', ['exports', 'walden-service/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    namespace: 'shareconfig',
    pathForType: function pathForType() {
      return 'addon-availabilities';
    }
  });
});