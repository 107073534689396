define('walden-service/controllers/login', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service(),
    loginError: null,
    actions: {
      authenticate: function authenticate() {
        var _this2 = this;

        var _this = this;
        var credentials = this.getProperties('identification', 'password');
        var authenticator = 'authenticator:jwt';
        try {
          this.get('session').authenticate(authenticator, credentials).then(function () {
            try {
              var token = _this2.get('session').session.content.authenticated.token;
              var tokenData = JSON.parse(atob(token.split('.')[1]));
              if (tokenData.access === 'admin') {
                _this2.transitionToRoute('/');
              } else {
                _this2.set('loginError', {
                  code: 'invalid role',
                  message: 'account does not have admin access',
                  statusCode: '000'
                });
                _this2.get('session').invalidate();
              }
            } catch (er) {
              _this.set('loginError', er);
            }
            return true;
          }).catch(function (err, jqXHR, payload) {
            if (!err) {
              err = true;
            }
            _this.set('loginError', err);
          });
        } catch (error) {
          // Log this in the event that something strange happens
          console.log(error);
        }
      }
    }
  });
});