define('walden-service/models/zip-group', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    partition: _emberData.default.attr('number'),
    day: _emberData.default.attr('number'),
    centroid: _emberData.default.attr('raw'),
    locked: _emberData.default.attr('boolean'),
    hull: _emberData.default.attr('raw'),
    dispatched: _emberData.default.attr('boolean'),
    packed: _emberData.default.attr('boolean'),
    zips: _emberData.default.hasMany('zip', { async: false }),
    service_day: _emberData.default.belongsTo('service-day', { async: false }),
    service_partition: _emberData.default.belongsTo('service-partition', { async: false }),
    color: _emberData.default.attr('string', { defaultValue: null }),
    orderCount: _emberData.default.attr('number', { defaultValue: null }),
    route_density: _emberData.default.attr('number', { defaultValue: 0 }),
    dayIndex: _emberData.default.attr('number'),
    pack_day: _emberData.default.attr('number'),
    est_shares: _emberData.default.attr('number', { defaultValue: 0 }),
    region: _emberData.default.attr('string', { defaultValue: 'unknown' })
  });
});