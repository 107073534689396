define('walden-service/models/billing', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    brand: _emberData.default.attr('string'),
    country: _emberData.default.attr('string'),
    exp_month: _emberData.default.attr('number'),
    exp_year: _emberData.default.attr('number'),
    last4: _emberData.default.attr('string'),
    stripe_id: _emberData.default.attr('string')
  });
});