define('walden-service/routes/order', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    session: Ember.inject.service('session'),
    model: function model(params) {
      //return params.orderid;
      return Ember.RSVP.hash({
        orderid: params.orderid,
        order: this.store.query('share', { id: params.orderid }),
        shareConfigs: null,
        orderMember: null,
        addons: this.store.findAll('addon')
      });
    },
    setupController: function setupController(controller, model) {
      model.order = model.order.get('firstObject');
      var packedItems = [];
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = model.order.get('packed_items').toArray()[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var item = _step.value;

          packedItems.pushObject(Ember.Object.create(item)); // convert items to ember objects. To be removed when packed_items are modelized
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      console.log('packed items: ', packedItems);
      model.packedItems = packedItems;
      var _iteratorNormalCompletion2 = true;
      var _didIteratorError2 = false;
      var _iteratorError2 = undefined;

      try {
        for (var _iterator2 = model.order.get('transactions').toArray()[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
          var transaction = _step2.value;

          if (transaction.get('status') == 'revised') {
            transaction.set('hidden', true);
          }
        }
      } catch (err) {
        _didIteratorError2 = true;
        _iteratorError2 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion2 && _iterator2.return) {
            _iterator2.return();
          }
        } finally {
          if (_didIteratorError2) {
            throw _iteratorError2;
          }
        }
      }

      model.orderMember = this.store.findRecord('member', model.order.get('member'));

      model.shareConfigs = this.store.query('share-config', { member: model.order.get('member'), count: 9999 }).then(function (sc) {
        return sc;
      });
      controller.set('model', model);
    }
  });
});