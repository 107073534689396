define('walden-service/models/cutgroup', ['exports', 'ember-data', 'ember-data/attr'], function (exports, _emberData, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    display_section: _emberData.default.attr('number'),
    selected: _emberData.default.attr('boolean', { defaultValue: true }),
    cutListing: _emberData.default.belongsTo('cut-listing'),
    cuts: _emberData.default.hasMany('cut', { async: false })
  });
});