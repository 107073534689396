define('walden-service/components/shareconfig/dietary-restrictions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    showRestriction: false,
    /**
     * If  we have a share config, update the dietary model instances accordingly
     *
     */
    didInsertElement: function didInsertElement() {
      if (this.get('shareConf')) {
        var conf = this.get('shareConf');
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = conf.get('dietary_restrictions').toArray()[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var dr = _step.value;

            this.get('dietaryRestrictions').findBy('id', dr.get('id')).set('selected', true);
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }
      }
    },
    actions: {
      /**
       * Show or hide dietary restrictions
       *
       */
      toggleRestrictionDisplay: function toggleRestrictionDisplay() {
        this.set('showRestriction', !this.showRestriction);
      },
      /**
       * Set the model instance to reflect changes
       *
       * @param {number} restriction restriction id
       */
      toggleRestriction: function toggleRestriction(restriction) {
        var dr = this.get('dietaryRestrictions').findBy('id', restriction);
        dr.set('selected', !dr.get('selected'));
      }
    }
  });
});