define('walden-service/components/share-config', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var $ = Ember.$;

  exports.default = Ember.Component.extend({
    ajax: Ember.inject.service('ajax'),
    store: Ember.inject.service('store'),
    session: Ember.inject.service('session'),
    positionalParams: ['memberid, scid'],
    memberid: null,
    scid: null,
    requestData: [{ name: 'meat-types', callback: 'populateMeatTypes' }, { name: 'dietary-restrictions', callback: 'populateDietaryRestrictions' }, { name: 'share-types', callback: 'populateShareTypes' }, { name: 'share-peoples?sort=display_order:asc', callback: 'populateSharePeoples', param: '1' }, { name: 'share-sizes?sort=display_order:asc', callback: 'populateShareSizes', param: '1' }, { name: 'share-frequencies?sort=display_order:asc', callback: 'populateShareFrequencies', param: '1' }, { name: 'share-presets?sort=display_order:desc', callback: 'populateSharePresets' }, { name: 'addons?recurring=1', callback: 'populateAddons' }, { name: 'cut-groups', callback: 'populateCuts' }, { name: '/cut-listings', callback: 'populateCutListings' }],
    activeDefaults: {
      meatTypes: [1, 2, 3, 4]
    },
    configData: {},
    shareConfig: {
      share_type: 1,
      share_people: 1,
      share_size: 1,
      share_addons: [],
      share_preset: 0,
      share_frequency: 1,
      meat_exclusions: [],
      dietary_restrictions: [],
      cut_group_exclusions: [],
      cut_group_inclusions: []
    },
    configState: {
      resume: false,
      hasRepopulatedPeople: false,
      hasRepopulatedSize: false,
      substitutions: false
    },
    init: function init() {
      this._super();
      console.log('eeeeeeeeeeee');
      $(document).off('click');
      console.log(this.edit);
      // Mobile 

      $(document).on('click', '.addon-cont #right', function () {
        console.log($('#subnav-wrap i').css('display'));
        if ($('#subnav-wrap i').css('display') !== 'none') {
          var emSize = parseFloat($("body").css("font-size"));
          var activePreset = 1;
          $('.addon').each(function () {
            var winLeft = 0;
            var winRight = $(window).width();
            var elLeft = $(this).offset().left;
            var elRight = elLeft + $(this).width();
            if (elRight <= winRight && elLeft >= winLeft) {
              activePreset = $(this).attr('position');
              console.log('yeee');
              console.log(activePreset);
              return false;
            }
          });
          console.log(activePreset);
          // $('.preset[preset='+activePreset+']').click();
          if (activePreset !== '4') {
            console.log('natural');
            $('#addon-select').animate({ scrollLeft: activePreset * ($('.addon').outerWidth() + 3 * emSize) }, 500);

            $('.addon[position=' + parseInt(activePreset) + ']').click();
          } else {
            console.log('not natural');
            $('#addon-select').animate({ scrollLeft: 0 * ($('.addon').outerWidth() + 4 * emSize) }, 500);
            $('.preset[preset=0]').click();
          }
        }
      });

      $(document).on('click', '.addon-cont #left', function (e) {
        e.preventDefault();
        console.log($('#subnav-wrap i').css('display'));
        if ($('#subnav-wrap i').css('display') !== 'none') {
          var emSize = parseFloat($("body").css("font-size"));
          var activePreset = 1;
          $('.addon').each(function () {
            var winLeft = 0;
            var winRight = $(window).width();
            var elLeft = $(this).offset().left;
            var elRight = elLeft + $(this).width();
            if (elRight <= winRight && elLeft >= winLeft) {
              activePreset = $(this).attr('position');
              console.log('yeee');
              console.log(activePreset);
              return false;
            }
          });
          console.log(activePreset);
          // $('.preset[preset='+activePreset+']').click();
          if (activePreset == '1') {
            console.log('natural');
            $('#addon-select').animate({ scrollLeft: 4 * ($('.addon').outerWidth() + 3 * emSize) }, 500);
            return false;
          }
          // else if(activePreset !== '1') {
          //   $('.preset-select').animate({scrollLeft: (activePreset-2)*($('.preset').outerWidth()+(3*emSize))}, 500);
          //   $('.preset[preset='+(activePreset-1)+']').click();
          //   return false;
          // }

          else {
              console.log('not natural');
              $('#addon-select').animate({ scrollLeft: (activePreset - 2) * ($('.addon').outerWidth() + 3 * emSize) }, 500);
              console.log('parse' + '.addon[position=' + (parseInt(activePreset) - 1) + ']');
              return false;
            }
        }
      });

      $(document).on('click', '.cutlist-wrap #right', function () {
        console.log($('#subnav-wrap i').css('display'));
        if ($('#subnav-wrap i').css('display') !== 'none') {
          var emSize = parseFloat($("body").css("font-size"));
          var activePreset = 1;
          $('.preset').each(function () {
            var winLeft = 0;
            var winRight = $(window).width();
            var elLeft = $(this).offset().left;
            var elRight = elLeft + $(this).width();
            if (elRight <= winRight && elLeft >= winLeft) {
              activePreset = $(this).attr('preset');
              console.log('yeee');
              console.log(activePreset);
              return false;
            }
          });
          console.log(activePreset);
          // $('.preset[preset='+activePreset+']').click();
          if (activePreset !== '3') {
            console.log('natural');
            $('.preset-select').animate({ scrollLeft: activePreset * ($('.preset').outerWidth() + 3 * emSize) }, 500);

            $('.preset[preset=' + (parseInt(activePreset) + 1) + ']').click();
          } else {
            console.log('not natural');
            $('.preset-select').animate({ scrollLeft: 3 * ($('.preset').outerWidth() + 4 * emSize) }, 500);
            $('.preset[preset=0]').click();
          }
        }
      });

      $(document).on('click', '.cutlist-wrap #left', function () {
        console.log($('#subnav-wrap i').css('display'));
        if ($('#subnav-wrap i').css('display') !== 'none') {
          var emSize = parseFloat($("body").css("font-size"));
          var activePreset = 1;
          $('.preset').each(function () {
            var winLeft = 0;
            var winRight = $(window).width();
            var elLeft = $(this).offset().left;
            var elRight = elLeft + $(this).width();
            if (elRight <= winRight && elLeft >= winLeft) {
              activePreset = $(this).attr('preset');
              console.log('yeee');
              console.log(activePreset);
              return false;
            }
          });
          console.log(activePreset);
          // $('.preset[preset='+activePreset+']').click();
          if (activePreset == '0') {
            console.log('natural');
            $('.preset-select').animate({ scrollLeft: 2 * ($('.preset').outerWidth() + 3 * emSize) }, 500);
            $('.preset[preset=3]').click();
            return false;
          } else if (activePreset !== '1') {
            $('.preset-select').animate({ scrollLeft: (activePreset - 2) * ($('.preset').outerWidth() + 3 * emSize) }, 500);
            $('.preset[preset=' + (activePreset - 1) + ']').click();
            return false;
          } else {
            console.log('not natural');
            $('.preset-select').animate({ scrollLeft: 3 * ($('.preset').outerWidth() + 4 * emSize) }, 500);
            $('.preset[preset=0]').click();
            return false;
          }
        }
      });

      this.send('loadShareConfigData');
      var self = this;
      if (self.edit) console.log('edit: ' + self.edit);

      $(function () {
        if (localStorage.getItem('walden_share_config') !== null) $('.yourinfo').removeClass('disabled').removeAttr('disabled');

        $('.yourinfo').click(function () {
          if (!$(this).hasClass('disabled')) window.location.href = '/member-info';
        });

        var modal = document.getElementById('myModal');

        // Get the button that opens the modal
        var btn = document.getElementById("myBtn");

        // Get the <span> element that closes the modal
        var span = document.getElementsByClassName("close")[0];

        // When the user clicks on the button, open the modal 
        btn.onclick = function () {
          modal.style.display = "block";
        };

        // When the user clicks on <span> (x), close the modal
        span.onclick = function () {
          modal.style.display = "none";
        };

        // When the user clicks anywhere outside of the modal, close it
        window.onclick = function (event) {
          if (event.target == modal) {
            modal.style.display = "none";
          }
        };
      });

      $(document).on('click', '.share', function (e) {
        $('.share-select-info').html($(this).find('p').html());
        $('.share').removeClass('selected');
        $('.share').find('.walden-radio').html('');
        $('.share-img').css('filter', 'grayscale(100%)');
        $(this).find('.share-img').css('filter', 'none');
        $(this).addClass('selected');
        $(this).find('.walden-radio').html('<i class="fa fa-circle" aria-hidden="true"></i>');

        if ($(this).attr('share') == 1) {
          if (!$('.ismobile').is(':visible')) {
            $("#shareamount").prop("selectedIndex", 0);
            $('.people-select').hide();
            $('.meta-amount').hide();
            // $('.size-select').css('margin-left','-100px');
            $("#shareamount").val($("#shareamount option:first").val());
          } else {
            $('.people-select').hide();
            $('.meta-amount').hide();
            $('.size-select').css('margin-top', '-4em');
          }

          self.send('updatePrice');
          self.send('collectPeople');
          self.send('collectSize');
        } else {
          if ($('.ismobile').is(':visible')) {
            $('.size-select').css('margin-top', '0px');
          }
          $("#shareamount").val($("#shareamount option:first").val());
          $('.people-select').show();
          $('.meta-amount').show();
          $('.size-select').css('margin-left', '0px');
          $("#shareamount").prop("selectedIndex", 0);
          self.send('updatePrice');
          self.send('collectPeople');
          self.send('collectSize');
        }
      });

      $(document).on('change', '#shareprice, #shareamount', function () {
        self.send('updatePrice');
        self.send('collectPeople');
        self.send('collectSize');
      });

      $(document).on('change', '#sharefreq', function () {
        self.send('collectFreq');

        $('.meta-total-freq').html($(this).find('option:selected').html());
      });

      $(document).on('click', '.choice', function (e) {
        if ($(this).hasClass('selected')) {
          $(this).removeClass('selected');
          $(this).find('.walden-check').html('');
          $(this).find('.meat-type-image').css('filter', 'grayscale(100%)');
          console.log($(this).attr('meattype'));
          self.send('updateSelection', $(this).attr('meattype'), false);
        } else {
          $(this).addClass('selected');
          $(this).find('.walden-check').html('<i class="fa fa-check" aria-hidden="true"></i>');
          $(this).find('.meat-type-image').css('filter', 'none');
          self.send('updateSelection', $(this).attr('meattype'), true);
        }

        self.send('meatSelectionValid');

        self.send('collectMeatTypes');
      });

      $(document).on('click', '.preset', function (e) {
        $('.preset').removeClass('selected');
        $('.preset').find('img').css('filter', 'grayscale(100%)');
        $(this).addClass('selected');
        $(this).find('img').css('filter', 'none');
      });

      $(document).on('change', '.addon-select select', function () {
        var addon = $(this).attr('id');
        var quantity = parseInt($(this).val());
        var scaddon = _lodash.default.find(self.shareConfig.share_addons, function (o) {
          return o.id == addon;
        });

        scaddon.quantity = quantity;
      });

      $(document).on('click', '.check-option', function (e) {
        if ($(this).hasClass('selected')) {
          $(this).removeClass('selected');
          $(this).find('.walden-check').html('');
          if ($(this).hasClass('linked')) {
            console.log('linked');
            if ($(this).hasClass('sub-cut')) {
              console.log('sub');
              // Uncheck linked
              var target = $('.check-option.w-100[cut_group=' + $(this).attr('cut_group') + ']');
              target.removeClass('selected');
              target.find('.walden-check').html('');
            } else {
              console.log('nonsub');
              // Uncheck linked
              var target = $('.sub-cut[cut_group=' + $(this).attr('cut_group') + ']');
              target.removeClass('selected');
              target.find('.walden-check').html('');
            }
          }
        } else {
          $(this).addClass('selected');
          if ($(this).hasClass('negative')) {
            $(this).find('.walden-check').html('<i class="fa fa-times" aria-hidden="true"></i>');
          } else {
            $(this).find('.walden-check').html('<i class="fa fa-check" aria-hidden="true"></i>');
            if ($(this).hasClass('linked')) {
              console.log('linked');
              if ($(this).hasClass('sub-cut')) {
                console.log('sub');
                // Uncheck linked
                var target = $('.check-option.w-100[cut_group=' + $(this).attr('cut_group') + ']');
                target.addClass('selected');
                target.find('.walden-check').html('<i class="fa fa-check" aria-hidden="true"></i>');
              } else {
                console.log('nonsub');
                // Uncheck linked
                var target = $('.sub-cut[cut_group=' + $(this).attr('cut_group') + ']');
                target.addClass('selected');
                target.find('.walden-check').html('<i class="fa fa-check" aria-hidden="true"></i>');
              }
            }
          }
        }

        if ($(this).hasClass('negative')) {
          self.send('collectDietaryRestrictions');
        } else {
          self.send('collectCutTypes');
        }
      });

      $(document).on('click', '.cut-list-option', function () {
        console.log(self.shareConfig);
        if ($(this).find('i').hasClass('fa-chevron-down')) {
          $(this).find('i').removeClass('fa-chevron-down').addClass('fa-chevron-up');
        } else {
          $(this).find('i').removeClass('fa-chevron-up').addClass('fa-chevron-down');
        }
      });

      $(document).on('click', '.toggle', function (e) {
        var target = '.' + $(this).attr('data-target');
        if ($(this).hasClass('btn-inactive')) {
          $(this).removeClass('btn-inactive');
          $(this).find('span i').removeClass('fa-chevron-down').addClass('fa-chevron-up');
        } else {
          $(this).addClass('btn-inactive');
          $(this).find('span i').removeClass('fa-chevron-up').addClass('fa-chevron-down');
        }
        e.stopPropagation();
        $(target).stop().slideToggle(300);
      });

      $(document).on('click', '.share', function () {
        self.send('collectShare');
      });

      $(document).on('click', '.preset', function () {
        self.send('collectPreset');
      });

      $(function () {
        self.send('updatePrice');
        setTimeout(function () {
          $('.choice').each(function () {
            if (!$(this).hasClass('selected')) {
              self.send('updateSelection', $(this).attr('meattype'), false);
              console.log('moo');
            }
          });
        }, 2000);
      });

      $(document).on('click', '.preset', function () {
        if ($(this).attr('preset') == 0) {
          if ($('.end .cut-option.selected').length < 5) {
            // $('.banner-info').show();


            // $('.continue-sign-up').addClass('disabled');
          } else if ($('.middle .cut-option.selected').length < 5) {
            // $('.banner-info').show();
            // $('.continue-sign-up').addClass('disabled');
          } else if ($('.base .cut-option.selected').length < 2) {
            // $('.banner-info').show();
            // $('.continue-sign-up').addClass('disabled');


          } else {
            $('.banner-info').hide();
            // $('.continue-sign-up').removeClass('disabled');
          }
        } else {
          $('.banner-info').hide();
          // $('.continue-sign-up').removeClass('disabled');
        }
      });

      $(document).on('click', '.cut-option, .choice', function () {
        console.log($('.end .cut-option.selected').length);
        if ($('.end .cut-option.selected').length < 5) {
          // $('.banner-info').show();
          // $('.continue-sign-up').addClass('disabled');
        } else if ($('.middle .cut-option.selected').length < 5) {
          // $('.banner-info').show();
          // $('.continue-sign-up').addClass('disabled');
        } else if ($('.base .cut-option.selected').length < 2) {
          // $('.banner-info').show();
          // $('.continue-sign-up').addClass('disabled');

        } else {
          $('.banner-info').hide();
          // $('.continue-sign-up').removeClass('disabled');
        }
      });

      $(document).on('click', '.section2 .cut-option', function () {
        if ($('.section2 .selected').length < 2 && !self.configState.substitutions) {
          console.log('showing all substitutions');
          $('.base-seperator').click();
          $('.base .section0 .cut-option').each(function () {
            // $(this).addClass('selected');
            // $(this).find('.walden-check').html('<i class="fa fa-check" aria-hidden="true"></i>');
            // $('.banner-info').hide()
          });
          self.configState.substitutions = true;
        }
      });

      $(document).on('click', '.base-seperator', function () {
        $('.cutlist-lane.base .section0').toggle();
        if ($('.base-seperator i').hasClass('fa-chevron-down')) {
          $('.base-seperator i').removeClass('fa-chevron-down');
          $('.base-seperator i').addClass('fa-chevron-up');
        } else {
          $('.base-seperator i').addClass('fa-chevron-down');
          $('.base-seperator i').removeClass('fa-chevron-up');
        }
      });

      $(document).on('click', '.cut-option', function () {
        self.send('updateCutExclusions');
      });

      $(document).on('change', '.addon .param-select', function () {
        $(this).parent().find('.price').html($(this).find('option:selected').val() * parseInt($(this).parent().attr('price').replace('$', '')));
      });

      $(document).on('click', '.meta-total p.too-freq', function () {
        $(this).hide();
        $('.share-freq').show();
        self.send('collectFreq');
      });

      $(document).on('click', '.cut-list-option', function () {
        var cutList = $(this).attr('cut_cat');

        if (!$(this).hasClass('selected')) {

          $('#cut-listing-' + cutList).show();
          // $(this).find('.ddOpt').html('Select All');
          $('#cut-listing-' + cutList + ' .check-option').each(function () {
            // $(this).addClass('selected');
            // $(this).find('.walden-check').html('<i class="fa fa-check" aria-hidden="true"></i>')
          });
        } else {
          $('#cut-listing-' + cutList).hide();
          // $(this).find('.ddOpt').html('Show All');
        }
      });
    },
    actions: {
      loadShareConfigData: function loadShareConfigData() {
        var _this = this;

        if (localStorage.getItem('walden_share_config') !== null) {
          this.shareConfig = JSON.parse(localStorage.getItem('walden_share_config'));
          this.configState.resume = true;
        }

        this.requestData.every(function (entity) {
          var reqName = '';
          var cutList = false;
          if (entity.name.split('')[0] == '/') {
            console.log(entity);
            reqName = entity.name;
            cutList = true;
          } else {
            reqName = 'shareconfig/' + entity.name;
          }
          _this.get('ajax').request(reqName + '?count=1000', {
            method: 'GET'
          }).then(function (response) {
            if (cutList) {
              _this.configData['cut-listings'] = response['categories'];
              _this.configData['base-cuts'] = response['groups'];
              _this.send('populateBasecuts');
            }
            _this.configData[entity.name.split('?')[0]] = response[entity.name.split('?')[0].replace('-', '_')];
            if ('param' in entity) {
              _this.send(entity.callback, entity.param);
            } else {
              _this.send(entity.callback);
            }
          });
          return true;
        });
      },
      populateBasecuts: function populateBasecuts() {
        console.log(this.configData['base-cuts']);
        this.configData['base-cuts'].every(function (bc) {

          var wrap = $('<div>');
          var check = $('<div>');
          var mark = $('<i>');
          var name = $('<span>');
          var cut_meat_type;
          var type = null;

          wrap.addClass('check-option');
          wrap.addClass('cut-option');
          wrap.attr('cut_group', bc.id);
          check.addClass('walden-check');
          mark.addClass('fa fa-check');
          mark.attr('aria-hidden', 'true');

          if (bc.name.toLowerCase().indexOf('beef') !== -1 || bc.name.toLowerCase().indexOf('steak') !== -1) type = 1;
          if (bc.name.toLowerCase().indexOf('pork') !== -1 || bc.name.toLowerCase().indexOf('hawk') !== -1 || bc.name.toLowerCase().indexOf('bacon') !== -1) type = 2;
          if (bc.name.toLowerCase().indexOf('lamb') !== -1) type = 3;
          if (bc.name.toLowerCase().indexOf('chicken') !== -1) type = 4;
          wrap.attr('cut_type', type);
          name.html(bc.name);
          wrap.append(check);
          wrap.append(name);
          check.append(mark);
          wrap.addClass('w-100');
          if (bc.display_section != 0) {
            wrap.addClass('selected');
          } else {
            wrap.find('.walden-check').html('');
          }

          $('.base .section' + bc.display_section).append(wrap);

          return true;
        });
      },
      updateSelection: function updateSelection(type, select) {
        var self = this;
        if (select) {
          $('.check-option[cut_meat_type~=' + type + ']').addClass('selected');
          $('.check-option[cut_meat_type~=' + type + ']').find('.walden-check').html('<i class="fa fa-check" aria-hidden="true"></i>');
        } else {
          $('.check-option[cut_meat_type~=' + type + ']').removeClass('selected');
          $('.check-option[cut_meat_type~=' + type + ']').find('.walden-check').html('');
        }

        // $('.cut-list-option').each(function() {
        //   if(select) {
        //     //  $(this).addClass('selected');
        //     //  $(this).find('.ddOpt').html('Show All');
        //     // $(this).find('.walden-check').html('<i class="fa fa-check" aria-hidden="true"></i>');
        //     // var cont = $('#cut-listing-'+$(this).attr('cut_cat'));
        //     // cont.hide();
        //     $('#cut-listing-'+$(this).attr('cut_cat')+' .check-option').each(function() {
        //       $(this).addClass('selected');
        //       $(this).find('.walden-check').html('<i class="fa fa-check" aria-hidden="true"></i>');
        //     });
        //   } else {
        //     // $(this).removeClass('selected');
        //     // $(this).find('.ddOpt').html('Select All');
        //     // $(this).find('.walden-check').html('');
        //     // var cont = $('#cut-listing-'+$(this).attr('cut_cat'));
        //     cont.show();
        //     $('#cut-listing-'+$(this).attr('cut_cat')+' .check-option').each(function() {
        //       $(this).removeClass('selected');
        //       $(this).find('.walden-check').html('');
        //     });
        //   }

        // });

        if (select) {
          // $('.cl-container .check-option[cut_type='+type+']').addClass('selected');
          // $('.cl-container .check-option[cut_type='+type+']').find('.walden-check').html('<i class="fa fa-check" aria-hidden="true"></i>');

          // $('.check-option[cut_type='+type+']').each(function() {
          //   $('.cut-list-option[cut_cat='+$(this).attr('cut_category')+']').addClass('selected');
          //   $('.cut-list-option[cut_cat='+$(this).attr('cut_category')+']').find('.ddOpt').html('Show All')
          //   $('.cut-list-option[cut_cat='+$(this).attr('cut_category')+']').find('.walden-check').html('<i class="fa fa-check" aria-hidden="true"></i>');
          //   $('#cut-listing-'+$(this).attr('cut_category')).hide();
          // });

          $('.cl-container .check-option, .section2 .check-option').each(function () {
            if ($(this).attr('cut_type') == type) {

              $(this).addClass('selected');
              $(this).find('.walden-check').html('<i class="fa fa-check" aria-hidden="true"></i>');
            } else {}
          });
          self.send('cutCleanup');
        } else {
          var currentParent = null;
          var showParent = false;
          // $('.cl-container .check-option[cut_type='+type+']').removeClass('selected');
          // $('.cl-container .check-option[cut_type='+type+']').find('walden-check').html('');
          $('.cl-container .check-option, .base .check-option').each(function () {
            if ($(this).attr('cut_type') && $(this).attr('cut_type') == type) {
              // $('.cut-list-option[cut_cat='+$(this).attr('cut_category')+']').removeClass('selected');
              $('.cut-list-option[cut_cat=' + $(this).attr('cut_category') + ']').find('.walden-check').html('');
              // $('.cut-list-option[cut_cat='+$(this).attr('cut_category')+']').find('.ddOpt').html('Select All')
              $(this).removeClass('selected');
              $(this).find('.walden-check').html('');
              // $('#cut-listing-'+$(this).attr('cut_category')).show();
            } else {}
          });
        }

        console.log(type + ' - ' + select);
      },
      cutCleanup: function cutCleanup() {
        $('.cl-container').each(function () {
          var hide = true;
          var activeParent = null;
          $(this).find('.check-option').each(function () {
            if (!$(this).hasClass('selected')) {
              hide = false;
            }
            activeParent = $(this).attr('cut_category');
          });
          if (hide) {
            $(this).hide();
            console.log('parent' + activeParent);
            $('.cut-list-option[cut_cat=' + activeParent + ']').addClass('selected');
            $('.cut-list-option[cut_cat=' + activeParent + ']').find('.walden-check').html('<i class="fa fa-check" aria-hidden="true"></i>');
          }
        });
      },
      repopulateShareConfig: function repopulateShareConfig() {
        var self = this;
        self.shareConfig = JSON.parse(localStorage.getItem('walden_share_config'));
        self.shareConfig.meat_exclusions.every(function (exclusion) {
          $('#meat-selection .choice[meattype="' + exclusion.id + '"]').removeClass('selected');
          return true;
        });
      },
      populateMeatTypes: function populateMeatTypes() {
        var _this2 = this;

        var self = this;
        var meatSelection = $('#meat-selection');
        var i = 0;
        var preSelect = [];

        if (this.configState.resume) {
          this.shareConfig.meat_exclusions.every(function (ex) {
            preSelect.push(ex.id);
            return true;
          });
        }

        this.configData['meat-types'].every(function (meatType) {
          if (i == 4) return true;
          var choice = $('#sign-up-templates .choice').clone();
          choice.find('.meat-type-image').prop('src', meatType.image_uri);
          choice.find('.title').html(meatType.name);
          choice.attr('meattype', meatType.id);

          if (_this2.activeDefaults.meatTypes.includes(meatType.id) && !_lodash.default.includes(preSelect, meatType.id)) {
            choice.addClass('selected');
            choice.find('.walden-check').html('<i class="fa fa-check" aria-hidden="true"></i>');
          } else {
            choice.find('.meat-type-image').css('filter', 'grayscale(100%)');
          }

          meatSelection.append(choice);
          i++;
          return true;
        });
      },
      populateDietaryRestrictions: function populateDietaryRestrictions() {
        var preSelect = [];

        if (this.configState.resume) {
          this.shareConfig.dietary_restrictions.every(function (dr) {
            preSelect.push(dr.id);
            return true;
          });
        }

        var drSelection = $('.dietary-restrictions');
        this.configData['dietary-restrictions'].every(function (dr) {
          var choice = $('#sign-up-templates .check-option').clone();
          choice.find('span').html('No ' + dr.name);
          //choice.addClass('negative');
          choice.attr('dr', dr.id);

          if (_lodash.default.includes(preSelect, dr.id)) {
            choice.addClass('selected');
          }

          drSelection.append(choice);
          return true;
        });
      },
      populateShareTypes: function populateShareTypes() {
        var self = this;
        var preSelect = null;

        if (self.configState.resume) {
          preSelect = self.shareConfig.share_type.id;
        }

        var shareSelect = $('.share-select');
        var i = 0;
        this.configData['share-types'].every(function (st) {
          var share = $('#sign-up-templates .share').clone();
          share.attr('share', st.id);
          $('.share-img').css('filter', 'grayscale(100%)');
          if (preSelect == st.id) {
            share.addClass('selected');
            share.find('.share-img').css('filter', 'none');
          } else {
            if (i == 1 && preSelect == null) {
              share.addClass('selected');
              share.find('.share-img').css('filter', 'none');
            } else {}
          }
          share.find('.share-title').html(st.name);
          share.find('p').html(st.description);
          share.find('.share-img').attr('src', st.image_uri);
          shareSelect.append(share);

          i++;

          return true;
        });
        $('.share').each(function () {
          if ($(this).hasClass('selected')) {
            $(this).find('.share-img').css('filter', 'none');

            $('.share-select-info').html($(this).find('p').html());
          }
        });

        if (preSelect == 1) {
          $('.people-select').hide();
          // $('.size-select').css('margin-left','-100px');
        }
        self.send('collectShare');
      },
      populateSharePeoples: function populateSharePeoples(type) {
        var self = this;
        var preSelect = null;

        if (self.configState.resume && !self.configState.hasRepopulatedPeople) {
          preSelect = JSON.parse(localStorage.getItem('walden_share_config')).share_people.people_lower;
          console.log(preSelect);
          self.configState.hasRepopulatedPeople == true;
          type = self.shareConfig.share_type.id;
        }

        $('#shareamount').html('');
        var singular;
        self.configData['share-peoples'].every(function (sp) {
          if (sp.share_type == type) {
            if (sp.people_lower == '1') singular = true;
            $('#shareamount').prepend('<option people="' + sp.id + '" value="' + sp.people_lower + '">' + sp.people_lower + ' serving' + (singular ? '' : 's') + ' of each item' + '</option>');
            singular = false;
          }
          return true;
        });

        $('#shareamount').val('2');

        if (preSelect !== null) {
          $('#shareamount').val(preSelect);
        }

        if ($('.share[share=1]').hasClass('selected')) {
          console.log('basic');
          $("#shareamount").prop("selectedIndex", 0);
        }
        self.send('updatePrice');
        self.send('collectSize');
        self.send('collectPeople');
      },
      populateShareSizes: function populateShareSizes(type) {
        var self = this;
        var preSelect = null;

        if (self.configState.resume && !self.configState.hasRepopulatedSize) {
          preSelect = JSON.parse(localStorage.getItem('walden_share_config')).share_size.weight_lower;
          self.configState.hasRepopulatedSize = true;
          type = self.shareConfig.share_type.id;
        }

        $('#shareprice').html('');
        this.configData['share-sizes'].every(function (sp) {
          if (sp.share_type == type) {
            $('#shareprice').prepend('<option size="' + sp.id + '" price="' + sp.price + '"value="' + sp.weight_lower + '">' + sp.display + '</option>');
          }
          return true;
        });

        $('#shareprice').val('10');

        if (preSelect !== null) $('#shareprice').val(preSelect);

        self.send('collectSize');
        self.send('collectPeople');
        self.send('updatePrice');
      },
      populateShareFrequencies: function populateShareFrequencies() {
        var self = this;

        var preSelect = null;

        if (self.configState.resume) {
          preSelect = self.shareConfig.share_frequency.id;
        }

        $('#sharefreq').html('');
        this.configData['share-frequencies'].every(function (sp) {
          $('#sharefreq').prepend('<option value="' + sp.id + '">' + sp.display + '</option>');
          if (sp.id == 1 && preSelect == null) {
            self.shareConfig.share_frequency = sp;
            console.log(sp);
            console.log(self.shareConfig);
          }
          return true;
        });

        if (preSelect !== null) {
          $('#sharefreq').val(preSelect);
          self.send('collectFreq');
          if (preSelect == 2) {
            $('.meta-total p').hide();
            $('.share-freq').show();
            $('.meta-total-freq').html('bi-monthly');
          }
        } else {
          $('#sharefreq').val('1');
        }
        //self.send('collectFreq');
        self.send('populateShareSizes', '2');
      },
      populateSharePresets: function populateSharePresets() {
        var presetSelect = $('.preset-select');
        var self = this;

        var preSelect = null;

        if (self.configState.resume) preSelect = self.shareConfig.share_preset.id;

        var i = self.configData['share-presets'].length;
        self.configData['share-presets'].every(function (sp) {
          var preset = $('#sign-up-templates .preset').clone();
          preset.attr('preset', sp.id);
          preset.find('.preset-img').attr('src', sp.image_uri);
          preset.find('.title').html(sp.name);
          preset.find('p').html(sp.description);
          $('.preset img').css('filter', 'grayscale(100%)');
          if (preSelect !== null && sp.id == preSelect) {
            preset.addClass('selected');
            console.log('-------------');
            preset.find('img').css('filter', 'none');
          } else {
            if (i == 1 && preSelect == null) {
              preset.addClass('selected');
              preset.find('img').css('filter', 'none');
            }
          }

          if (preSelect !== null && preSelect == 0) {
            $('.preset-select div[preset="0"]').addClass('selected');
            // preset.find('img').css('filter', 'none');
          }

          presetSelect.prepend(preset);
          i--;
          return true;
        });
        $('.preset').each(function () {
          if ($(this).hasClass('selected')) {
            $(this).find('img').css('filter', 'none');
          }
        });
        self.send('collectPreset');
      },
      populateAddons: function populateAddons() {
        var addonSelect = $('.addon-select-wrap');
        var self = this;

        var preSelect = [];
        var q = 1;

        if (self.configState.resume) {
          self.shareConfig.share_addons.every(function (sp) {
            preSelect.push({ id: sp.id, quantity: sp.quantity });
            return true;
          });
        }

        self.configData['addons'].every(function (addon) {
          var currentAddon = _lodash.default.find(preSelect, { id: addon.id });

          addon.quantity = 0;
          if (!self.configState.resume) self.shareConfig.share_addons.push(addon);
          var addonTemplate = $('#sign-up-templates .addon').clone();
          addonTemplate.find('span').html(addon.display);
          addonTemplate.find('select').attr('id', addon.id);
          addonTemplate.find('.price').html('0');
          addonTemplate.find('.addon-image').attr('src', addon.image_uri);
          addonTemplate.find('.addon-desc').html(addon.description);
          addonTemplate.attr('position', q);
          addonTemplate.attr('price', Math.ceil(addon.price / 100));
          var addSel = addonTemplate.find('select');

          for (var i = 0; i < addon.quantity_max; i++) {
            var newOpt = $('<option>');
            newOpt.attr('value', i);
            if (i === 0) {
              newOpt.html('None');
            } else {
              var selStr = i + ' ' + (i === 1 ? addon.unit_singular : addon.unit_plural);
              newOpt.html(selStr);
            }
            addSel.append(newOpt);
          }

          if (currentAddon) {
            addon.quantity = currentAddon.quantity;
            addonTemplate.find('select').val(currentAddon.quantity);
            addonTemplate.find('.price').html(parseInt(addonTemplate.attr('price')) * parseInt(addon.quantity));
          }

          addonSelect.append(addonTemplate);
          q++;
          return true;
        });
      },
      populateCuts: function populateCuts() {
        return true;
        var self = this;
        self.configData['cut-groups'].every(function (cut) {
          var meat_types = '';
          for (var i = 0; i < cut.cuts.length; i++) {
            for (var j = 0; j < cut.cuts[i].contents.length; j++) {
              meat_types += cut.cuts[i].contents[j].id + ',';
            }
          }

          self.send('addCheck', cut.id, cut.display_section, cut.name, true, cut.type.name, meat_types.substr(0, meat_types.length - 1));
          console.log('types' + meat_types.substr(0, meat_types.length - 1));
          return true;
        });
        // if(self.get('session').session.content.authenticated.token) {
        //   self.send('loadCutData');
        // }
        self.send('updateCutExclusions');
      },
      populateCutListings: function populateCutListings() {
        var self = this;
        console.log(self.configData['cut-listings']);

        self.configData['cut-listings'].every(function (cl) {

          var wrap = $('<div>');
          var check = $('<div>');
          var mark = $('<i>');
          var name = $('<span>');
          var ddOpt = $('<span class="ddOpt">');
          var cut_meat_type;

          wrap.addClass('check-option');
          wrap.addClass('cut-option');
          wrap.addClass('cut-list-option');

          wrap.attr('cut_type', cl.cut_type);

          wrap.attr('cut_cat', cl.id);
          check.addClass('walden-check');
          mark.addClass('fa fa-check');
          mark.attr('aria-hidden', 'true');

          // ddOpt.html('Show All')

          name.html(cl.display + ' <i aria-hidden="true" class="float-right fa fa-chevron-down"></i>');
          // wrap.append(check);
          wrap.append(name);
          wrap.addClass('w-100');
          wrap.addClass('selected');
          wrap.append(ddOpt);
          check.append(mark);

          $('.' + cl.type.name.toLowerCase()).append(wrap);

          $('.' + cl.type.name.toLowerCase()).append('<div class="cl-container" id="cut-listing-' + cl.id + '"></div>');

          cl.cut_groups.every(function (cg) {

            var wrap = $('<div>');
            var check = $('<div>');
            var mark = $('<i>');
            var name = $('<span>');
            var cut_meat_type;
            var type = null;

            wrap.addClass('check-option');
            wrap.addClass('cut-option');
            wrap.attr('cut_group', cg.id);
            wrap.attr('cut_category', cl.id);

            if (cg.name.toLowerCase().indexOf('beef') !== -1 || cg.name.toLowerCase().indexOf('steak') !== -1) type = 1;
            if (cg.name.toLowerCase().indexOf('pork') !== -1 || cg.name.toLowerCase().indexOf('hawk') !== -1 || cg.name.toLowerCase().indexOf('bacon') !== -1) type = 2;
            if (cg.name.toLowerCase().indexOf('lamb') !== -1) type = 3;
            if (cg.name.toLowerCase().indexOf('chicken') !== -1) type = 4;

            wrap.attr('cut_type', type);

            check.addClass('walden-check');
            mark.addClass('fa fa-check');
            mark.attr('aria-hidden', 'true');

            name.html(cg.name);
            wrap.append(check);
            wrap.append(name);
            check.append(mark);
            wrap.addClass('sub-cut');
            wrap.addClass('selected');
            console.log('appending' + cl.id);
            $('#cut-listing-' + cl.id).append(wrap);
            // if(cg.display_section == 100) {
            //   wrap.addClass('linked');
            //   var wrap = wrap.clone();
            //   wrap.removeClass('sub-cut').removeClass('selected').addClass('w-100 linked').attr('cut_type', type).find('.walden-check').html('');
            //   $('.base .section0').append(wrap)
            // }

            return true;
          });

          if ($('.end .cut-option.selected').length < 5) {
            // $('.banner-info').show();
            // $('.continue-sign-up').addClass('disabled');
          } else if ($('.middle .cut-option.selected').length < 5) {
            // $('.banner-info').show();
            // $('.continue-sign-up').addClass('disabled');
          } else if ($('.base .cut-option.selected').length < 2) {
            // $('.banner-info').show();
            // $('.continue-sign-up').addClass('disabled');

          } else {
            $('.banner-info').hide();
            // $('.continue-sign-up').removeClass('disabled');
          }
          return true;
        });
        if (self.edit) self.send('loadShareConfig');
      },
      // loadCutData: function() {

      //   var self = this;
      //   var token = self.get('session').session.content.authenticated.token;
      //   var memberId = JSON.parse(atob(token.split('.')[1]))['userId'];
      //   self.get('ajax').request('/members/'+memberId+'/share-configs/latest', {
      //     method: 'GET',
      //     headers: {
      //       'token': token
      //     }
      //   }).then(response => {

      //     var exclusions = response.share_config.cut_exclusions;
      //     console.log(exclusions)
      //     for(var i = 0; i < exclusions.length; i++) {
      //       var item = $('.check-option[cut='+exclusions.id+']');
      //       item.removeClass('selected');
      //       item.find('.walden-check').html('');
      //       console.log(item)
      //     }
      //   });

      // },
      updatePrice: function updatePrice() {
        var people = $('#shareamount option:selected').val();
        var lowerBound = parseInt($('#shareprice option:selected').val());
        var price = parseInt($('#shareprice option:selected').attr('price'));
        var freq = parseInt($('#sharefreq option:selected').val());

        this.shareConfig.share_people = people;

        $('.meta-amount span').html(Math.round(2 * lowerBound / people));
        $('.meta-total-dollars').html('$' + Math.ceil(price / 100));
      },
      updateCutExclusions: function updateCutExclusions() {
        var self = this;
        self.shareConfig.cut_group_exclusions = [];

        $('.cut-option').each(function () {
          if (!$(this).hasClass('selected')) {
            self.send('addToShareConfig', 'cut-groups', $(this).attr('cut'), 'cut_group_exclusions', true);
          }
        });
      },
      collectMeatTypes: function collectMeatTypes() {
        var self = this;
        this.shareConfig['meat_exclusions'] = [];
        $('#meat-selection .choice').each(function () {
          if (!$(this).hasClass('selected')) {
            self.send('addToShareConfig', 'meat-types', $(this).attr('meattype'), 'meat_exclusions', true);
          }
        });
      },
      collectDietaryRestrictions: function collectDietaryRestrictions() {
        var self = this;
        this.shareConfig['dietary_restrictions'] = [];
        $('.dietary-restrictions .check-option').each(function () {
          if ($(this).hasClass('selected')) {
            self.send('addToShareConfig', 'dietary-restrictions', $(this).attr('dr'), 'dietary_restrictions', true);
          }
        });
      },
      collectShare: function collectShare() {
        var self = this;
        $('.share').each(function () {
          if ($(this).hasClass('selected')) {
            self.send('addToShareConfig', 'share-types', $(this).attr('share'), 'share_type', false);
            if ($(this).attr('share') == '1') {
              self.send('hidePersonalization');
            } else {
              self.send('showPersonalization');
            }
            self.send('populateSharePeoples', $(this).attr('share'));
            self.send('populateShareSizes', $(this).attr('share'));
          }
        });
      },
      collectFreq: function collectFreq() {
        this.send('addToShareConfig', 'share-frequencies', $('#sharefreq option:selected').val(), 'share_frequency', false);
      },
      collectPeople: function collectPeople() {
        this.send('addToShareConfig', 'share-peoples', $('#shareamount option:selected').attr('people'), 'share_people', false);
      },
      collectSize: function collectSize() {
        this.send('addToShareConfig', 'share-sizes', $('#shareprice option:selected').attr('size'), 'share_size', false);
      },
      collectPreset: function collectPreset() {
        var preset = $('.preset-select').find('.selected').attr('preset');
        if (preset == 0) {
          this.shareConfig.share_preset = { id: 0 };
          this.send('showCuts');
        } else {
          this.send('addToShareConfig', 'share-presets', $('.preset-select').find('.selected').attr('preset'), 'share_preset', false);
          this.send('hideCuts');
        }
      },
      collectCutTypes: function collectCutTypes() {},
      collectCutGroups: function collectCutGroups() {
        var self = this;
        self.shareConfig.cut_group_exclusions = [];
        self.shareConfig.cut_group_inclusions = [];
        $('.cutlist-wrap .check-option').each(function () {
          if (!$(this).hasClass('cut-list-option')) {
            // cut exclusions
            if (!$(this).hasClass('selected')) {
              if ($('.choice[meattype=' + $(this).attr('cut_type') + ']').hasClass('selected')) {
                console.log('exclusion ' + $(this).html());
                var activeExclusion = $(this).attr('cut_group');
                self.shareConfig.cut_group_exclusions.push({ id: activeExclusion });
              }
            } else {
              if (!$('.choice[meattype=' + $(this).attr('cut_type') + ']').hasClass('selected')) {
                console.log('inclusion ' + $(this).html());
                var activeInclusion = $(this).attr('cut_group');
                self.shareConfig.cut_group_inclusions.push({ id: activeInclusion });
              }
            }
          }
        });
      },
      addToShareConfig: function addToShareConfig(key, id, location, isArray) {
        var self = this;
        var result = _lodash.default.find(self.configData[key], function (o) {
          return o.id == id;
        });
        if (isArray) {
          self.shareConfig[location].push(result);
        } else {
          self.shareConfig[location] = result;
        }
      },
      hideCuts: function hideCuts() {
        $('.cutlist-lane').hide();
        // $('.cut-selection').css('overflow', 'hidden');
        // $('.cut-selection').animate({ height: '0px'}, '1000');
      },
      showCuts: function showCuts() {
        $('.cutlist-lane').show();
        // $('.cut-selection').css('overflow', 'visible');
        // $('.cut-selection').animate({ height: '1755px'}, '1000');
      },
      nextStep: function nextStep(e) {
        if (e) e.preventDefault();
        this.sendAction('signUpInfo', true);
      },
      addCheck: function addCheck(id, location, value, selected, type, meat_types) {
        var wrap = $('<div>');
        var check = $('<div>');
        var mark = $('<i>');
        var name = $('<span>');
        var cut_meat_type;

        wrap.addClass('check-option');
        wrap.addClass('cut-option');
        wrap.attr('cut', id);
        check.addClass('walden-check');
        mark.addClass('fa fa-check');
        mark.attr('aria-hidden', 'true');

        // if(value.toLowerCase().indexOf('beef') !== -1) {
        //   cut_meat_type = 1;
        // } else if(value.toLowerCase().indexOf('pork') !== -1) {
        //   cut_meat_type = 2;
        // } else if(value.toLowerCase().indexOf('lamb') !== -1) {
        //   cut_meat_type = 3;
        // } else if(value.toLowerCase().indexOf('chicken') !== -1) {
        //   cut_meat_type = 4;
        // }

        wrap.attr('cut_meat_type', meat_types);

        if (selected) {
          wrap.addClass('selected');
          check.append(mark);
        }

        name.html(value);
        wrap.append(check);
        wrap.append(name);
        if (location !== 1) {
          wrap.addClass('full');
        }

        if (location == 0 && type.toLowerCase() == 'base') {
          wrap.removeClass('selected');
          wrap.find('.walden-check').html('');
        }

        if (location == 0) {
          if (type.toLowerCase() == 'base') {
            $('.' + type.toLowerCase()).find('.section' + location).append(wrap);
          } else {
            $('.' + type.toLowerCase() + '.section' + location).append(wrap);
          }
        } else {
          $('.section' + location).append(wrap);
        }
      },
      hidePersonalization: function hidePersonalization() {
        $('.cutlist-wrap').hide();
        // $('.cutlist-wrap').animate({ height: '0px'}, '1000');
      },
      showPersonalization: function showPersonalization() {
        $('.cutlist-wrap').show();
        // $('.cutlist-wrap').animate({ height: '1955px'}, '1000');
      },
      meatSelectionValid: function meatSelectionValid() {
        var selectedTypes = [];
        $('.choice.selected').each(function () {
          selectedTypes.push($(this).attr('meattype'));
        });

        if (selectedTypes.length == 1 && (selectedTypes[0] == 3 || selectedTypes[0] == 4)) {
          // $('.meat-type-error').html('Please select at least one more meat type').fadeIn('300');
        } else if (selectedTypes.length == 0) {
          $('.meat-type-error').html('Please select meat types');
          if (!$('.meat-type-error').is(':visible')) {
            // $('.meat-type-error').(300);
          }
        } else {
          $('.meat-type-error').hide();
        }
      },
      loadShareConfig: function loadShareConfig() {
        var self = this;
        var token = this.get('session').session.content.authenticated.token;
        var data = JSON.parse(atob(token.split('.')[1]));
        console.log('loading share configuration');

        var url = '/members/' + self.memberid + '/share-configs/current';
        if (!this.memberid) {
          url = '/share-configs/' + this.scid;
        }

        self.get('ajax').request(url, {
          method: 'GET',
          headers: {
            'token': token
          }
        }).then(function (response) {
          if (!self.memberid) {
            $('.continue-sign-up').hide();
          }
          console.log(response);
          var meat_exclusions = [];
          for (var i = 0; i < response.share_config.meat_exclusions.length; i++) {
            meat_exclusions.push(response.share_config.meat_exclusions[i].id);
          }

          self.shareConfig.meat_exclusions = response.share_config.meat_exclusions;

          $('#meat-selection .choice').each(function (index, element) {
            if (!($.inArray(parseInt($(this).attr('meattype')), meat_exclusions) == -1)) {
              $(this).removeClass('selected').find('.walden-check').html('');
            }
          });

          self.shareConfig.dietary_restrictions = response.share_config.dietary_exclusions;

          for (var i = 0; i < response.share_config.dietary_exclusions.length; i++) {
            $('.dietary-restrictions .check-option[dr=' + response.share_config.dietary_exclusions[i].id + ']').addClass('selected').find('.walden-check').html('<i class="fa fa-check" aria-hidden="true"></i>');
          }

          self.shareConfig.share_type = response.share_config.share_type;
          $('.share').removeClass('selected');
          $('.share[share=' + response.share_config.share_type + ']').addClass('selected');
          self.send('collectShare');

          if (response.share_config.share_type == 1) {
            $('.people-select').hide();
            $('.meta-amount').hide();
          }

          self.shareConfig.share_frequency = response.share_config.share_frequency;
          $('#sharefreq').val(response.share_config.share_frequency);
          if (response.share_config.share_frequency !== 1) {
            $('.share-freq').show();
            $('.too-freq').hide();
          }

          self.send('collectFreq');

          self.shareConfig.share_preset = response.share_config.share_preset ? response.share_config.share_preset : 0;

          $('.preset').removeClass('selected').find('img').css('filter', 'grayscale(100%)');
          $('.preset[preset=' + self.shareConfig.share_preset + ']').addClass('selected').find('img').css('filter', 'none');

          self.send('collectPreset');

          self.shareConfig.cut_group_exclusions = response.share_config.cut_group_exclusions;

          for (var i = 0; i < response.share_config.cut_group_exclusions.length; i++) {
            $('.cutlist-wrap .check-option[cut_group=' + response.share_config.cut_group_exclusions[i].id + ']').removeClass('selected').find('.walden-check').html('');
          }

          self.shareConfig.cut_group_inclusions = response.share_config.cut_group_inclusions;

          for (var i = 0; i < response.share_config.cut_group_inclusions.length; i++) {
            $('.cutlist-wrap .check-option[cut_group=' + response.share_config.cut_group_inclusions[i].id + ']').addClass('selected').find('.walden-check').html('<i class="fa fa-check" aria-hidden="true"></i>');
          }

          // self.shareConfig.share_addons = response.share_config.recurring_addons;

          for (var i = 0; i < response.share_config.recurring_addons.length; i++) {
            // self.shareConfig.share_addons = [];
            // self.shareConfig.share_addons = response.share_config.recurring_addons;
            var currentAddon = response.share_config.recurring_addons[i].share_addons;
            var scaddon = _lodash.default.find(self.shareConfig.share_addons, function (o) {
              return o.id == response.share_config.recurring_addons[i].id;
            });
            scaddon.quantity = currentAddon.quantity;
            console.log(currentAddon);

            var price = Math.ceil(response.share_config.recurring_addons[i].price / 100) * currentAddon.quantity;
            console.log(price);

            $('.addon-select select#' + response.share_config.recurring_addons[i].id).val(currentAddon.quantity).parent().parent().parent().find('.price').html(price);
          }
          self.send('resetSize', response);
        });
      },
      resetSize: function resetSize(response) {
        var self = this;
        self.shareConfig.share_people = response.share_config.share_people;
        console.log(response.share_config.share_people);
        $('#shareamount option[people=' + response.share_config.share_people + ']').attr('selected', 'selected');

        self.shareConfig.share_size = response.share_config.share_size;
        console.log('SIZE=================' + response.share_config.share_people);
        var newSize = $('#shareprice option[size=' + response.share_config.share_size + ']').attr('value');
        $('#shareprice').val(newSize);
        self.send('collectSize');
        self.send('collectPeople');
      },
      updateConfig: function updateConfig(e) {
        if (e) e.preventDefault();

        if ($('.banner-info').is(':visible')) {
          $('html, body').animate({
            scrollTop: $(".banner-info").offset().top
          }, 500);
          return false;
        } else if ($('.meat-type-error').is(':visible')) {

          $('html, body').animate({
            scrollTop: $(".meat-type-error").offset().top
          }, 500);
        } else {
          var self = this;
          this.send('collectCutGroups');
          self.send('collectSize');
          this.send('collectPeople');
          self.send('collectDietaryRestrictions');
          self.send('collectPreset');
          self.send('collectFreq');
          console.log(self.shareConfig);
          var data = self.shareConfig;
          data.member = self.get('memberid');
          console.log(data.member);
          self.get('ajax').request('share-configs', {
            method: 'POST',
            data: data,
            headers: {
              'token': self.get('session').session.content.authenticated.token,
              'content-type': 'application/json'
            }
          }).then(function (response) {
            // self.sendAction('authenticateUser', { identification: self.accountInfo.basic.email, password: self.accountInfo.basic.password });
            // window.location.href="/?success=true";
            alert('Successfully updated share config. ');
            console.log(response);
          }).catch(function (err) {
            console.log(err);
          });
        }
      },
      saveConfig: function saveConfig(e) {
        if (e) e.preventDefault();
        if ($('.banner-info').is(':visible')) {
          $('html, body').animate({
            scrollTop: $(".banner-info").offset().top
          }, 500);
          return false;
        } else if ($('.meat-type-error').is(':visible')) {

          $('html, body').animate({
            scrollTop: $(".meat-type-error").offset().top
          }, 500);
        } else {

          this.send('collectCutGroups');
          console.log(this.shareConfig);
          if (!$('.banner-info').is(':visible') && !$('.meat-type-error').is(':visible')) {
            // this.shareConfig.share_people = $('#shareamount option:selected').attr('people');
            this.send('collectPeople');
            this.shareConfig.cut_group_exclusions = [];
            localStorage.setItem('walden_share_config', JSON.stringify(this.shareConfig));
            this.sendAction('signUpInfo', true);
          }
        }
      }
    }
  });
});