define('walden-service/components/shareconfig/share-types', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = Ember.Component.extend({
    shareType: 1,
    showPortion: false,
    portionAvailable: [3, 4],
    shareSize: 8,
    portionSize: 11,
    activeType: null,
    sharePrice: null,
    shareFrequency: null,
    frequency: null,
    shareYield: null,
    yieldDisplay: null,
    reloading: true,
    showFrequency: false,
    /**
     * Set up default instance values, reload data if shareconfig exists on the server, or in the store
     *
     */
    didInsertElement: function didInsertElement() {
      if (this.edit) {
        this.send('selectShareType', this.get('shareConf').get('share_type').get('id'));
      } else {
        this.send('selectShareType', 1);
      }
      // this.send('setPortionSize', 11);

      // if (this.get('reloadState')) this.reloading = true;
      this.getShareFrequency();
      if (this.get('shareConf')) {
        this.send('setPortionSize', this.get('shareConf').get('share_people').get('id'));
        var frequency = this.get('shareConf').get('share_frequency').get('id');

        this.set('shareFrequency', frequency);
        // this.getShareFrequency();
        this.set('frequency', this.get('shareConf').get('share_frequency').get('display'));
        if (frequency > 1) {
          this.set('showFrequency', true);
        }

        this.send('setShareSize', this.get('shareConf').get('share_size').get('id'));
      }
    },
    actions: {
      /**
       * Update the appropriate share type model instance to selected
       *
       * @param {number} type share type id
       */
      selectShareType: function selectShareType(type) {
        this.set('showPortion', type > 2);
        var shareTypes = this.get('shareTypes');
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = shareTypes.toArray()[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var shareType = _step.value;

            shareType.set('selected', parseInt(type) === parseInt(shareType.get('id')));
            var _iteratorNormalCompletion2 = true;
            var _didIteratorError2 = false;
            var _iteratorError2 = undefined;

            try {
              for (var _iterator2 = shareType.get('shareSizes').toArray()[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
                var shareSize = _step2.value;

                shareSize.set('visible', parseInt(type) === parseInt(shareType.get('id')));
                shareSize.set('selected', false);
              }
            } catch (err) {
              _didIteratorError2 = true;
              _iteratorError2 = err;
            } finally {
              try {
                if (!_iteratorNormalCompletion2 && _iterator2.return) {
                  _iterator2.return();
                }
              } finally {
                if (_didIteratorError2) {
                  throw _iteratorError2;
                }
              }
            }

            if (parseInt(type) === parseInt(shareType.get('id'))) {
              shareType.get('shareSizes').get('firstObject').set('selected', true);
            }
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }
      },
      /**
       * Sets the active share size
       *
       * @param {number} size share size id
       */
      setShareSize: function setShareSize(size) {
        var shareTypes = this.get('shareTypes');
        var _iteratorNormalCompletion3 = true;
        var _didIteratorError3 = false;
        var _iteratorError3 = undefined;

        try {
          for (var _iterator3 = shareTypes.toArray()[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
            var shareType = _step3.value;
            var _iteratorNormalCompletion4 = true;
            var _didIteratorError4 = false;
            var _iteratorError4 = undefined;

            try {
              for (var _iterator4 = shareType.get('shareSizes').toArray()[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
                var shareSize = _step4.value;

                if (parseInt(shareSize.get('id')) === parseInt(size)) {
                  shareSize.set('selected', true);
                  this.set('shareWeight', shareSize.get('weight_lower'));
                } else {
                  shareSize.set('selected', false);
                }
              }
            } catch (err) {
              _didIteratorError4 = true;
              _iteratorError4 = err;
            } finally {
              try {
                if (!_iteratorNormalCompletion4 && _iterator4.return) {
                  _iterator4.return();
                }
              } finally {
                if (_didIteratorError4) {
                  throw _iteratorError4;
                }
              }
            }
          }
        } catch (err) {
          _didIteratorError3 = true;
          _iteratorError3 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion3 && _iterator3.return) {
              _iterator3.return();
            }
          } finally {
            if (_didIteratorError3) {
              throw _iteratorError3;
            }
          }
        }
      },
      /**
       * Sets the active portion (people) size
       *
       * @param {number} size share people id
       */
      setPortionSize: function setPortionSize(size) {
        var _iteratorNormalCompletion5 = true;
        var _didIteratorError5 = false;
        var _iteratorError5 = undefined;

        try {
          for (var _iterator5 = this.get('sharePeoples').toArray()[Symbol.iterator](), _step5; !(_iteratorNormalCompletion5 = (_step5 = _iterator5.next()).done); _iteratorNormalCompletion5 = true) {
            var portion = _step5.value;

            portion.set('selected', parseInt(size) === parseInt(portion.get('id')));
          }
        } catch (err) {
          _didIteratorError5 = true;
          _iteratorError5 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion5 && _iterator5.return) {
              _iterator5.return();
            }
          } finally {
            if (_didIteratorError5) {
              throw _iteratorError5;
            }
          }
        }
      },
      /**
       * Show or hide the frequency selector
       *
       */
      toggleFrequency: function toggleFrequency() {
        this.toggleProperty('showFrequency');
      },
      /**
       * Sets the active frequency based on event target value
       *
       */
      changeFrequency: function changeFrequency() {
        this.set('shareFrequency', event.target.value);
        this.getShareFrequency();
      }
    },
    /**
     * Get the selected frequency display value
     *
     */
    getShareFrequency: function getShareFrequency() {
      var freqId = this.get('shareFrequency') || '1';
      var freq = this.get('shareFrequencies').findBy('id', freqId);
      this.sendAction('setShareFrequency', freqId, freq.get('display'));
      this.set('frequency', freq.get('display'));
    },
    /**
     * calculate the total share yield based on current configuration
     *
     */
    setShareYield: function setShareYield() {
      var shareType = this.get('shareTypes').findBy('selected', true);
      var shareSize = shareType.get('shareSizes').findBy('selected', true);
      if (this.portionAvailable.includes(parseInt(shareType.get('id')))) {
        var sharePortion = this.get('sharePeoples').findBy('selected', true);

        var people = parseInt(sharePortion.get('people_lower'));
        var weight = parseInt(shareSize.get('weight_lower'));

        return Math.floor(weight * 2 / people);
      } else {
        return 0;
      }
    },
    /**
     * Nested attribute filter helper
     *
     * @param {string} entity entity name
     * @param {string} localName local object
     * @param {number} id id of entity
     * @returns {object} entity instance
     */
    _getEntity: function _getEntity(entity, localName) {
      var _this = this;

      var collection = this.get(entity).toArray();
      return collection.filter(function (instance) {
        return parseInt(instance.get('id')) === parseInt(_this.get(localName));
      })[0];
    }
  });
});