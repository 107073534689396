define('walden-service/helpers/equals', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.equals = equals;


  /**
   * Handlebars helper that allows an equality
   * check between two variables on the model.
   * @param {[*]} params - The 2 variables to compare equality with
   * @returns {boolean} Result of the equality check
   */
  function equals(params /*, hash*/) {
    return params[0] === params[1];
  }

  exports.default = Ember.Helper.helper(equals);
});