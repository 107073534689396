define('walden-service/helpers/is-empty', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isEmpty = isEmpty;
  function isEmpty(params /*, hash*/) {
    var arr = params[0];
    if (!arr || arr.length === 0) {
      console.log('array is empty: ', arr);
      return true;
    } else {
      console.log('array not empty: ', arr);
      return false;
    }
    // return !(params[0] && params[0].length > 0);
  }

  exports.default = Ember.Helper.helper(isEmpty);
});