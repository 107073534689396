define('walden-service/components/sc-snapshot', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        session: Ember.inject.service('session'),
        ajax: Ember.inject.service('ajax'),
        positionalParams: ['scid'],
        scid: null,
        didInsertElement: function didInsertElement() {
            console.log(this.scid);
            console.log('in');
        }
    });
});