define('walden-service/serializers/problem-member', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTSerializer.extend({
    /**
     * Serializes the 'problem members' response.
     * TODO: Combine this with the problem-order serializer (if possible),
     * as both intercept the same property of the response (resp.problems).
     * @param {*} store
     * @param {*} primaryModelClass
     * @param {*} payload
     * @param {*} id
     * @param {*} requestType
     */
    normalizeFindAllResponse: function normalizeFindAllResponse(store, primaryModelClass, payload, id, requestType) {
      payload = {
        'problem-member': payload.problems
      };

      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });
});