define('walden-service/adapters/zip-group', ['exports', 'walden-service/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    updateRecord: function updateRecord(store, type, snapshot) {
      var data = this.serialize(snapshot, { includeId: true });
      var id = snapshot.id;
      var url = this.buildURL(type.modelName, id, snapshot, 'updateRecord');
      return this.ajax(url, 'PUT', { data: { zip_group: data } });
    }
  });
});